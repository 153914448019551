define("pleroma-pwa/serializers/poll-option", ["exports", "pleroma-pwa/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PollOptionSerializer extends _application.default {
    extractId(modelClass, resourceHash) {
      return `${resourceHash.poll_id}-${Ember.String.dasherize(resourceHash.title)}`;
    }

  }

  _exports.default = PollOptionSerializer;
});