define("pleroma-pwa/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": "About",
    "accountHasBeenDeleted": "Your account has been successfully deleted.",
    "accountHasBeenUpdated": "Your account has been successfully updated.",
    "addAnotherChoice": "Add another choice",
    "addPoll": "Post a poll",
    "advanced": "Advanced",
    "advancedSettings": "Account & Content",
    "allNotifications": "All Notifications",
    "allowDiscovery": "Allow discovery of this account in search results and other services",
    "allowsRegistrations": "Allows registrations",
    "alreadyHaveAccount": "Already have an account?",
    "apiVersion": "API Version",
    "approved": "Approved",
    "atHandle": "@{handle}",
    "attachImage": "Post images, GIFs, or video",
    "attachmentPreview": "Preview of {description}",
    "authenticating": "Authenticating...",
    "avatar": "Avatar",
    "avatarDimensions": "The recommended minimum size for avatar images is 150x150 pixels.",
    "avatarFor": "Avatar for {name}",
    "avatarNew": "Set a new Avatar",
    "backToAccount": "Back to account",
    "backToMessages": "Back to messages",
    "beginningOfConversations": "This is the beginning of your conversation history",
    "beginningOfFeed": "This is the beginning of your feed history.",
    "beginningOfHashtagFeed": "This is the beginning of this hashtags history",
    "beginningOfNotificationHistory": "This is the beginning of your notifications history.",
    "block": "Block",
    "blockSettingsPrompt": "If you block a user you will not be able to do any of the following to their statuses: view, reply, repost, favorite or send the user Direct Messages.",
    "blockUser": "Block @{handle}",
    "blocks": "Blocks",
    "cancel": "Cancel",
    "cancelUpload": "Cancel upload",
    "captcha": "Captcha",
    "chat": "Chat",
    "chats": "Chats",
    "checkingPushSubscription": "Checking push subscriptions...",
    "chooseExisitingChatOrStartNew": "Choose an existing chat, or start a new one.",
    "clearAvatar": "Clear avatar",
    "clearSearch": "Clear search query",
    "clientVersion": "Client Version",
    "close": "Close",
    "closeMenu": "Close menu",
    "colon": ":",
    "comma": ", ",
    "compose": "Compose",
    "confirmDeleteAccount": "Are you sure you want to delete your account?",
    "confirmDeleteStatus": "Are you sure you want to delete this status?",
    "confirmPassword": "Confirm Password",
    "congratulations": "Congratulations!",
    "contentNotAvailable": "This content is not available.",
    "conversation": "Conversation",
    "conversationMuted": "Conversation Muted",
    "conversationUnmuted": "Conversation Unmuted",
    "copyStatusLink": "Copy Link to Status",
    "createAccount": "Create a Free Account",
    "dash": "-",
    "dataImportExport": "Data Import Export",
    "days": "Days",
    "deleteMessage": "Delete Message",
    "deleteStatus": "Delete Status",
    "directFeedDescription": "This feed shows all of the Direct Messages you’ve received from other users.",
    "directMessage": "Direct Message",
    "directMessageChat": "Direct Message (Chat)",
    "directMessageClassic": "Direct Message (Classic)",
    "directMessageNotFound": "Direct Message not found",
    "directMessages": "Direct Messages",
    "directMessagesChats": "Direct Messages (Chats)",
    "directMessagesChatsDescription": "This feed shows the Chat Messages you’ve received.",
    "directMessagesClassic": "Direct Messages (Classic)",
    "directMessagesClassicDescription": "This feed shows the Direct Messages you’ve received.",
    "discoverability": "Discoverability",
    "dismiss": "Dismiss",
    "dismissNotification": "Dismiss Notification",
    "dismissToast": "Dismiss Notification",
    "displayName": "Display Name",
    "displayNamePlaceholder": "Lain Iwakura",
    "displayNameReposted": "{displayName} Reposted",
    "dmrecipients": "Direct message recipients:",
    "domains": "Domains",
    "dontHaveAccount": "Don’t have an account?",
    "emailAddress": "Email Address",
    "emailAddressPlaceholder": "lain@pleroma.com",
    "emojiReact": "React with :{emoji}:",
    "emojiReactAdd": "Add emoji reaction",
    "emojiReactUndo": "Remove my :{emoji}: reaction",
    "emojiSearch": "Search for an emoji",
    "errorBlockingUserTryAgain": "There was an error blocking that user, please try again.",
    "errorChangingPassword": "There was an error changing your password, please try again.",
    "errorClearingAvatarTryAgain": "There was an error clearing your avatar. Please try again.",
    "errorCopyingStatusUrlToClipboard": "There was an error copying the link to your clipboard, please try again.",
    "errorCreatingAccount": "There was an error creating your account. Please try again.",
    "errorDeletingAccount": "There was an error deleting your account. Please try again.",
    "errorDeletingStatusTryAgain": "There was an error deleting that status. Please try again.",
    "errorFavoritingTryAgain": "There was an error favoriting that status. Please try again.",
    "errorFetchingDataTryAgain": "Error fetching data, try again",
    "errorFollowingUserTryAgain": "There was an error following @{username}, please try again.",
    "errorMutingConversationTryAgain": "There was an error muting this conversation. Try again.",
    "errorMutingUserTryAgain": "There was an error muting @{username}, please try again.",
    "errorRepostingTryAgain": "There was an error reposting that status. Please try again.",
    "errorSavingBlockSettings": "There was an error saving block settings, please try again.",
    "errorSavingMuteSettings": "There was an error saving mute settings, please try again.",
    "errorSavingNotificationSettings": "There was an error saving notification settings, please try again.",
    "errorTryAgain": "There was an error. Please try again.",
    "errorUnblockingUserTryAgain": "There was an error unblocking that user, please try again.",
    "errorUnfavoritingTryAgain": "There was an error unfavoriting that status. Please try again.",
    "errorUnfollowingUserTryAgain": "There was an error unfollowing @{username}, please try again",
    "errorUnmutingConversationTryAgain": "There was an error unmuting this conversation. Try again.",
    "errorUnmutingUserTryAgain": "There was an error unmuting @{username}, please try again.",
    "errorUnrepostingTryAgain": "There was an error unreposting that status. Please try again.",
    "errorUpdatingAvatarTryAgain": "There was an error updating your avatar. Please try again.",
    "errorVotingTryAgain": "There was an error voting, please try again.",
    "export": "Export",
    "exportBlocking": "Block Export",
    "exportBlockingPrompt": "Export your blocks to a CSV file",
    "exportFollowing": "Follow Export",
    "exportFollowingPrompt": "Export your follows to a CSV file",
    "exportPrompt": "Export to a CSV file",
    "favorite": "Favorite",
    "favorites": "Favorites",
    "featureNotEnabled": "Feature \"{feature}\" is not enabled. Hang tight. We are actively iterating on it. ",
    "features": "Features",
    "feeds": "Feeds",
    "fileInputBlank": "No file chosen",
    "fileInputBtn": "Choose file",
    "fileTooLarge": "A selected file is too large, keep it below {max}.",
    "finalResults": "Final Results",
    "finish": "Finish",
    "finishedLoadingStatuses": "All statuses loaded",
    "firstAttachment": "First Attachment",
    "follow": "Follow",
    "followBack": "Follow Back",
    "followUser": "Follow @{handle}",
    "followers": "Followers",
    "followersCAPS": "FOLLOWERS",
    "followersOnly": "Followers Only",
    "following": "Following",
    "followingCAPS": "FOLLOWING",
    "forgotPassword": "Forgot password?",
    "forgotPasswordHelpText": "Enter your User Name or email address and we will send you a link to reset your password.",
    "forgotPasswordSuccess": "Please check your email for a link to reset your Password.",
    "forgotPasswordSuccessHeader": "Reset link sent.",
    "from": "from",
    "globalFeedDescription": "This feed shows the activity of all the users in {instance}, and all of the users of any instance connected to {instance}. This is the Federated feed.",
    "hasFavorited": "has favorited your status",
    "hasFollowed": "has followed you",
    "hasMentioned": "has mentioned you",
    "hasReposted": "has reposted your status",
    "hashtags": "Hashtags",
    "headBackHome": "Head back home",
    "home": "Home",
    "hours": "Hours",
    "hoursMinutesSeconds": "{days, plural, =0 {} one {{days} day} other {{days} days} } {hours, plural, =0 {} one {{hours} hour} other {{hours} hours} } {minutes, plural, =0 {} one {{minutes} minute} other {{minutes} minutes} }",
    "ifYouLeavePageStatusWillBeLost": "If you leave this page, your post will be lost.",
    "ifYouLeaveProgressWillBeLost": "If you leave this page, your progress will be lost.",
    "import": "Import",
    "importBlocking": "Block Import",
    "importBlockingPrompt": "Import your blocks from a CSV file",
    "importFollowing": "Follow Import",
    "importFollowingPrompt": "Import your follows from a CSV file",
    "importPrompt": "Import from a CSV file",
    "installPwaPrompt": "Install PWA",
    "installing": "...Installing",
    "invalidEmail": "Invalid email",
    "languages": "Languages",
    "lastAttachment": "Last Attachment",
    "learnMore": "Learn More",
    "loading": "Loading...",
    "loadingInstancePanel": "Loading instance panel...",
    "loadingMoreStatuses": "Loading more statuses...",
    "loadingOauthTokens": "Loading OAuth Tokens...",
    "localFeedDescription": "This feed shows the activity of all the users in {instance}.",
    "lorem": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "markAsSensitive": "Mark this content as sensitive",
    "maxExpiration": "Max Expiration",
    "maxOptionCharLength": "Max Option Character Length",
    "maxOptions": "Max Options",
    "maxStatusLength": "Max Status Length",
    "maybeItWasHereOnce": "Maybe it was here once, but it’s definitely not now.",
    "media": "Media",
    "mention": "Mention",
    "mentions": "Mentions",
    "message": "Message",
    "messageChat": "Message (Chat)",
    "messageClassic": "Message (Direct)",
    "messageNotFound": "Message not found",
    "messages": "Messages",
    "minExpiration": "Minimum Expiration",
    "minutes": "Minutes",
    "moreInformation": "See more information",
    "moreUserOptions": "More options for this user",
    "mute": "Mute",
    "muteConversation": "Mute Conversation",
    "muteSettingsPrompt": "If you mute a user you will not be able to do any of the following to their statuses: view, reply, repost or favorite.",
    "muteUser": "Mute @{handle}",
    "mutes": "Mutes",
    "myFeed": "My Feed",
    "new": "new",
    "newChat": "New Chat",
    "newFollows": "New Follows",
    "newItems": "{n} new items",
    "next": "Next",
    "nextAttachment": "Next Attachment",
    "noFavorites": "@{username} has no favorites.",
    "noMediaYet": "@{username} has not posted any attachments yet.",
    "noOneFollowingYet": "No one is following @{username} yet.",
    "noUsersMatchedQuery": "No users matched that query",
    "notFollowingAnyone": "@{username} is not following anyone.",
    "nothingToSeeHere": "🤷 Nothing to see here.",
    "notification": "notification",
    "notificationFeedDescription": "This feed shows all of the Notifications you’ve received.",
    "notificationSettingBlockFromStrangers": "Block notifications from users who you do not follow",
    "notificationSettingHideContents": "Hide the sender and contents of push notifications",
    "notificationSettings": "Notifications Preferences",
    "notificationSettingsFootnote": "To stop receiving notifications from a specific user, we suggest you use a mute. The act of blocking a user both stops all notifications and unsubscribes them.",
    "notificationSettingsHaveBeenSaved": "Notification settings have been saved.",
    "notificationSettingsPrompt": "Choose which notifications you want to receive.",
    "notifications": "Notifications",
    "nowFollowing": "Now following @{username}",
    "numFavourites": "{n, plural, =0 {no favorites} one {{n} favorite} other {{n} favorites} }",
    "numMoreReplies": "{n, plural, one {{n} more reply} other {{n} more replies} }",
    "numNewStatuses": "{n, plural, =0 {no new statuses} one {{n} new status} other {{n} new statuses} }",
    "numReplies": "{n, plural, =0 {no replies} one {{n} reply} other {{n} replies} }",
    "numReposts": "{n, plural, =0 {no reposts} one {{n} repost} other {{n} reposts} }",
    "oauthTokens": "OAuth Tokens",
    "offlineNotice": "You are currently offline. Please try again with a stable network connection.",
    "onboardingAvatarSelect": "You have successfully signed up. Upload a profile photo so people can recognize you.",
    "onboardingWriteBio": "Write a short bio",
    "onboardingWriteBioSub": "Tell us a little bit about yourself.",
    "openMenu": "Open menu",
    "password": "Password",
    "passwordHasBeenChanged": "Your password has been successfully changed. Please sign in again.",
    "passwordInputPlaceholder": "Password",
    "pause": "Pause video",
    "people": "People",
    "percent": "{n}%",
    "performanceErrorTracking": "Performance and Error Tracking",
    "performanceErrorTrackingExplanation": "Welcome to the Fedi FE Beta! As part of the development process this instance has performance and error tracking enabled. We are collecting this information in order to isolate and fix bugs. The instance admin will have access to information about errors and timing events within the page. If you are signed in and encounter an error, the instance admin will be notified of your user id along with the error. This instance admin, through this mechanism, does not have access to any of your personal information. However, be aware that if the instance admin has access to the database, then they have access to all content within their instance.",
    "pipe": "|",
    "play": "Play video",
    "pleaseProvideValidTokenId": "Please provide a valid token ID.",
    "pleaseProvideValidUser": "Please provide a valid user.",
    "pleaseSignIn": "Please sign in.",
    "pleaseUploadValidFile": "Please upload a valid file.",
    "pollDurationTooLong": "The poll is too long, please keep it below {max}.",
    "pollDurationTooShort": "The poll is not long enough, please keep it longer than {min} .",
    "pollHasTooManyOptions": "The poll has too many options, please keep it below {max} options.",
    "pollLimits": "Poll Limits",
    "pollNeedsAtLeastTwoOptions": "The poll needs at least 2 options.",
    "pollOptionLabel": "Choice {num}",
    "pollOptionTooLong": "A poll option has too many characters, please keep it below {max} characters.",
    "pollOptionsEmpty": "You have empty poll options, please use or remove them.",
    "pollOptionsUnique": "Poll options must be unique.",
    "postAStatus": "Post a Status",
    "postStatus": "Post Status",
    "postingStatus": "...Posting Status",
    "previous": "Previous",
    "previousAttachment": "Previous Attachment",
    "private": "Private",
    "profile": "Profile",
    "public": "Public",
    "pushNotificationSettings": "Push Notification Settings",
    "pushNotificationsApproved": "You will receive push notifications in this browser",
    "pushNotificationsFavorite": "When a status you created has been favourited by someone else.",
    "pushNotificationsFollow": "When someone has followed you.",
    "pushNotificationsMention": "When someone else has mentioned you in a status.",
    "pushNotificationsPoll": "When a poll you voted in or created has ended.",
    "pushNotificationsRepost": "When a status you created has been boosted by someone else.",
    "pwaInstallFail": "Why did you click no? It will be a while for the browser to allow us to ask you again.",
    "pwaInstallSuccess": "You installed the app! You Win!",
    "receivePushNotifications": "Receive push notifications",
    "recentChats": "Recent chats",
    "recentDirectMessages": "Recent direct messages",
    "register": "Register",
    "registerCaptcha": "Enter the text displayed in the image above.",
    "registerDisplaynameLabel": "Display Name",
    "registerDisplaynamePlaceholder": "Display Name, ex: Patti Smith",
    "registerDisplaynameTooltip": "This is the name that friends will see associated with your profile. It may contain letters, numbers, and hyphens.",
    "registerUsernameHint": "Use hyphens instead of spaces, ex: patti-smith",
    "registerUsernameLabel": "User Name",
    "registerUsernamePlaceholder": "User Name, ex: patti-smith",
    "registerUsernameTooltip": "This is your unique name that friends will use to mention you in statuses.",
    "registering": "Registering...",
    "reloadThisPage": "Reload this page",
    "remainingCount": "+{count}",
    "remoteFollow": "Remote Follow",
    "removeChoice": "Remove choice",
    "replies": "Replies",
    "reply": "Reply",
    "replyingTo": "Replying to",
    "reportUser": "Report @{handle}",
    "repost": "Repost",
    "reposted": "Reposted",
    "reposts": "Reposts",
    "requesting": "Requesting...",
    "resetPasswordNotEnabled": "Password reset is disabled, please contact your instance administrator.",
    "retryAttachment": "Retry attachment",
    "returnToTheHomePage": "Return to the Home Page",
    "richTextFormatting": "Rich text formatting",
    "rmvAttachment": "Remove attachment",
    "rmvPoll": "Remove Poll",
    "rmvrecipient": "Remove recipient",
    "sadlyYouveStumbled": "Sadly, you’ve stumbled on a page that doesn’t exist.",
    "save": "Save",
    "saving": "Saving...",
    "search": "Search",
    "searchFor": "Search for \"{query}\"",
    "searchForPeopleAndMessages": "Search for people and messages",
    "searchFoundHashtags": "Found {number} matching hashtags.",
    "searchFoundStatuses": "Found matching statuses.",
    "searchFoundUsers": "Found {number} matching users.",
    "searchLoadingSuggestions": "Looking for suggestions...",
    "searchNavKeys": "Use up and down arrows to browse results.",
    "searchNoResults": "There are no {type} results for this search.",
    "searchNoResultsTitle": "No Results",
    "searchPlaceholder": "Search {instance}",
    "security": "Security",
    "securitySettingsDeleteAccount": "Delete Account",
    "securitySettingsDeleteAccountDescription": "Permanently delete your account and all your messages.",
    "securitySettingsPassword": "Change Password",
    "securitySettingsPasswordError": "There was an issue changing your password. The new password does not match the confirmation.",
    "securitySettingsPasswordLabelCurrent": "Current Password",
    "securitySettingsPasswordLabelNew": "New Password",
    "securitySettingsPasswordLabelNewConfirm": "Confirm New Password",
    "securitySettingsPasswordPrompt": "Please note: Changing your password will sign you out and you will need to sign in again.",
    "seeAllChats": "See all Chats",
    "seeAllHashtags": "See all hashtags",
    "seeAllPeople": "See all people",
    "seeAllStatuses": "See all statuses",
    "selectAll": "Select all",
    "selectFeed": "Select feed",
    "selectThisFeed": "Select feed: {header}",
    "sendNewMessage": "Send a new message",
    "sendingMessage": "Sending message...",
    "sensitiveContent": "Sensitive content. Click to view.",
    "sensitiveContentHide": "Hide",
    "sensitiveContentShow": "Show",
    "separatorHyphen": "&nbsp;-&nbsp;",
    "settings": "Settings",
    "share": "Share",
    "shareStatus": "Share Status via Direct Message",
    "signIn": "Sign in",
    "signOut": "Sign out",
    "signUp": "Sign up",
    "skipThis": "Skip this",
    "somethingWentWrongTryAgain": "Something went wrong, please try again.",
    "stats": "Stats",
    "statusAudience": "Status audience",
    "statusContentPlaceholder": "Post a status to {instance}",
    "statusDeleted": "Status Deleted",
    "statusReposted": "Status Reposted",
    "statusUnavailable": "This Status is unavailable",
    "statusUnreposted": "Status Unreposted",
    "statusUrlCopiedToClipboard": "Link has been successfully copied to your clipboard",
    "statuses": "Statuses",
    "statusesCAPS": "STATUSES",
    "stripRichTextFromAllPosts": "Strip rich text formatting from all posts",
    "submit": "Submit",
    "tagWithHash": "#{name}",
    "termsOfService": "Terms of Service",
    "thatsNotRight": "That’s not right...",
    "thisIsAGroupDm": "This is a group DM",
    "timeLeft": "{time} left",
    "tokenRevoked": "That token was successfully revoked.",
    "trackingEnabled": "This instance has basic performance and error tracking enabled.",
    "trySigningIn": "Try signing in.",
    "type": "type",
    "unblock": "Unblock",
    "unblockUser": "Unblock @{handle}",
    "undoRepost": "Undo Repost",
    "unfavorite": "Unfavorite",
    "unfollow": "Unfollow",
    "unfollowUser": "Unfollow @{handle}",
    "unmute": "Unmute",
    "unmuteConversation": "Unmute Conversation",
    "unmuteUser": "Unmute @{handle}",
    "uploadANewPhoto": "Upload a New Photo",
    "uploadCancelled": "Upload has been cancelled",
    "uploadFailed": "An upload has failed, please try again.",
    "uploadingAvatar": "Uploading Avatar...",
    "useTheInputBelowToBeginDirectMessage": "Use the input selection below to start a new conversation.",
    "userBlocked": "@{username} has been successfully blocked",
    "userFeedDescription": "This feed shows the activity of only the users that you currently follow.",
    "userHasNoStatuses": "@{username} has not posted any statuses yet.",
    "userIsMuted": "You have muted @{username} and cannot see their {cannotSee}.",
    "userMuted": "@{username} has been successfully muted",
    "userSettings": "User Settings",
    "userSettingsAudienceBlurb": "This will be the default audience selected when posting a status. This can always be changed in the status modal itself.",
    "userSettingsAudienceFollowers": "Followers only",
    "userSettingsAudienceFollowersBlurb": "Only your followers can see and interact with your status.",
    "userSettingsAudiencePublic": "Public",
    "userSettingsAudiencePublicBlurb": "All users can see and interact with your status.",
    "userSettingsDefaultAudience": "Default audience selection for statuses",
    "userSettingsFavoritesOptions": "Favorites options",
    "userSettingsFavoritesShowPublicly": "Show favorites publicly",
    "userSettingsFollowCountDontShow": "Don't show follow count",
    "userSettingsFollowerCountDontShow": "Don't show follower count",
    "userSettingsFollowersOnlyRestricted": "Show favorites publicly",
    "userSettingsFollowingDontShow": "Don't show who I'm following",
    "userSettingsFollowingMeDontShow": "Don't show who's following me",
    "userSettingsFollowingOptions": "Following options",
    "userSettingsFollowingOptionsRestrict": "Restrict your account to approved followers only",
    "userSettingsNameAndBio": "Name & Bio",
    "userSettingsProfileOptions": "Profile Options",
    "userSettingsUserBio": "User Bio",
    "userUnblocked": "@{username} has been successfully unblocked",
    "userUnfollowed": "@{username} unfollowed",
    "userUnmuted": "@{username} has been successfully unmuted",
    "usernameOrEmail": "User Name or Email",
    "usernameOrEmailAddress": "User Name or Email Address",
    "users": "Users",
    "view": "View",
    "viewAccount": "View Account",
    "viewAccountOnHomeServer": "View account on home server",
    "viewChat": "View Chat",
    "viewProfile": "View Profile",
    "viewStatusThread": "View status thread.",
    "viewingInstalledPwa": "You are looking at an installed version of Pleroma!",
    "voteCount": "{n, plural, one {{n} vote} other {{n} votes} }",
    "voteSaved": "Vote saved",
    "whereAreWe": "Where are we?",
    "wholeKnownNetwork": "The Whole Known Network",
    "youCanOnlyDeleteYourOwnStatuses": "You can only delete your own statuses",
    "youCannotBlockYourself": "You cannot block yourself, sorry!",
    "youCannotFollowYourself": "You cannot follow yourself, sorry!",
    "youCannotMuteYourself": "You cannot mute yourself, sorry!",
    "youCannotSeeSomeoneElsesFavorites": "You cannot see someone elses favorites",
    "youHaveNoBlocks": "You have no blocks",
    "youHaveNoChats": "You have no chats",
    "youHaveNoMutes": "You have no mutes",
    "youHaveNotFavorited": "You have not favorited anything yet.",
    "youMustConfirmYourPassword": "You must confirm your password.",
    "youMustEnterABio": "You must enter a bio, or skip this form.",
    "youMustEnterCaptcha": "You must enter captcha.",
    "youMustEnterCurrentPassword": "You must enter your current password.",
    "youMustEnterDisplayName": "You must enter a display name",
    "youMustEnterEmail": "You must enter an email address",
    "youMustEnterNewPassword": "You must enter a new password.",
    "youMustEnterPassword": "You must enter your password.",
    "youMustEnterUsername": "You must enter your user name",
    "youMustEnterUsernameOrEmail": "You must enter a user name or email address",
    "youMustFillInOrRemoveUnusedOptions": "You must fill in or remove unsed options.",
    "youMustSignInToBlockUser": "You must sign in to block a user",
    "youMustSignInToClearYourAvatar": "You must sign in to clear your avatar",
    "youMustSignInToCreateStatus": "You must sign in to post a status",
    "youMustSignInToDeleteStatus": "You must be signed in to delete a status.",
    "youMustSignInToFavoriteStatus": "You must sign in to favorite a status",
    "youMustSignInToFollowUser": "You must sign in to follow a user",
    "youMustSignInToMessageSomeone": "You must sign in to message someone",
    "youMustSignInToMuteConversations": "You must sign in to mute conversations",
    "youMustSignInToMuteUser": "You must sign in to mute someone",
    "youMustSignInToReactWithEmoji": "You must sign in to react with an emoji",
    "youMustSignInToRepostStatus": "You must sign in to repost a status",
    "youMustSignInToSeeThatPage": "You must be signed in to see that page",
    "youMustSignInToSeeWhoFollowsThisPerson": "You must sign in to see who follows this person.",
    "youMustSignInToSeeWhoThisPersonFollows": "You must sign in to see who this person follows.",
    "youMustSignInToSeeYourDirectMessages": "You must sign in to see your direct messages",
    "youMustSignInToSeeYourFavorites": "You must sign in to see your favorites.",
    "youMustSignInToSeeYourNotifications": "You must sign in to see your notifications",
    "youMustSignInToUnblockUser": "You must be signed in to unblock users",
    "youMustSignInToUnfollowUser": "You must sign in to unfollow a user",
    "youMustSignInToUnmuteConversations": "You must sign in to unmute conversations.",
    "youMustSignInToUnmuteUser": "You must sign in to unmute someone",
    "youMustSignInToUpdateYourAvatar": "You must sign in to update your avatar",
    "youMustSignInToVote": "You must sign in to vote",
    "yourBioIsTooLong": "Your bio is too long, please keep it below {max} characters.",
    "yourBlocksHaveBeenExported": "Your blocks have been successfully exported.",
    "yourBlocksHaveBeenImported": "Your blocks have been successfully imported.",
    "yourConfirmPasswordDoesNotMatch": "Your password confirmation does not match your password.",
    "yourExportIsComplete": "Your export has been successful.",
    "yourFollowsHaveBeenExported": "Your follows have been successfully exported.",
    "yourFollowsHaveBeenImported": "Your follows have been successfully imported.",
    "yourImportIsComplete": "Your import has been successful.",
    "yourNameIs": "Your Name is:",
    "yourPostIsTooLong": "Your message is too long. Please keep it below {max} characters.",
    "yourPostMustHaveContent": "Your message must have either content or media attachments.",
    "yourReplyWasPosted": "Your Reply was Posted",
    "yourStatusWasPosted": "Your Status was Posted",
    "yourUsernameContainsInvalidCharacters": "Your user name contains invalid characters.",
    "yourUsernameIsTooLong": "Your user name is too long, please keep it below {max} characters."
  };
  _exports.default = _default;
});