define("pleroma-pwa/routes/message/chat", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MessageDetailsRoute = (_class = (_temp = class MessageDetailsRoute extends Ember.Route.extend(_authenticatedRoute.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "chatActions", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "toast", _descriptor4, this);
    }

    async afterModel(models) {
      var _chat$lastMessage;

      let {
        chat
      } = models;
      let lastReadId = (_chat$lastMessage = chat.lastMessage) === null || _chat$lastMessage === void 0 ? void 0 : _chat$lastMessage.id;
      let chatId = chat.id;

      if (lastReadId && chatId) {
        this.chatActions.markChatAsRead.perform(chatId, lastReadId, {
          delayed: false
        });
      }
    }

    async model(params) {
      try {
        let chat = await this.chatActions.assertChat.perform(params.id); // TODO: Handle participants like message.compose route did before. Do this when multiple participants are possible.

        await chat.messages;
        return {
          chat,
          initialLinks: params.links
        };
      } catch (err) {
        let formatted = this.error.formatPayloadErrors(err);

        for (let {
          detail: message
        } of formatted.errors) {
          this.toast.notify({
            message,
            type: 'error'
          });
        }

        this.transitionTo('messages');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatActions", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MessageDetailsRoute;
});