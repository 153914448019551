define("pleroma-pwa/serializers/status", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      account: {
        deserialize: 'records'
      },
      card: {
        deserialize: 'records'
      },
      emojis: {
        deserialize: 'records'
      },
      reblog: {
        deserialize: 'records'
      },
      mediaAttachments: {
        deserialize: 'records'
      },
      mentions: {
        deserialize: 'records'
      },
      pleroma: {
        embedded: 'always'
      },
      poll: {
        embedded: 'always'
      },
      ancestors: {
        serialize: false,
        deserialize: false
      },
      descendants: {
        serialize: false,
        deserialize: false
      }
    },

    // This overrides `normalize` from `EmbeddedRecordsMixin`.
    normalize(typeClass, hash, prop) {
      // card
      if (hash.id && hash.card) {
        hash.card = { ...hash.card,
          id: hash.id
        };
      } // status-extra


      if (hash.id && hash.pleroma) {
        hash.pleroma = { ...hash.pleroma,
          id: hash.id
        };
      }

      return this._super(typeClass, hash, prop);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        statuses: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        status: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    serialize(snapshot, options) {
      var _json$to, _json$media_ids;

      let json = this._super(snapshot, options);
      /* eslint-disable camelcase */


      return { ...(json.content && {
          status: json.content
        }),
        // The text of the status	Optional*
        visibility: json.visibility,
        //	One of direct, private, unlisted public	Optional
        sensitive: json.sensitive,
        //	Mark the media in the status as sensitive	Optional
        ...(json.in_reply_to_id && {
          in_reply_to_id: json.in_reply_to_id
        }),
        // ID of the status you want to reply to	Optional
        ...(((_json$to = json.to) === null || _json$to === void 0 ? void 0 : _json$to.length) && {
          to: json.to
        }),
        // A list of nicknames (like lain@soykaf.club or lain on the local server) that will be used to determine who is going to be addressed by this post. Using this will disable the implicit addressing by mentioned names in the status body, only the people in the to list will be addressed. The normal rules for for post visibility are not affected by this and will still apply.
        ...(json.in_reply_to_conversation_id && {
          in_reply_to_conversation_id: json.in_reply_to_conversation_id
        }),
        // Will reply to a given conversation, addressing only the people who are part of the recipient set of that conversation. Sets the visibility to direct.
        ...(((_json$media_ids = json.media_ids) === null || _json$media_ids === void 0 ? void 0 : _json$media_ids.length) && {
          media_ids: json.media_ids
        }),
        //	Array of media IDs to attach to the status	Optional*
        ...(json.poll && {
          poll: {
            //	Nested parameters to attach a poll to the status	Optional*	2.8.0
            options: json.poll.options.mapBy('title'),
            // Array of poll answer strings	Required
            expires_in: json.poll.expires_in // Duration the poll should be open for in seconds	Required
            // poll[multiple]	Whether multiple choices should be allowed	Optional
            // poll[hide_totals]	Whether to hide totals until the poll ends	Optional

          }
        }) // spoiler_text: [],//	Text to be shown as a warning before the actual content	Optional
        // scheduled_at: //	Timestamp string to schedule posting of status (ISO 8601)	Optional	2.7.0
        // language: this.instances.current.chosenLanguage, //	Override language code of the toot (ISO 639-2)	Optional

      };
      /* eslint-enable camelcase */
    }

  });

  _exports.default = _default;
});