define("pleroma-pwa/serializers/user", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    pleromaApi: Ember.inject.service(),
    attrs: {
      emojis: {
        deserialize: 'records'
      },
      pleroma: {
        embedded: 'always'
      },
      source: {
        embedded: 'always'
      },
      avatar: {
        serialize: false
      },
      displayName: {
        serialize: false
      }
    },

    normalize(model, hash, prop) {
      hash.links = {
        mutes: this.pleromaApi.endpoints.accountMutes,
        blocks: this.pleromaApi.endpoints.accountBlocks,
        tokens: this.pleromaApi.endpoints.oauthTokens
      }; // user-source

      if (hash.id && hash.source) {
        hash.source = { ...hash.source,
          id: hash.id
        };
      } // user-extra


      if (hash.id && hash.pleroma) {
        hash.pleroma = { ...hash.pleroma,
          id: hash.id
        };
      }

      return this._super(model, hash, prop);
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        user: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        users: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    serializeIntoHash(hash, type, record, options) {
      Ember.setProperties(hash, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});