define("pleroma-pwa/templates/search/results/people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AUPcSgS+",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"searchResult\",\"accounts\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"profile-card\",[],[[\"@user\",\"@includeActions\"],[[27,[24,1],[]],true]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"  \"],[7,\"search-no-results\",[],[[\"@type\"],[\"people\"]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/search/results/people.hbs"
    }
  });

  _exports.default = _default;
});