define("pleroma-pwa/components/post-status-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" class="post-status-button button button--pill button--fab" data-test-selector="post-status-button" {{on "click" (route-action "createStatus" "text")}}>
    {{svg-jar "post" class="post-status-button__icon button__icon"}}
    <span class="post-status-button__text">{{t "postAStatus"}}</span>
  </button>
  
  */
  {
    id: "xv7yuj8N",
    block: "{\"symbols\":[],\"statements\":[[9,\"button\",false],[23,\"class\",\"post-status-button button button--pill button--fab\",null],[23,\"data-test-selector\",\"post-status-button\",null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[\"click\",[31,135,12,[27,[26,0,\"CallHead\"],[]],[\"createStatus\",\"text\"],null]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,178,7,[27,[26,2,\"CallHead\"],[]],[\"post\"],[[\"class\"],[\"post-status-button__icon button__icon\"]]]],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"post-status-button__text\",null],[10],[1,0,0,0,[31,284,1,[27,[26,3,\"CallHead\"],[]],[\"postAStatus\"],null]],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"on\",\"svg-jar\",\"t\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/post-status-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});