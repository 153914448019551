define("pleroma-pwa/initializers/modify-link-to-active-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.LinkComponent.reopen({
      activeClass: 'is-active'
    });
  }

  var _default = {
    name: 'modify-link-to-active-class',
    initialize
  };
  _exports.default = _default;
});