define("pleroma-pwa/components/search-no-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="search__empty-tab">
    <h2 class="search__empty-tab__heading">{{t "searchNoResultsTitle"}}</h2>
    <p>{{t "searchNoResults" type=(if @type @type "")}}</p>
  </div>
  
  */
  {
    id: "2FetuNfS",
    block: "{\"symbols\":[\"@type\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"search__empty-tab\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"h2\",true],[12,\"class\",\"search__empty-tab__heading\",null],[10],[1,0,0,0,[31,75,1,[27,[26,0,\"CallHead\"],[]],[\"searchNoResultsTitle\"],null]],[11],[1,1,0,0,\"\\n  \"],[9,\"p\",true],[10],[1,0,0,0,[31,114,1,[27,[26,0,\"CallHead\"],[]],[\"searchNoResults\"],[[\"type\"],[[31,140,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]],[27,[24,1],[]],\"\"],null]]]]],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/search-no-results.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});