define("pleroma-pwa/models/attachment", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "ember-concerns"], function (_exports, _model, _loadableModel, _emberConcerns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_loadableModel.default, {
    type: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    previewUrl: (0, _model.attr)('string'),
    remoteUrl: (0, _model.attr)('string'),
    textUrl: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    blurhash: (0, _model.attr)('string'),
    meta: (0, _model.attr)('object'),
    // Uploads won't work without this being specified.
    // eslint-disable-next-line ember/no-empty-attrs
    file: (0, _model.attr)(),
    pleroma: (0, _model.belongsTo)('attachment-extra', {
      async: false,
      inverse: null
    }),
    uploader: (0, _emberConcerns.inject)('attachment/uploader'),
    hasOriginalUrl: Ember.computed('previewUrl', 'url', function () {
      return this.previewUrl !== this.url;
    }),
    dimensions: Ember.computed('meta', function () {
      let {
        meta
      } = this;
      if (meta.small) return meta.small;
      if (meta.original) return meta.original;
      return {};
    })
  }); // type	unknown, image, gifv, video, Meta
  // remote_url	String (URL)		0.6.0
  // preview_url	String (URL)		0.6.0
  // text_url	String (URL)		0.6.0
  // meta	Hash		1.5.0
  // description
  // May contain subtrees small and original.
  // Images may contain width, height, size, aspect, while videos (including GIFV) may contain width, height, frame_rate, duration and bitrate.
  // There may be another top-level object, focus with the coordinates x and y. These coordinates can be used for smart thumbnail cropping, see this for reference.


  _exports.default = _default;
});