define("pleroma-pwa/routes/settings/profile", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    intl: Ember.inject.service(),

    titleToken() {
      return this.intl.t('profile');
    }

  });

  _exports.default = _default;
});