define("pleroma-pwa/components/sidebar/instance-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="sidebar__box sidebar__box--padded small-hide large-show" data-test-selector="sidebar-instance-information">
  
    <LoadHtml class="sidebar__instance-html" @path="instance/panel.html" />
  
    <p class="sidebar__meta"><LinkTo @route="about" data-test-selector="sidebar-about-page-link">{{t "moreInformation"}}</LinkTo></p>
  
  </section>
  
  */
  {
    id: "bp1WPClu",
    block: "{\"symbols\":[],\"statements\":[[9,\"section\",true],[12,\"class\",\"sidebar__box sidebar__box--padded small-hide large-show\",null],[12,\"data-test-selector\",\"sidebar-instance-information\",null],[10],[1,1,0,0,\"\\n\\n  \"],[7,\"load-html\",[[23,\"class\",\"sidebar__instance-html\",null]],[[\"@path\"],[\"instance/panel.html\"]],null],[1,1,0,0,\"\\n\\n  \"],[9,\"p\",true],[12,\"class\",\"sidebar__meta\",null],[10],[7,\"link-to\",[[23,\"data-test-selector\",\"sidebar-about-page-link\",null]],[[\"@route\"],[\"about\"]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,297,1,[27,[26,0,\"CallHead\"],[]],[\"moreInformation\"],null]]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/sidebar/instance-information.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});