define("pleroma-pwa/adapters/node-info", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    urlForQueryRecord() {
      return this.pleromaApi.endpoints.nodeInfo;
    }

  });

  _exports.default = _default;
});