define("pleroma-pwa/components/status-visibility", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    this.shouldShow
    @status.visibilityState.height
  }}
  
  */
  {
    id: "AdMcFWW3",
    block: "{\"symbols\":[\"@status\",\"&default\"],\"statements\":[[16,2,[[27,[24,0],[\"shouldShow\"]],[27,[24,1],[\"visibilityState\",\"height\"]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "pleroma-pwa/components/status-visibility.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, class extends _component.default {
    get shouldShow() {
      let {
        status
      } = this.args;

      if (!status.visibilityState) {
        // if not a status, return true
        return true;
      }

      return status.visibilityState.shouldShow;
    }

  });

  _exports.default = _default;
});