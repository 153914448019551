define("pleroma-pwa/templates/feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9jHEMm08",
    "block": "{\"symbols\":[\"feed\",\"status\",\"index\",\"&attrs\"],\"statements\":[[9,\"main\",false],[23,\"class\",\"main feed\",null],[23,\"role\",\"main\",null],[23,\"aria-labelledby\",\"feed__header\",null],[14,\"aria-busy\",[31,115,9,[27,[26,2,\"CallHead\"],[]],[[31,126,7,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"feed\",\"isLoading\"]]],null]],null],null],[15,4],[10],[1,1,0,0,\"\\n  \"],[7,\"feed/switcher\",[],[[\"@feed\"],[[27,[24,0],[\"model\",\"feed\"]]]],null],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[31,244,3,[27,[26,4,\"CallHead\"],[]],[[31,249,7,[27,[26,3,\"CallHead\"],[]],[\"session\"],null],\"isAuthenticated\"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"feed/status-form-trigger\",[],[[],[]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[7,\"feed/loader\",[],[[\"@feed\",\"@beginningOfFeedCallout\"],[[27,[24,0],[\"model\",\"feed\"]],[31,407,1,[27,[26,6,\"CallHead\"],[]],[\"beginningOfFeed\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,8,\"BlockHead\"],[]],[[31,0,0,[27,[26,7,\"CallHead\"],[]],[[31,0,0,[27,[26,7,\"CallHead\"],[]],[[27,[24,1],[\"content\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[7,\"feed-item/index\",[],[[\"@feedItem\",\"@hideUnseen\",\"@posinset\",\"@setsize\"],[[27,[24,2],[]],true,[31,746,3,[27,[26,0,\"CallHead\"],[]],[[27,[24,3],[]],1],null],[27,[24,0],[\"model\",\"feed\",\"content\",\"length\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[2,3]}]]],[1,1,0,0,\"  \"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,10,\"CallHead\"],[]],[[31,0,0,[27,[26,9,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"add\",\"to-bool\",\"to-string\",\"service\",\"get\",\"if\",\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/feed.hbs"
    }
  });

  _exports.default = _default;
});