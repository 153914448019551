define("pleroma-pwa/serializers/relationship", ["exports", "pleroma-pwa/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload || !payload[0] || !payload[0].id || payload[0].id !== id) {
        throw new Error('no matching relationship');
      }

      let wrappedPayload = {
        relationship: payload[0]
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        relationship: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});