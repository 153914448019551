define("pleroma-pwa/adapters/instance", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    urlForQueryRecord() {
      return this.pleromaApi.endpoints.instance;
    },

    ajaxOptions(url, type, options) {
      let hash = this._super(url, type, options);

      hash.url = url;
      return hash;
    }

  });

  _exports.default = _default;
});