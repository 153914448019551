define("pleroma-pwa/components/sticky-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="sticky-header__wrapper-piece sticky-header__wrapper-piece--top {{@topPieceClass}}"></div>
  {{yield}}
  <div class="sticky-header__wrapper-piece {{@bottomPieceClass}}"></div>
  <div class="sticky-header__wrapper-border {{@bottomBorderClass}}"></div>
  
  */
  {
    id: "gJEOHfRm",
    block: "{\"symbols\":[\"@topPieceClass\",\"&default\",\"@bottomPieceClass\",\"@bottomBorderClass\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[\"sticky-header__wrapper-piece sticky-header__wrapper-piece--top \",[27,[24,1],[]]]],null],[10],[11],[1,1,0,0,\"\\n\"],[16,2,null],[1,1,0,0,\"\\n\"],[9,\"div\",true],[13,\"class\",[32,[\"sticky-header__wrapper-piece \",[27,[24,3],[]]]],null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[13,\"class\",[32,[\"sticky-header__wrapper-border \",[27,[24,4],[]]]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "pleroma-pwa/components/sticky-header.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});