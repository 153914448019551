define("pleroma-pwa/concerns/status/emoji-state", ["exports", "ember-concerns"], function (_exports, _emberConcerns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let fetched = {};

  class StatusEmojiStateConcern extends _emberConcerns.default {
    get isFetched() {
      let {
        id
      } = this.model;
      return !!fetched[id];
    }

    setFetched() {
      let {
        id
      } = this.model;
      fetched[id] = true;
    }

    unsetFetched() {
      let {
        id
      } = this.model;
      fetched[id] = false;
    }

  }

  _exports.default = StatusEmojiStateConcern;
});