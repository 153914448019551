define("pleroma-pwa/serializers/hashtag", ["exports", "pleroma-pwa/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractId(modelClass, resourceHash) {
      return resourceHash.name;
    }

  });

  _exports.default = _default;
});