define("pleroma-pwa/services/bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BusService extends Ember.Service.extend(Ember.Evented) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "events", {
        'blocked': true,
        'unblocked': true,
        'followed': true,
        'unfollowed': true,
        'muted': true,
        'unmuted': true
      });
    }

    touch() {}

    on(event) {
      (false && !(this.events[event]) && Ember.assert(`You must pass a valid event that we know about, you passed: ${event}`, this.events[event]));
      super.on(...arguments);
    }

    trigger(event) {
      (false && !(this.events[event]) && Ember.assert(`You must pass a valid event that we know about, you passed: ${event}`, this.events[event]));
      super.trigger(...arguments);
    }

  }

  _exports.default = BusService;
});