define("pleroma-pwa/helpers/camel-to-sentence-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Will accept a camelCase string and return a Sentence Case string.
   */
  var _default = Ember.Helper.helper(function camelToSentenceCase([text = '']) {
    let withSpaces = text.replace(/([A-Z])/g, ' $1');
    let sentence = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
    return sentence;
  });

  _exports.default = _default;
});