define("pleroma-pwa/concerns/user/names", ["exports", "ember-concerns", "pleroma-pwa/helpers/emojify"], function (_exports, _emberConcerns, _emojify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserNamesConcern = (_dec = Ember.computed('model.acct', 'pleromaApi.apiBaseUrl'), _dec2 = Ember.computed('model.{displayName,emojis}'), _dec3 = Ember.computed('model.{displayName,emojis}'), (_class = (_temp = class UserNamesConcern extends _emberConcerns.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "pleromaApi", _descriptor, this);
    }

    get fullScreenName() {
      let {
        acct
      } = this.model;

      if (acct.includes('@')) {
        return acct;
      }

      let {
        apiBaseUrl
      } = this.pleromaApi;
      let url = new URL(apiBaseUrl);
      return `${acct}@${url.hostname}`;
    }

    get displayNameWithEmojis() {
      let {
        displayName,
        emojis
      } = this.model;
      return (0, _emojify.addEmojis)(displayName, emojis || []);
    }

    get displayNameWithoutEmojis() {
      let {
        displayName,
        emojis
      } = this.model;
      return (0, _emojify.removeEmojis)(displayName, emojis || []);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullScreenName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "fullScreenName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayNameWithEmojis", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "displayNameWithEmojis"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayNameWithoutEmojis", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "displayNameWithoutEmojis"), _class.prototype)), _class));
  _exports.default = UserNamesConcern;
});