define("pleroma-pwa/utils/scroll-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollToTop;

  function scrollToTop(element = window) {
    let behavior = 'auto';

    if (0 === element.scrollTop) {
      return;
    }

    if (Math.abs(element.scrollTop) < 300) {
      behavior = 'smooth';
    }

    element.scrollTo({
      top: 0,
      behavior
    });
  }
});