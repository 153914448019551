define("pleroma-pwa/models/instance", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "pleroma-pwa/config/environment"], function (_exports, _model, _loadableModel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://docs-develop.pleroma.social/backend/configuration/cheatsheet/
  var _default = _model.default.extend(_loadableModel.default, {
    description: (0, _model.attr)('string'),
    // "A Pleroma instance, an alternative fediverse server"
    email: (0, _model.attr)('string'),
    // "example@example.com"
    registrations: (0, _model.attr)('boolean'),
    // true
    thumbnail: (0, _model.attr)('string'),
    // "https://stupid-brown-camel.gigalixirapp.com/instance/thumbnail.jpeg"
    title: (0, _model.attr)('string'),
    // "Pleroma"
    uri: (0, _model.attr)('string'),
    // "https://stupid-brown-camel.gigalixirapp.com"
    version: (0, _model.attr)('string'),
    // "2.7.2 (compatible; Pleroma 1.0.0)"
    urls: (0, _model.attr)('object'),
    // { streamingApi: "wss://stupid-brown-camel.gigalixirapp.com"}
    stats: (0, _model.attr)('object'),
    avatarUploadLimit: (0, _model.attr)('number', {
      // File size limit of user’s profile avatars.
      defaultValue() {
        return _environment.default.APP.MAX_AVATAR_SIZE;
      }

    }),
    bannerUploadLimit: (0, _model.attr)('number', {
      // File size limit of user’s profile banners.
      defaultValue() {
        return _environment.default.APP.MAX_BANNER_SIZE;
      }

    }),
    uploadLimit: (0, _model.attr)('number', {
      // File size limit of uploads (except for avatar, background, banner).
      defaultValue() {
        return _environment.default.APP.MAX_UPLOAD_SIZE;
      }

    }),
    backgroundUploadLimit: (0, _model.attr)('number', {
      // File size limit of user’s profile backgrounds.
      defaultValue() {
        return _environment.default.APP.MAX_BANNER_SIZE;
      }

    }),
    maxTootChars: (0, _model.attr)('number', {
      defaultValue() {
        return _environment.default.APP.MAX_STATUS_CHARS;
      }

    }),
    userBioLength: (0, _model.attr)('number', {
      // A user bio maximum length (default: 5000).
      defaultValue() {
        return _environment.default.APP.MAX_BIO_LENGTH;
      }

    }),
    userNameLength: (0, _model.attr)('number', {
      // A user name maximum length (default: 100).
      defaultValue() {
        return _environment.default.APP.USER_NAME_LENGTH;
      }

    }),
    languages: (0, _model.attr)('array', {
      defaultValue() {
        return ['en'];
      }

    }),
    pollLimits: (0, _model.attr)('object', {
      defaultValue() {
        return {
          maxExpiration: 31536000,
          maxOptionChars: 200,
          maxOptions: 20,
          minExpiration: 0
        };
      }

    }),
    credentials: (0, _model.belongsTo)('client-credential', {
      async: false,
      inverse: null
    }),
    token: (0, _model.belongsTo)('client-token', {
      async: false,
      inverse: null
    }),
    nodeInfo: (0, _model.belongsTo)('node-info', {
      // Fetched and set at runtime
      async: false,
      inverse: null
    }),
    backgroundImageUrl: Ember.computed('backgroundImage', 'uri', function () {
      return this.backgroundImage && `${this.uri}${this.backgroundImage}`;
    }),
    chosenLanguage: Ember.computed('languages', 'intl.locales', function () {
      // TODO: Someday this should also account for the language set in the web-app manifest. If it gives us trouble, take another stab.
      // TODO: This should also account for the locale on the sessions user (if set)
      if (this.languages) {
        if (this.intl.locales.includes(this.languages.firstObject && this.languages.firstObject.toLowerCase())) {
          return this.languages.firstObject;
        } // Pleroma uses: https://en.wikipedia.org/wiki/ISO_639... ember-intl uses: https://github.com/andyearnshaw/Intl.js/tree/master/locale-data/json


        if (this.languages.includes('en')) {
          return 'en';
        }
      }

      return 'en';
    }),
    thumbnailUrl: Ember.computed('thumbnail', 'uri', function () {
      if (!this.thumbnail || !this.thumbnail.startsWith('/') || this.thumbnail.startsWith('data:')) {
        return this.thumbnail;
      }

      return `${this.uri}${this.thumbnail}`;
    }),
    intl: Ember.inject.service()
  });

  _exports.default = _default;
});