define("pleroma-pwa/serializers/application", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    keyForAttribute(attr
    /* , method */
    ) {
      return Ember.String.underscore(attr);
    }

  });

  _exports.default = _default;
});