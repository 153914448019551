define("pleroma-pwa/models/user", ["exports", "@ember-data/model", "ember-api-actions", "ember-concerns", "ember-data-storefront/mixins/loadable-model"], function (_exports, _model, _emberApiActions, _emberConcerns, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_loadableModel.default, {
    acct: (0, _model.attr)('string'),
    avatar: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    // @greg
    locale: (0, _model.attr)('string'),
    followersCount: (0, _model.attr)('number'),
    followingCount: (0, _model.attr)('number'),
    statusesCount: (0, _model.attr)('number'),
    locked: (0, _model.attr)('boolean'),
    note: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),

    /* eslint-disable ember-data-sync-relationships/no-async-relationships */
    mutes: (0, _model.hasMany)('user', {
      inverse: null
    }),
    blocks: (0, _model.hasMany)('user', {
      inverse: null
    }),
    tokens: (0, _model.hasMany)('token', {
      inverse: 'user'
    }),

    /* eslint-enable ember-data-sync-relationships/no-async-relationships */
    emojis: (0, _model.hasMany)('emoji', {
      async: false,
      inverse: null
    }),
    pleroma: (0, _model.belongsTo)('user-extra', {
      async: false,
      inverse: 'user'
    }),
    // Represents display or publishing preferences of user's own account. Returned as an additional entity when verifying and updated credentials, as an attribute of Account.
    source: (0, _model.belongsTo)('user-source', {
      async: false,
      inverse: null
    }),
    bio: Ember.computed.reads('note'),
    defaultScope: Ember.computed.reads('source.privacy'),
    names: (0, _emberConcerns.inject)('user/names'),
    remoteFollowUrl: Ember.computed('url', function () {
      let serverUrl = new URL(this.url);
      return `${serverUrl.protocol}//${serverUrl.host}/main/ostatus`;
    }),
    isLocal: Ember.computed('acct', function () {
      return !this.acct.includes('@');
    }),
    block: (0, _emberApiActions.memberAction)({
      path: 'block',
      type: 'post'
    }),
    unblock: (0, _emberApiActions.memberAction)({
      path: 'unblock',
      type: 'post'
    }),
    mute: (0, _emberApiActions.memberAction)({
      path: 'mute',
      type: 'post'
    }),
    unmute: (0, _emberApiActions.memberAction)({
      path: 'unmute',
      type: 'post'
    }),
    follow: (0, _emberApiActions.memberAction)({
      path: 'follow',
      type: 'post'
    }),
    unfollow: (0, _emberApiActions.memberAction)({
      path: 'unfollow',
      type: 'post'
    })
  }); // return {
  //   acct: 'Greg',
  //   avatar: 'https://stupid-brown-camel.gigalixirapp.com/images/avi.png',
  //   avatar_static: 'https://stupid-brown-camel.gigalixirapp.com/images/avi.png',
  //   bot: false,
  //   created_at: '2019-08-12T16:47:02.000Z',
  //   display_name: 'Greg',
  //   emojis: [],
  //   fields: [],
  //   followers_count: 0,
  //   following_count: 0,
  //   header: 'https://stupid-brown-camel.gigalixirapp.com/images/banner.png',
  //   header_static: 'https://stupid-brown-camel.gigalixirapp.com/images/banner.png',
  //   id: '9loeB9ihTWXWHOl7xo',
  //   locked: false,
  //   note: 'GregGreg',
  //   statuses_count: 0,
  //   url: 'https://stupid-brown-camel.gigalixirapp.com/users/Greg',
  //   username: 'Greg',
  //   pleroma: {
  //     background_image: null,
  //     chat_token: 'SFMyNTY.g3QAAAACZAAEZGF0YW0AAAASOWxvZUI5aWhUV1hXSE9sN3hvZAAGc2lnbmVkbgYAKXbeh2wB.CC-0T7atRe2iSjVNbNXgvlq9RGEPjvoCuBpxoihn9zk',
  //     confirmation_pending: true,
  //     hide_favorites: true,
  //     hide_followers: false,
  //     hide_follows: false,
  //     is_admin: false,
  //     is_moderator: false,
  //     settings_store: {},
  //     skip_thread_containment: false,
  //     tags: [],
  //
  //     notification_settings: {
  //       block_from_strangers: true,
  //       hide_notification_contents: true,
  //     },
  //
  //     relationship: {
  //       blocked_by: false,
  //       blocking: false,
  //       domain_blocking: false,
  //       endorsed: false,
  //       followed_by: true,
  //       following: true,
  //       id: '9loeB9ihTWXWHOl7xo',
  //       muting: false,
  //       muting_notifications: false,
  //       requested: false,
  //       showing_reblogs: true,
  //       subscribing: false,
  //     },
  //   },
  //
  //   source: {
  //     note: 'GregGreg',
  //     no_rich_text: false,
  //     show_role: true,
  //     privacy: 'public',
  //     sensitive: false,
  //
  //     pleroma: {
  //       show_role: true,
  //       no_rich_text: false,
  //     },
  //   },
  // };


  _exports.default = _default;
});