define("pleroma-pwa/controllers/message/compose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MessageComposeController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", [{
        participants: {
          type: 'array'
        }
      }, {
        links: {
          type: 'array'
        }
      }]);

      _defineProperty(this, "participants", []);

      _defineProperty(this, "links", null);
    }

  }

  _exports.default = MessageComposeController;
});