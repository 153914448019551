define("pleroma-pwa/components/in-viewport-notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{did-insert this.didInsertNode this}}
    {{will-destroy this.willDestroyNode this}}
    ...attributes
  >
    {{yield}}
  </div>
  
  */
  {
    id: "e1CysCbF",
    block: "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[9,\"div\",false],[15,1],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[\"didInsertNode\"]],[27,[24,0],[]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"willDestroyNode\"]],[27,[24,0],[]]],null],[10],[1,1,0,0,\"\\n  \"],[16,2,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/in-viewport-notifier.hbs"
    }
  });

  let InViewportNotifier = (_dec = Ember.inject.service('in-viewport'), (_class = (_temp = class InViewportNotifier extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "inViewport", _descriptor, this);

      _defineProperty(this, "tagName", '');
    }

    didInsertNode(element, [instance]) {
      let viewportTolerance = {
        bottom: 0
      };
      let {
        onEnter,
        onExit
      } = instance.inViewport.watchElement(element, {
        viewportTolerance
      });
      onEnter(instance.onViewportEntered);
      onExit(instance.onViewportExited);
    }

    willDestroyNode(element, [instance]) {
      instance.inViewport.stopWatching(element);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InViewportNotifier;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InViewportNotifier);
});