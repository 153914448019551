define("pleroma-pwa/templates/search/results-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Bane83h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"search\",[],[[],[]],null],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"main__search--loading\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"img\",true],[12,\"src\",\"/assets/images/spinner.gif\",null],[13,\"alt\",[31,94,1,[27,[26,0,\"CallHead\"],[]],[\"loading\"],null],null],[12,\"width\",\"36\",null],[12,\"height\",\"36\",null],[12,\"role\",\"alert\",null],[12,\"aria-busy\",\"true\",null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/search/results-loading.hbs"
    }
  });

  _exports.default = _default;
});