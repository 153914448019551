define("pleroma-pwa/serializers/token", ["exports", "ember-data/serializers/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend({
    keyForAttribute(attr
    /* , method */
    ) {
      return Ember.String.underscore(attr);
    }

  });

  _exports.default = _default;
});