define("pleroma-pwa/helpers/fixed-grid-layout", ["exports", "ember-collection/layouts/grid"], function (_exports, _grid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    return new _grid.default(params[0], params[1]);
  });

  _exports.default = _default;
});