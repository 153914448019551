define("pleroma-pwa/feeds/chats", ["exports", "pleroma-pwa/feeds/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChatsFeed = (_class = (_temp = class ChatsFeed extends _base.default {
    get name() {
      if (this.features.isEnabled('showDirectMessagesClassic')) {
        return this.intl.t('chats');
      }

      return this.intl.t('directMessages');
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor3, this);

      _initializerDefineProperty(this, "unreadCounts", _descriptor4, this);

      _defineProperty(this, "id", 'chats');

      _defineProperty(this, "params", {});

      _defineProperty(this, "modelName", 'chat');

      _defineProperty(this, "authenticated", true);

      _defineProperty(this, "enqueNewItems", false);

      _defineProperty(this, "url", this.pleromaApi.endpoints.chats);

      this.on('feed:loaded-more', () => {
        Ember.run(() => {
          Ember.set(this, 'canLoadMore', false);
          return this.collectUnreadCount();
        });
      });
      this.on('feed:loaded-new', () => {
        Ember.run(() => {
          return this.collectUnreadCount();
        });
      });
    }

    collectUnreadCount() {
      let unreadChats = this.content.reduce((memo, chat) => {
        return memo + ((chat === null || chat === void 0 ? void 0 : chat.unread) || 0);
      }, 0);
      this.unreadCounts.setUnreadChatCount(unreadChats);
      return this.unreadCounts.unreadChatCount;
    }

    async reset() {
      await super.reset(...arguments);
      Ember.set(this, 'enqueNewItems', false);
    }

    get loadMoreUrl() {
      return this.url;
    }

    get loadNewUrl() {
      return this.url;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "unreadCounts", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ChatsFeed;
});