define("pleroma-pwa/helpers/to-bool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function toBool([value]) {
    return !!value;
  });

  _exports.default = _default;
});