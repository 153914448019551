define("pleroma-pwa/helpers/ember-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function emberObjectHelper(positional, named) {
    return Ember.Object.create(named);
  });

  _exports.default = _default;
});