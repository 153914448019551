define("pleroma-pwa/utils/poller", ["exports", "pleroma-pwa/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Poller {
    constructor(func, intervalTimeout = _environment.default.APP.POLLING_TIMEOUT) {
      _defineProperty(this, "intervalId", null);

      _defineProperty(this, "func", null);

      _defineProperty(this, "intervalTimeout", _environment.default.APP.POLLING_TIMEOUT);

      (false && !(Ember.typeOf(func) === 'function') && Ember.assert('you must pass a func to the poller util, were going to call this func when we poll', Ember.typeOf(func) === 'function'));
      this.func = func;
      this.intervalTimeout = intervalTimeout;
    }

    get started() {
      return Ember.isPresent(this.intervalId);
    }

    start() {
      // escape hatch for tests
      // http://ember-concurrency.com/docs/testing-debugging
      if (_environment.default.environment !== 'test') {
        window.clearInterval(this.intervalId);
        Ember.set(this, 'intervalId', window.setInterval(() => Ember.run(this.func), this.intervalTimeout));
      }
    }

    stop() {
      window.clearInterval(this.intervalId);
    }

  }

  _exports.default = Poller;
});