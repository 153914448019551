define("pleroma-pwa/models/client-credential", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    clientId: attr('string'),
    clientSecret: attr('string'),
    name: attr('string'),
    redirectUri: attr('string'),
    vapidKey: attr('string'),
    website: attr('string')
  });

  _exports.default = _default;
});