define("pleroma-pwa/adapters/user", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    pathForType() {
      return this._super('account');
    },

    urlForQuery() {
      return this.pleromaApi.endpoints.accountSearch;
    },

    urlForQueryRecord(query, type) {
      if (query.me) {
        delete query.me;
        return this.pleromaApi.endpoints.currentUser;
      }

      return this._super(query, type);
    }

  });

  _exports.default = _default;
});