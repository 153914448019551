define("pleroma-pwa/routes/feed", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    feeds: Ember.inject.service(),
    instances: Ember.inject.service(),

    beforeModel(transition) {
      let targetFeed = this.feeds.getFeed(this.paramsFor(this.routeName).id); // Ensure the FeedItem components are rendred without the delay on repeat visits (issue #85)

      targetFeed.content.content.slice(0, 10).forEach(feedItem => {
        feedItem.visibilityState.show();
      });

      if (targetFeed && targetFeed.authenticated) {
        // Allow ember-simple-auth to enforce authenticaion rules.
        return this._super(transition);
      }

      return;
    },

    model(params, transition) {
      let previousModel = this.modelFor(this.routeName);
      let feed = this.feeds.getFeed(params.id);

      if (transition.from && previousModel && previousModel.feed.id !== feed.id) {
        // Unsubscribe from previousFeed when navigating between feed routes.
        this.feeds.unsubscribe(previousModel.feed.id);
      }

      this.feeds.subscribe(feed.id);
      return {
        feed
      };
    },

    deactivate(...args) {
      this._super(...args);

      this.feeds.unsubscribe(this.modelFor('feed').feed.id);
    },

    titleToken(model) {
      return model.feed.name;
    }

  });

  _exports.default = _default;
});