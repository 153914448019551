define("pleroma-pwa/services/emoji-picker", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmojiPickerService = (_dec = Ember.computed('cursorPosition', 'content'), (_class = (_temp = class EmojiPickerService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "rawQuery", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "textarea", void 0);

      _initializerDefineProperty(this, "content", _descriptor3, this);

      _defineProperty(this, "common", ['👍', '😠', '👀', '😂', '🔥']);

      this.getEmojis.perform();
      this.reset();
    }

    reset() {
      this.rawQuery = null;
    }

    touch() {}

    get emojis() {
      var _this$getEmojis$last;

      return (_this$getEmojis$last = this.getEmojis.last) === null || _this$getEmojis$last === void 0 ? void 0 : _this$getEmojis$last.value;
    }

    get cursorPosition() {
      let {
        textarea
      } = this;

      if (!textarea) {
        return null;
      } // Get position of cursor.


      let {
        selectionStart,
        selectionEnd
      } = textarea; // only proceed if cursor is in a single spot, not selecting something.

      if (selectionStart !== selectionEnd) {
        return null;
      }

      return selectionStart;
    }

    get emojiInProgress() {
      let {
        cursorPosition
      } = this;

      if (!cursorPosition) {
        return null;
      }

      let {
        content
      } = this;
      let exp = /:([a-z0-9_-]{1,})/g;
      let inProgress = null;
      let match;

      while ((match = exp.exec(content)) !== null) {
        let [matchText] = match;

        if (cursorPosition === match.index + matchText.length) {
          inProgress = match;
          break;
        }
      }

      return inProgress;
    }

    withNewContent(staticEmoji, fn) {
      var _this$textarea;

      let startIndex = this.emojiInProgress.index;
      let endIndex = this.emojiInProgress.index + this.emojiInProgress[0].length;
      let newContent = this.content.slice(0, startIndex) + staticEmoji.value + this.content.slice(endIndex);
      this.content = newContent;

      if (fn && typeof fn === 'function') {
        fn(newContent);
      }

      if ((_this$textarea = this.textarea) === null || _this$textarea === void 0 ? void 0 : _this$textarea.setSelectionRange) {
        Ember.run.next(() => {
          this.reset();
          this.textarea.focus();
          this.textarea.setSelectionRange(startIndex + 1, startIndex + 1);
        });
      }
    }

    get filteredEmojis() {
      var _this$emojis;

      if ((_this$emojis = this.emojis) === null || _this$emojis === void 0 ? void 0 : _this$emojis.length) {
        let {
          emojis
        } = this;

        if (!Ember.isEmpty(this.queryNormalized)) {
          emojis = emojis.filter(emoji => {
            return emoji.shortcode.includes(this.queryNormalized);
          });
        }

        return emojis;
      }

      return [];
    }

    get commonEmojis() {
      if (this.getEmojis.isRunning || !this.getEmojis.last.value) {
        return [];
      }

      let found = this.getEmojis.last.value.filter(emoji => {
        return this.common.includes(emoji.value);
      });
      return this.common.map(emojiCharacter => {
        return found.find(e => e.value === emojiCharacter);
      });
    }

    get query() {
      if (this.rawQuery !== null) {
        return this.rawQuery;
      }

      return '';
    }

    get queryNormalized() {
      return this.query.toLowerCase();
    }

    interceptEnter(ev) {
      // Ensure it's possible to add new lines when the picker is not shown.
      if (ev.keyCode === 13 && this.emojiInProgress) {
        ev.stopPropagation();
        ev.preventDefault();
        this.chooseIfOnlyOrExactMatch.perform();
      }
    }

    doSetEmojiQuery() {
      if (!this.emojiInProgress) {
        return;
      }

      this.setQuery(this.emojiInProgress[1]);
    }

    setContentWithEvent(ev) {
      let {
        target: input
      } = ev;
      this.content = input.value;
    }

    setQueryWithEvent(ev) {
      let {
        target: input
      } = ev;
      Ember.run.debounce(this, 'rawSetQuery', input.value, 50);
    }

    setQuery(value) {
      Ember.run.debounce(this, 'rawSetQuery', value, 50);
    }

    rawSetQuery(value) {
      this.rawQuery = value;
    }

    *getEmojis() {
      try {
        // Load them to have them around.
        if (Ember.isEmpty(this.store.peekAll('emoji'))) {
          this.store.loadRecords('emoji');
        }

        let staticEmoji = this.store.peekAll('static-emoji');

        if (Ember.isEmpty(staticEmoji)) {
          staticEmoji = this.store.loadRecords('static-emoji');
        }

        return yield staticEmoji;
      } catch (e) {
        Ember.tryInvoke(this.args, 'onNotFound');
      }
    }

    choose(shortcode) {
      let el = document.querySelector(`button[data-shortcode="${shortcode}"]`);

      if (el) {
        el.click();
      }
    }

    *chooseIfOnlyOrExactMatch() {
      let shortcode;

      if (this.filteredEmojis.length === 1) {
        ({
          shortcode
        } = this.filteredEmojis[0]);
      } else {
        // See if there is an exact match.
        let exact = this.filteredEmojis.find(emoji => emoji.shortcode === this.queryNormalized); // If there is, assume user wants to pick it.

        if (exact) {
          ({
            shortcode
          } = exact);
        }
      }

      if (shortcode) {
        yield this.choose(shortcode);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rawQuery", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "content", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "reset", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emojiInProgress", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "emojiInProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "interceptEnter", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "interceptEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doSetEmojiQuery", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "doSetEmojiQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setContentWithEvent", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setContentWithEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setQueryWithEvent", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setQueryWithEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setQuery", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getEmojis", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getEmojis"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "choose", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "choose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseIfOnlyOrExactMatch", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "chooseIfOnlyOrExactMatch"), _class.prototype)), _class));
  _exports.default = EmojiPickerService;
});