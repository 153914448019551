define("pleroma-pwa/modifiers/background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(() => ({
    createModifier() {
      return {
        element: null,

        updateBackground(image) {
          this.element.style.backgroundImage = Ember.String.htmlSafe(`url('${image}')`);
        }

      };
    },

    installModifier(modifier, element, {
      named
    }) {
      modifier.element = element;
      modifier.updateBackground = modifier.updateBackground.bind(modifier);

      if (named.url && (Ember.isEmpty(named.enabled) || named.enabled)) {
        modifier.updateBackground(named.url);
      }
    },

    updateModifier(modifier, {
      named
    }) {
      if (named.url && (Ember.isEmpty(named.enabled) || named.enabled)) {
        modifier.updateBackground(named.url);
      }
    },

    destroyModifier()
    /* state */
    {}

  }), class BackgroundImageModifier {});

  _exports.default = _default;
});