define("pleroma-pwa/services/unread-counts", ["exports", "pleroma-pwa/utils/poller", "ember-concurrency-decorators"], function (_exports, _poller, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UnreadCountsService = (_class = (_temp = class UnreadCountsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "pleromaApi", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _defineProperty(this, "longPoller", new _poller.default(() => this.collectUnreadCounts.perform()));

      _initializerDefineProperty(this, "unreadNotificationsCount", _descriptor3, this);

      _initializerDefineProperty(this, "unreadChatCount", _descriptor4, this);

      _initializerDefineProperty(this, "unreadConversationCount", _descriptor5, this);
    }

    startWatching() {
      this.collectUnreadCounts.perform();
      this.longPoller.start();
    }

    stopWatching() {
      this.longPoller.stop();
    }

    *collectUnreadCounts() {
      var _currentUser$pleroma;

      let response = yield fetch(this.pleromaApi.endpoints.currentUser, {
        headers: this.session.headers
      });
      let currentUser = yield response.json();
      /* eslint-disable-next-line camelcase */

      this.setUnreadConversationCount(currentUser === null || currentUser === void 0 ? void 0 : (_currentUser$pleroma = currentUser.pleroma) === null || _currentUser$pleroma === void 0 ? void 0 : _currentUser$pleroma.unread_conversation_count);
    }

    setUnreadChatCount(value) {
      Ember.set(this, 'unreadChatCount', value || null);
    }

    setUnreadConversationCount(value) {
      Ember.set(this, 'unreadConversationCount', value || null);
    }

    setUnreadNotificationsCount(value) {
      Ember.set(this, 'unreadNotificationsCount', value || null);
    }

    decrementUnreadChatCount() {
      Ember.set(this, 'unreadChatCount', Math.max(this.unreadChatCount - 1, 0) || null);
    }

    decrementUnreadConversationCount() {
      Ember.set(this, 'unreadConversationCount', Math.max(this.unreadConversationCount - 1, 0) || null);
    }

    decrementNotificationsCount() {
      Ember.set(this, 'unreadNotificationsCount', Math.max(this.unreadNotificationsCount - 1, 0) || null);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unreadNotificationsCount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "unreadChatCount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "unreadConversationCount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "collectUnreadCounts", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "collectUnreadCounts"), _class.prototype)), _class);
  _exports.default = UnreadCountsService;
});