define("pleroma-pwa/helpers/scroll-to-top", ["exports", "pleroma-pwa/utils/scroll-to-top"], function (_exports, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function scrollToTopHelper() {
    return function () {
      (0, _scrollToTop.default)();
    };
  });

  _exports.default = _default;
});