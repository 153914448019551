define("pleroma-pwa/helpers/unescape", ["exports", "lodash.unescape"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unescape = unescape;
  _exports.default = void 0;

  function unescape([html]) {
    return (0, _lodash.default)(html);
  }

  var _default = Ember.Helper.helper(unescape);

  _exports.default = _default;
});