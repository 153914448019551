define("pleroma-pwa/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      this.replaceWith('feed', 'all');
    }

  });

  _exports.default = _default;
});