define("pleroma-pwa/templates/search/results/hashtags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Knq5D1mG",
    "block": "{\"symbols\":[\"hashtag\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"searchResult\",\"hashtags\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n    \"],[7,\"link-to\",[[23,\"class\",\"bare-search-result search-results__hashtag advanced-search__hashtag feed-item feed-item--no-actions\",null]],[[\"@route\",\"@model\"],[\"hashtag\",[27,[24,1],[\"name\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[24,1],[\"tag\"]]],[1,1,0,0,\"\\n    \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"  \"],[7,\"search-no-results\",[],[[\"@type\"],[\"hashtag\"]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/search/results/hashtags.hbs"
    }
  });

  _exports.default = _default;
});