define("pleroma-pwa/templates/settings/blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1mvlnNbH",
    "block": "{\"symbols\":[\"blocks\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"main__tab\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"h3\",true],[12,\"class\",\"settings__small-heading\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,69,1,[27,[26,0,\"CallHead\"],[]],[\"blocks\"],null]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,104,1,[27,[26,0,\"CallHead\"],[]],[\"blockSettingsPrompt\"],null]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"model\",\"user\",\"blocks\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"block-settings\",[],[[\"@blocks\",\"@refresh\",\"@block\",\"@unblock\"],[[27,[24,1],[]],[27,[24,0],[\"model\",\"refresh\"]],[31,290,3,[27,[26,2,\"CallHead\"],[]],[[31,295,7,[27,[26,1,\"CallHead\"],[]],[\"userActions\"],null],\"blockUser\"],null],[31,349,3,[27,[26,2,\"CallHead\"],[]],[[31,354,7,[27,[26,1,\"CallHead\"],[]],[\"userActions\"],null],\"unblockUser\"],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"img\",true],[12,\"src\",\"/assets/images/spinner.gif\",null],[13,\"alt\",[31,459,1,[27,[26,0,\"CallHead\"],[]],[\"loading\"],null],null],[12,\"width\",\"148\",null],[12,\"height\",\"148\",null],[12,\"role\",\"alert\",null],[12,\"aria-busy\",\"true\",null],[12,\"class\",\"settings__loading\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"service\",\"get\",\"async-await\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/settings/blocks.hbs"
    }
  });

  _exports.default = _default;
});