define("pleroma-pwa/routes/settings", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),

    model() {
      let user = this.session.currentUser;
      return {
        refresh: () => this.refresh(),
        user
      };
    },

    titleToken() {
      return this.intl.t('userSettings');
    }

  });

  _exports.default = _default;
});