define("pleroma-pwa/components/splash-screen-remover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SplashScreenRemover extends Ember.Component {
    constructor(...args) {
      super(...args);
      let splashScreen = document.getElementById('splash-screen');

      if (splashScreen) {
        splashScreen.parentNode.removeChild(splashScreen);
      }
    }

  }

  _exports.default = SplashScreenRemover;
});