define("pleroma-pwa/helpers/defer-transition-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    router: Ember.inject.service(),

    compute() {
      let {
        router
      } = this;
      (false && !(router) && Ember.assert('Unable to lookup router', router));
      return function () {
        router.transitionTo(...arguments);
      };
    }

  });

  _exports.default = _default;
});