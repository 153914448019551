define("pleroma-pwa/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-fetch/utils/serialize-query-params", "pleroma-pwa/config/environment", "ember-app-scheduler"], function (_exports, _applicationRouteMixin, _serializeQueryParams, _environment, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    authModal: Ember.inject.service(),
    cookies: Ember.inject.service(),
    documentService: Ember.inject.service('-document'),
    emojiPicker: Ember.inject.service(),
    features: Ember.inject.service(),
    feeds: Ember.inject.service(),
    instances: Ember.inject.service(),
    intl: Ember.inject.service(),
    offCanvasNav: Ember.inject.service(),
    preload: Ember.inject.service(),
    pushNotifications: Ember.inject.service(),
    router: Ember.inject.service(),
    routerScroll: Ember.inject.service(),
    sentry: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    unreadCounts: Ember.inject.service(),
    visibility: Ember.inject.service(),
    window: Ember.inject.service(),
    // TODO: perhaps move mgmt of these into a service
    blockGalleryClose: false,
    blockCreateStatusClose: false,
    preventCreateStatusClose: false,

    async beforeModel(...args) {
      this._super(...args);

      this.visibility.touch();
      this.toast.touch();
      this.feeds.touch(); // Enable `usePreload` feature to use.

      await this.preload.load(); // should be a no-op if preload succeeds

      await this.instances.ensureCurrent();
      await Ember.RSVP.all([this.intl.setLocale(this.instances.current.chosenLanguage), this.sentry.setup(), this.feeds.subscribe('chats'), this.feeds.subscribe('notifications')]);
      this.emojiPicker.touch();

      if (this.session.isAuthenticated) {
        this.documentService.documentElement.classList.add('is-signed-in');
        await Ember.RSVP.all([this.session.loadCurrentUser(), this.unreadCounts.startWatching()]);
      } // On the 2nd transition, attempt to subscribe push-notifications


      (0, _emberAppScheduler.whenRouteIdle)().then(() => this.router.one('routeDidChange', () => this.pushNotifications.subscribe.perform()));
    },

    async afterModel(...args) {
      this._super(...args);

      (0, _emberAppScheduler.whenRouteIdle)().then(() => {
        // This focus-visble polyfill is decently heavy handed. Lets defer its IIFE for as long as we can.
        // eslint-disable-next-line no-unused-expressions
        emberAutoImportDynamic("focus-visible"); // On the 2nd transition, and if permission has not been asked for, attempt to subscribe push-notifications

        if (this.features.isEnabled('pushNotifications') && this.pushNotifications.browserPermissionDefault) {
          this.router.one('routeDidChange', () => this.pushNotifications.subscribe.perform());
        }
      });
    },

    actions: {
      willTransition(transition) {
        this.offCanvasNav.close();

        if (this.blockCreateStatusClose) {
          return transition.abort();
        }

        return true;
      },

      onGalleryDragStart() {
        Ember.set(this, 'blockGalleryClose', true);
      },

      onGalleryDragEnd() {
        Ember.run.next(this, () => {
          Ember.set(this, 'blockGalleryClose', false);
        });
      },

      onCloseGalleryModal() {
        if (!this.blockGalleryClose) {
          this.closeGalleryModal();
        }

        return false; // Always return false. The 'closing' is done as part of the query param change.
      },

      onCloseGalleryModalForce() {
        this.closeGalleryModal();
        return false;
      },

      onGalleryCenterChanged(subjectId, newcenterAttachmentId, subjectType) {
        this.openGalleryModal(subjectId, newcenterAttachmentId, subjectType);
      },

      onGallerySubjectNotFound() {
        this.closeGalleryModal();
      },

      onGallerySubjectWithoutAttachments() {
        this.closeGalleryModal();
      },

      openGalleryModal(...args) {
        this.openGalleryModal(...args);
      },

      createStatus(intent = true) {
        this.openStatusModal({
          intent
        });
      },

      createReply(inReplyToStatus) {
        let statusMentions = inReplyToStatus.mentions.map(mention => mention.acct);
        let initialMentions = [inReplyToStatus.account.acct].concat(statusMentions).without(this.session.currentUser.acct).uniq();
        let initialParticipants = [inReplyToStatus.account.id];
        let inReplyToStatusId = inReplyToStatus.id;
        this.openStatusModal({
          intent: 'reply',
          initialParticipants,
          inReplyToStatusId,
          initialMentions
        });
      },

      createMention(initialMentions) {
        this.openStatusModal({
          intent: 'mention',
          initialMentions
        });
      },

      createDirectMessage({
        initialMentions,
        initialParticipants,
        initialLinks
      }) {
        if (this.features.isEnabled('showDirectMessagesClassic')) {
          this.openStatusModal({
            intent: 'direct',
            directMode: 'classic',
            initialMentions,
            initialParticipants,
            initialLinks
          });
          return;
        }

        this.openStatusModal({
          intent: 'direct',
          directMode: 'chat',
          initialParticipants,
          initialLinks
        });
      },

      createChat() {
        this.openStatusModal({
          intent: 'direct',
          directMode: 'chat'
        });
      },

      closeCreateStatusModal() {
        if (this.preventCreateStatusClose) {// no-op
        } else if (this.blockCreateStatusClose) {
          /* eslint-disable no-alert */
          if (this.window.confirm(this.intl.t('ifYouLeavePageStatusWillBeLost'))) {
            Ember.set(this, 'blockCreateStatusClose', false);
            this.closeStatusModal();
          }
        } else {
          this.closeStatusModal();
        }

        return false; // Always return false. The 'closing' is done as part of the query param change.
      },

      forceCloseCreateStatusModal() {
        Ember.set(this, 'blockCreateStatusClose', false);
        return this.closeStatusModal({
          preserveScrollPosition: true
        });
      },

      onStatusEdited() {
        Ember.set(this, 'blockCreateStatusClose', true);
      }

    },

    async closeGalleryModal() {
      await this.transitionTo({
        queryParams: {
          gallerySubject: null,
          galleryAttachment: null,
          gallerySubjectType: null
        }
      });
      Ember.set(this.routerScroll, 'preserveScrollPosition', false);
    },

    async openGalleryModal(gallerySubject, galleryAttachment, gallerySubjectType = 'status') {
      Ember.set(this.routerScroll, 'preserveScrollPosition', true);
      await this.replaceWith({
        queryParams: {
          gallerySubject,
          galleryAttachment,
          gallerySubjectType
        }
      });
    },

    async closeStatusModal(options = {}) {
      let {
        preserveScrollPosition = false
      } = options;
      await this.transitionTo({
        queryParams: {
          compose: null,
          directMode: null,
          inReplyToStatusId: null,
          initialMentions: null,
          initialParticipants: null,
          initialLinks: null
        }
      });
      Ember.set(this.routerScroll, 'preserveScrollPosition', !!preserveScrollPosition);
    },

    async openStatusModal(options) {
      let {
        intent,
        directMode,
        inReplyToStatusId,
        initialMentions,
        initialParticipants,
        initialLinks
      } = options;

      if (!this.session.isAuthenticated) {
        this.session.setReasonForAuthentication('youMustSignInToCreateStatus');
        return this.authModal.showSignInModal();
      }

      Ember.set(this.routerScroll, 'preserveScrollPosition', true);
      await this.transitionTo({
        queryParams: {
          compose: intent,
          ...(directMode && {
            directMode
          }),
          ...(inReplyToStatusId && {
            inReplyToStatusId
          }),
          ...(initialMentions && {
            initialMentions
          }),
          ...(initialParticipants && {
            initialParticipants
          }),
          ...(initialLinks && {
            initialLinks
          })
        }
      });
    },

    // checkout the ESA application-route-mixiin sessionAuthenticated
    // https://github.com/simplabs/ember-simple-auth/blob/master/addon/mixins/application-route-mixin.js
    sessionAuthenticated() {
      let {
        cookies,
        session
      } = this;
      let redirectTarget = cookies.read('ember_simple_auth-redirectTarget');
      this.documentService.documentElement.classList.add('is-signed-in');
      this.feeds.refreshAll().then(() => {
        this.unreadCounts.startWatching();
      });

      if (session.attemptedTransition) {
        session.attemptedTransition.retry();
      } else if (redirectTarget) {
        this.transitionTo(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget');
      } else if (['sign-up', 'sign-in'].includes(this.router.currentRouteName)) {
        // Deviate from ESA here
        let url = `${_environment.default.APP.emberSimpleAuthConfig.routeAfterAuthentication}?${(0, _serializeQueryParams.serializeQueryParams)(this.router.currentRoute.queryParams)}`;
        this.transitionTo(url);
      }
    },

    sessionInvalidated(...args) {
      this.documentService.documentElement.classList.remove('is-signed-in');
      this.unreadCounts.stopWatching();

      this._super(...args);
    },

    title(tokens) {
      return `${this.instances.current.title} - ${tokens.join(' - ')}`;
    }

  });

  _exports.default = _default;
});