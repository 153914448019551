define("pleroma-pwa/concerns/status/media", ["exports", "ember-concerns", "ember-concurrency", "ember-concurrency-decorators", "pleroma-pwa/utils/clipboard"], function (_exports, _emberConcerns, _emberConcurrency, _emberConcurrencyDecorators, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StatusMediaConcern = (_class = (_temp = class StatusMediaConcern extends _emberConcerns.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "statusActions", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "attachments", _descriptor5, this);

      _initializerDefineProperty(this, "uploaderTasks", _descriptor6, this);

      _initializerDefineProperty(this, "files", _descriptor7, this);

      this.reset();
    }

    reset() {
      this.attachments = [];
      this.files = [];
      this.uploaderTasks = {};
      return this.attachments;
    }

    get uploadCount() {
      var _this$attachments;

      return ((_this$attachments = this.attachments) === null || _this$attachments === void 0 ? void 0 : _this$attachments.length) || 0;
    }

    get allSuccessful() {
      return this.attachments.every(a => {
        var _a$uploader;

        return a === null || a === void 0 ? void 0 : (_a$uploader = a.uploader) === null || _a$uploader === void 0 ? void 0 : _a$uploader.uploaded;
      });
    }

    get ids() {
      return this.attachments.map(upload => upload === null || upload === void 0 ? void 0 : upload.id);
    }

    *addAttachments() {
      Ember.debug('addAttachments');
      let {
        files
      } = this;

      if (Ember.isEmpty(files)) {
        throw new Error(this.intl.t('pleaseUploadValidFile'));
      }

      let newAttachments = Array.from(files).map(file => this.store.createRecord('attachment', {
        file
      }));
      this.attachments = this.attachments.concat(newAttachments);
      return yield newAttachments;
    }

    *tryUpload(attachments = []) {
      yield (0, _emberConcurrency.allSettled)(attachments.map(attachment => {
        return this.tryAttachment(attachment);
      }));
      this.setMediaIds();
      this.throwIfError(attachments);
      this.checkIfAllSuccessful();
    }

    checkIfAllSuccessful() {
      Ember.debug('checkIfAllSuccessful');

      if (this.allSuccessful) {
        Ember.debug('all successful');
      }
    }

    tryAttachment(attachment) {
      this.uploaderTasks[Ember.guidFor(attachment)] = attachment.uploader.upload.perform();
      return this.uploaderTasks[Ember.guidFor(attachment)];
    }

    get cancelReason() {
      return this.intl.t('uploadCancelled');
    }

    async handleSelectedFiles(files) {
      this.files = files;

      try {
        let newAttachments = await this.addAttachments.perform();
        await this.tryUpload.perform(newAttachments);
        return this.attachments;
      } catch (rawErr) {
        var _err$message;

        let err = rawErr;

        if (err === null || err === void 0 ? void 0 : (_err$message = err.message) === null || _err$message === void 0 ? void 0 : _err$message.includes(this.cancelReason)) {// do nothing. user knows they cancelled.
        } else {
          let errors = this.error.formErrors(err, 'uploadFailed');
          errors.forEach(({
            detail
          }) => {
            let foundIdentical = this.model.changeset.errors.any(e => {
              return e.key === 'mediaIds' && e.validation.includes(detail);
            });

            if (!foundIdentical) {
              this.model.changeset.pushErrors('mediaIds', detail);
            }
          });
        }

        Ember.notifyPropertyChange(this, 'attachments');
        return this.attachments;
      }
    }

    cancel(attachment) {
      Ember.debug('cancel');
      let uploader = this.uploaderTasks[Ember.guidFor(attachment)];

      if (uploader) {
        uploader.cancel(this.cancelReason);
      }

      this.removeAttachment(attachment);
    }

    throwIfError(attachments = []) {
      let erroredFn = u => u.uploader.uploadError;

      let anyErrored = attachments.any(erroredFn);

      if (anyErrored) {
        let erroredAttachments = attachments.filter(erroredFn);
        let errors = erroredAttachments.map(erroredFn).filter(Boolean);
        throw {
          errors
        };
      }
    }

    setMediaIds() {
      Ember.set(this.model.changeset, 'mediaIds', this.ids.filter(Boolean));
    }

    async onFilesSelected(event) {
      let files = Array.from(event.target.files);
      event.target.value = null;
      this.handleSelectedFiles(files);
    }

    async onFilesPasted(event) {
      let files = (0, _clipboard.filesFromClipboard)(event);

      if (files.length > 0) {
        event.stopPropagation();
        event.preventDefault();
        this.handleSelectedFiles(files);
      }
    }

    removeAttachment(attachment) {
      Ember.debug('removeAttachment');
      this.attachments = this.attachments.filter(a => Ember.guidFor(a) !== Ember.guidFor(attachment));
      this.setMediaIds();
      this.checkIfAllSuccessful();
      return this.model.changeset.mediaIds;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "statusActions", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "attachments", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uploaderTasks", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "files", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "reset", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAttachments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "addAttachments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tryUpload", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "tryUpload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkIfAllSuccessful", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "checkIfAllSuccessful"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "throwIfError", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "throwIfError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setMediaIds", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setMediaIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilesSelected", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onFilesSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilesPasted", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onFilesPasted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAttachment", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "removeAttachment"), _class.prototype)), _class);
  _exports.default = StatusMediaConcern;
});