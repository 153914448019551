define("pleroma-pwa/helpers/scroll-to-bottom", ["exports", "pleroma-pwa/utils/scroll-to-bottom"], function (_exports, _scrollToBottom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function scrollToBottomHelper([targetSelector]) {
    return function () {
      let targetElement;

      if (typeof targetSelector === 'string') {
        targetElement = targetSelector ? document.querySelector(targetSelector) : window;
      } else {
        targetElement = targetSelector;
      }

      Ember.run.next(() => (0, _scrollToBottom.default)(targetElement));
    };
  });

  _exports.default = _default;
});