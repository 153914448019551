define("pleroma-pwa/services/pleroma-api", ["exports", "ember-concerns", "pleroma-pwa/config/environment"], function (_exports, _emberConcerns, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PleromaApiService = (_dec = (0, _emberConcerns.inject)('pleroma-api/endpoints'), _dec2 = Ember.computed('instances.current'), (_class = (_temp = class PleromaApiService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "endpoints", _descriptor, this);

      _initializerDefineProperty(this, "instances", _descriptor2, this);
    }

    get apiBaseUrl() {
      var _this$instances, _this$instances$curre, _config$APP, _window, _window$location;

      if (_environment.default.environment === 'test') {
        return _environment.default.APP.testApiBaseUrl;
      }

      if (Ember.isPresent((_this$instances = this.instances) === null || _this$instances === void 0 ? void 0 : (_this$instances$curre = _this$instances.current) === null || _this$instances$curre === void 0 ? void 0 : _this$instances$curre.uri)) {
        return this.instances.current.uri;
      }

      if (Ember.isPresent(_environment.default === null || _environment.default === void 0 ? void 0 : (_config$APP = _environment.default.APP) === null || _config$APP === void 0 ? void 0 : _config$APP.apiBaseUrl)) {
        return _environment.default.APP.apiBaseUrl;
      }

      if (_environment.default.environment !== 'test' && Ember.isPresent((_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.origin)) {
        return window.location.origin;
      }

      (false && !(true) && Ember.assert('should have found apiBaseUrl', true));
      return '';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "endpoints", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instances", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "apiBaseUrl", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "apiBaseUrl"), _class.prototype)), _class));
  _exports.default = PleromaApiService;
});