define("pleroma-pwa/adapters/search-result", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    pathForType() {
      return this._super('search_result');
    },

    urlForQueryRecord() {
      return this.pleromaApi.endpoints.search;
    }

  });

  _exports.default = _default;
});