define("pleroma-pwa/components/linkify", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="visuallyhidden"
    {{did-insert this.handleDidInsert}}
    {{will-destroy this.handleWillDestroy}}
  >
  </div>
  
  */
  {
    id: "+Va7GWsO",
    block: "{\"symbols\":[],\"statements\":[[9,\"div\",false],[23,\"class\",\"visuallyhidden\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[\"handleDidInsert\"]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"handleWillDestroy\"]]],null],[10],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/linkify.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (_class = (_temp = class _class extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    intercept(e) {
      let {
        target
      } = e;
      let parent = target.parentNode;
      let link;

      if (target.nodeName === 'A') {
        link = target;
      }

      if (!link && (parent === null || parent === void 0 ? void 0 : parent.nodeName) === 'A') {
        link = parent;
      }

      if (link && link.classList.contains('linkified')) {
        e.stopPropagation();
        e.preventDefault();
        let {
          location: {
            origin
          }
        } = window;
        let {
          href
        } = link;

        if (href.startsWith(origin)) {
          href = href.slice(origin.length);
        }

        this.router.transitionTo(href);
      }
    }

    handleDidInsert() {
      document.addEventListener('click', this.intercept, false);
    }

    handleWillDestroy() {
      document.removeEventListener('click', this.intercept);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "intercept", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "intercept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDidInsert", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleWillDestroy", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleWillDestroy"), _class.prototype)), _class));

  _exports.default = _default;
});