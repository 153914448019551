define("pleroma-pwa/feeds/notifications-favorites", ["exports", "pleroma-pwa/feeds/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NotificationsFavoriteFeed extends _notifications.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "id", 'notifications-favorites');

      _defineProperty(this, "shouldShow", ['favourite']);
    }

    get name() {
      return this.intl.t('favorites');
    }

    get params() {
      let params = {
        includeTypes: ['favourite']
      };
      return params;
    }

    collectUnreadCount() {}

  }

  _exports.default = NotificationsFavoriteFeed;
});