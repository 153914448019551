define("pleroma-pwa/services/search", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchService = (_class = (_temp = class SearchService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "query", _descriptor3, this);
    }

    clearQuery() {
      if (!this.isDestroying && !this.isDestroyed) {
        Ember.set(this, 'query', '');
      }
    }

    setQuery(value) {
      Ember.set(this, 'query', value);
    }

    onSubmit(e) {
      e.preventDefault();

      if (this.query === '') {
        return;
      }

      this.router.transitionTo('search.results', this.query);
    }

    searchParameters() {
      return {
        q: this.query,
        resolve: true,
        limit: 40
      };
    }

    results() {
      return this.store.queryRecord('search-result', this.searchParameters());
    }

    *getResults() {
      if (this.query) {
        return yield this.results();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "query", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearQuery", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "clearQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setQuery", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getResults", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getResults"), _class.prototype)), _class);
  _exports.default = SearchService;
});