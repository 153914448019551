define("pleroma-pwa/routes/notifications/reposts", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    feeds: Ember.inject.service(),
    feedId: 'notifications-reposts',

    model() {
      let factory = Ember.getOwner(this).factoryFor(`feed:${this.feedId}`);
      let tmpFeed = factory.create({});
      let feed = this.feeds.registerFeed(tmpFeed);

      try {
        this.feeds.subscribe(this.feedId);
      } catch (_) {
        feed = {};
      }

      return {
        feed
      };
    },

    afterModel(model) {
      model.feed.loadNew.perform();
    },

    deactivate() {
      this.feeds.unsubscribe(this.feedId);
    },

    titleToken() {
      return this.intl.t('reposts');
    }

  });

  _exports.default = _default;
});