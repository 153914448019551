define("pleroma-pwa/services/features", ["exports", "ember-feature-flags/services/features", "node-env-flag"], function (_exports, _features, _nodeEnvFlag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  /**
   * Extends the Features service from `ember-feature-flags`
   * and adds custom loading logic.
   *
   * This service is designed to only load and report on
   * enabled/disabled flags.
   *
   * Please use the `feature-decisions` service for adding
   * custom logic, such as dependencies between flags,
   * depending on current user state, etc.
   */

  var _default = _features.default.extend({
    config: Ember.inject.service(),
    cookies: Ember.inject.service(),
    deployTarget: Ember.computed('config.deployTarget', function () {
      return Ember.getWithDefault(this, 'config.deployTarget', '');
    }),

    /**
     * Runs when service is initialized.
     * Responsible for setting up initial state.
     * @method init
     * @public
     */
    init() {
      this._super(...arguments);

      this.setup(this.configuredFlags());
    },

    /**
     * Provides flags from configuration.
     * @method configuredFlags
     * @returns {Object}
     * @public
     */
    configuredFlags() {
      let configuredFlags = {};

      if (Ember.get(this, 'config.featureFlags')) {
        let configFlags = Ember.get(this, 'config.featureFlags');
        let normalizedConfigFlags = keys(configFlags).reduce((obj, key) => {
          let value = (0, _nodeEnvFlag.default)(configFlags[key], false); // eslint-disable-next-line no-underscore-dangle

          let convertedKey = this._normalizeFlag(key);

          obj[convertedKey] = value;
          return obj;
        }, {});
        Ember.assign(configuredFlags, normalizedConfigFlags);
      }

      let {
        deployTarget
      } = this; // Import flags from cookies only if in less-privileged environment.

      if (Ember.get(this, 'config.environment') === 'production' && deployTarget === 'production') {// no-op
      } else {
        let cookies = this.cookies.read();
        let cookieFlags = keys(cookies).reduce((obj, key) => {
          if (key.toLowerCase().startsWith('ff') && key.length > 3) {
            // eslint-disable-next-line no-underscore-dangle
            let convertedKey = this._normalizeFlag(key.slice(3));

            let value = (0, _nodeEnvFlag.default)(cookies[key], false);
            obj[convertedKey] = value;
          }

          return obj;
        }, {});
        Ember.assign(configuredFlags, cookieFlags);
      }

      return configuredFlags;
    }

  });

  _exports.default = _default;
});