define("pleroma-pwa/models/user-source", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://docs.joinmastodon.org/entities/source/
  var _default = _model.default.extend({
    note: (0, _model.attr)('string'),
    privacy: (0, _model.attr)('string', {
      defaultValue: () => 'public'
    }),
    pleroma: (0, _model.belongsTo)('user-source-extra', {
      async: false,
      inverse: null
    })
  });

  _exports.default = _default;
});