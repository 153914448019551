define("pleroma-pwa/helpers/emojify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEmojis = addEmojis;
  _exports.removeEmojis = removeEmojis;
  _exports.emojify = emojify;
  _exports.default = void 0;
  const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

  function addEmojis(string, emojis = []) {
    return Ember.String.htmlSafe(emojis.reduce((acc, emoji) => {
      let regexSafeShortCode = emoji.shortcode.replace(matchOperatorsRegex, '\\$&');
      return acc.replace(new RegExp(`:${regexSafeShortCode}:`, 'g'), `<img src='${emoji.url}' alt=':${emoji.shortcode}:' title=':${emoji.shortcode}:' class='emoji' />`);
    }, string));
  }

  function removeEmojis(string, emojis = []) {
    return emojis.reduce((acc, emoji) => {
      let regexSafeShortCode = emoji.shortcode.replace(matchOperatorsRegex, '\\$&');
      return acc.replace(new RegExp(`:${regexSafeShortCode}:`, 'g'), '');
    }, string).trim();
  }

  function emojify([string, emojis = []]) {
    return addEmojis(string, emojis || []);
  }

  var _default = Ember.Helper.helper(emojify);

  _exports.default = _default;
});