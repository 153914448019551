define("pleroma-pwa/helpers/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function focus() {
    return function (element) {
      return element === null || element === void 0 ? void 0 : element.focus();
    };
  });

  _exports.default = _default;
});