define("pleroma-pwa/models/token", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    appName: (0, _model.attr)('string'),
    validUntil: (0, _model.attr)('date'),
    // START for testing only --
    username: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    // END for testing only ----
    user: (0, _model.belongsTo)('user', {
      async: false
    })
  });

  _exports.default = _default;
});