define("pleroma-pwa/templates/account/followers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rZ1W7TJ3",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"feed/loader\",[],[[\"@feed\",\"@beginningOfFeedCallout\"],[[27,[24,0],[\"model\",\"feed\"]],false]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"feed\",\"content\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"profile-card\",[],[[\"@user\",\"@includeActions\"],[[27,[24,1],[]],true]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"profile__empty-tab\",null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[31,300,1,[27,[26,0,\"CallHead\"],[]],[\"noOneFollowingYet\"],[[\"username\"],[[27,[24,0],[\"model\",\"user\",\"username\"]]]]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/account/followers.hbs"
    }
  });

  _exports.default = _default;
});