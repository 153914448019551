define("pleroma-pwa/services/sentry", ["exports", "pleroma-pwa/config/environment", "pleroma-pwa/utils/humanize-file-size"], function (_exports, _environment, _humanizeFileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SentryService = (_class = (_temp = class SentryService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "feeds", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "instances", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "toast", _descriptor7, this);

      _initializerDefineProperty(this, "userPerformanceMonitoring", _descriptor8, this);

      _defineProperty(this, "enabled", !!_environment.default.APP.SENTRY_DSN && _environment.default.environment !== 'test');

      _defineProperty(this, "sentryDSN", _environment.default.APP.SENTRY_DSN);

      _defineProperty(this, "ignoreErrors", [// https://docs.sentry.io/platforms/javascript/#decluttering-sentry
      // Random plugins/extensions
      'top.GLOBALS', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification', 'canvas.contentDocument', 'MyApp_RemoveAllHighlights', 'http://tt.epicplay.com', 'Can\'t find variable: ZiteReader', 'jigsaw is not defined', 'ComboSearch is not defined', 'http://loading.retry.widdit.com/', 'atomicFindClose', // Facebook borked
      'fb_xd_fragment', // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload', 'EBCallBackMessageReceived', // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage']);

      _defineProperty(this, "blacklistUrls", [// https://docs.sentry.io/platforms/javascript/#decluttering-sentry
      // Facebook flakiness
      /graph\.facebook\.com/i, // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i, // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i, /static\.woopra\.com\/js\/woopra\.js/i, // Chrome extensions
      /extensions\//i, /^chrome:\/\//i, // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i, /metrics\.itunes\.apple\.com\.edgesuite\.net\//i]);
    }

    async setup() {
      if (this.enabled) {
        let Sentry = await emberAutoImportDynamic("@sentry/browser");
        window.Sentry = Sentry; // Expose yourself.

        let {
          Ember: EmberIntegration
        } = await emberAutoImportDynamic("@sentry/integrations");
        Sentry.init({
          dsn: this.sentryDSN,
          environment: _environment.default.deployTarget ? _environment.default.deployTarget : 'other',
          ignoreErrors: this.ignoreErrors,
          blacklistUrls: this.blacklistUrls,
          integrations: [new EmberIntegration()],
          beforeSend: this.beforeSend
        });
        this.userPerformanceMonitoring.on('timingEvent', this.handleTimingEvent).listen();
        this.session.on('currentUserLoaded', this.handleAuthentication);
        this.session.on('invalidationSucceeded', this.handleInvalidation);
        Sentry.setContext('instance', this.instances.current.toJSON());
        this.toast.notify({
          message: this.intl.t('trackingEnabled'),
          type: 'dismissibleMessage',
          link: this.router.urlFor('about'),
          learnMore: this.intl.t('learnMore')
        });
      }
    }

    willDestroy(...args) {
      super.willDestroy(...args);

      if (this.enabled) {
        this.userPerformanceMonitoring.off('timingEvent', this.handleTimingEvent);
        this.session.off('currentUserLoaded', this.handleAuthentication);
        this.session.off('invalidationSucceeded', this.handleInvalidation);
      }
    }

    throwException(error) {
      if (this.enabled) {
        Sentry.captureException(new Error(error));
      }
    }

    beforeSend(event
    /* hint */
    ) {
      event.extra = { ...event.extra,
        memory: this.generateMemoryMetrics(),
        timing: this.generateTimingMetrics(),
        store: this.generateStoreMetrics(),
        feeds: this.generateFeedMetrics()
      }; // Check if it is an exception, and if so, show the report dialog
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id });
      // }

      return event;
    }

    handleAuthentication() {
      Sentry.setUser({
        id: this.session.currentUser.id,
        username: this.session.currentUser.username
      });
    }

    handleInvalidation() {
      Sentry.setUser({
        id: null,
        username: null
      });
    }

    handleTimingEvent(eventName, eventDetails, additionalDetails) {
      Sentry.setContext(eventName, { ...eventDetails,
        ...additionalDetails
      });
    }

    generateMemoryMetrics() {
      let {
        memory
      } = window.performance;

      if (!memory) {
        return {};
      }

      return {
        jsHeapSizeLimit: (0, _humanizeFileSize.default)(memory.jsHeapSizeLimit),
        totalJSHeapSize: (0, _humanizeFileSize.default)(memory.totalJSHeapSize),
        usedJSHeapSize: (0, _humanizeFileSize.default)(memory.usedJSHeapSize)
      };
    }

    generateTimingMetrics() {
      let events = [];

      for (let event in window.performance.timing) {
        events.push([event, window.performance.timing[event]]);
      }

      return events.filter(event => event[1] > 0).sort((a, b) => a[1] - b[1]).map((event, i, eventList) => {
        let prevEvent = eventList[i - 1];

        if (prevEvent) {
          return [`${prevEvent[0]} -> ${event[0]}`, event[1] - prevEvent[1]];
        }
      }).filter(Boolean).map(event => `${event[0]} : ${event[1]}`);
    }

    generateStoreMetrics() {
      return {
        statuses: this.store.peekAll('status').length,
        accounts: this.store.peekAll('user').length
      };
    }

    generateFeedMetrics() {
      let metrics = {};
      this.feeds.feedList.forEach(feed => {
        metrics[feed.id] = {
          content: feed.content.length,
          newItems: feed.newItems.length
        };
        metrics.totalLength += feed.newItems.length + feed.content.length;
      });
      return metrics;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feeds", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "instances", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "toast", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "userPerformanceMonitoring", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "throwException", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "throwException"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "beforeSend", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeSend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleAuthentication", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleAuthentication"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInvalidation", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInvalidation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTimingEvent", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTimingEvent"), _class.prototype)), _class);
  _exports.default = SentryService;
});