define("pleroma-pwa/models/search-result", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    hashtags: (0, _model.hasMany)('hashtag', {
      async: false
    }),
    accounts: (0, _model.hasMany)('user', {
      async: false
    }),
    statuses: (0, _model.hasMany)('status', {
      async: false
    })
  });

  _exports.default = _default;
});