define("pleroma-pwa/helpers/mixed-grid-layout", ["exports", "ember-collection/layouts/mixed-grid"], function (_exports, _mixedGrid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    return new _mixedGrid.default(params[0]);
  });

  _exports.default = _default;
});