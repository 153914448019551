define("pleroma-pwa/models/status", ["exports", "uuid/v4", "pleroma-pwa/config/environment", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "ember-api-actions", "ember-concerns"], function (_exports, _v, _environment, _model, _loadableModel, _emberApiActions, _emberConcerns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      CONVERSATION_MAX_DEPTH
    }
  } = _environment.default;

  const afterMemberActionSerialize = function (response) {
    let serializer = this.store.serializerFor('status');
    let normalized = serializer.normalizeResponse(this.store, this.store.modelFor('status'), response, response.id, 'findRecord');
    return this.store.push(normalized);
  };

  var _default = _model.default.extend(_loadableModel.default, {
    spoilerText: (0, _model.attr)('string'),
    content: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    favourited: (0, _model.attr)('boolean', {
      defaultValue() {
        return false;
      }

    }),
    favouritesCount: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    inReplyToAccountId: (0, _model.attr)('string'),
    inReplyToId: (0, _model.attr)('string'),
    reblogged: (0, _model.attr)('boolean', {
      defaultValue() {
        return false;
      }

    }),
    reblogsCount: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    repliesCount: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    uri: (0, _model.attr)('string'),
    visibility: (0, _model.attr)('string', {
      defaultValue() {
        return 'public';
      }

    }),
    to: (0, _model.attr)('array'),
    inReplyToConversationId: (0, _model.attr)('string'),
    // Will reply to a given conversation, addressing only the people who are part of the recipient set of that conversation. Sets the visibility to direct.
    sensitive: (0, _model.attr)('boolean', {
      defaultValue() {
        return false;
      }

    }),
    // NOTE: This is needed for creating new status with media attachments.
    mediaIds: (0, _model.attr)('array'),
    account: (0, _model.belongsTo)('user', {
      async: false,
      inverse: null
    }),
    card: (0, _model.belongsTo)('card', {
      async: false,
      inverse: null
    }),
    reblog: (0, _model.belongsTo)('status', {
      async: false,
      inverse: null
    }),
    emojis: (0, _model.hasMany)('emoji', {
      async: false,
      inverse: null
    }),
    mediaAttachments: (0, _model.hasMany)('attachment', {
      async: false,
      inverse: null
    }),
    mentions: (0, _model.hasMany)('mention', {
      async: false,
      inverse: null
    }),
    ancestors: (0, _model.hasMany)('status', {
      async: false,
      inverse: null
    }),
    descendants: (0, _model.hasMany)('status', {
      async: false,
      inverse: null
    }),
    pleroma: (0, _model.belongsTo)('status-extra', {
      async: false,
      inverse: null
    }),
    poll: (0, _model.belongsTo)('poll', {
      async: false,
      inverse: null
    }),
    favoritesCount: Ember.computed.reads('favouritesCount'),
    favorited: Ember.computed.reads('favourited'),
    repostsCount: Ember.computed.reads('reblogsCount'),
    reposted: Ember.computed.reads('reblogged'),
    repostOf: Ember.computed.reads('reblog'),
    isReply: Ember.computed.and('inReplyToId', 'inReplyToAccountId'),
    isDirectMessage: Ember.computed.equal('visibility', 'direct'),
    isPrivate: Ember.computed.equal('visibility', 'private'),
    isPublic: Ember.computed.equal('visibility', 'public'),
    isUnlisted: Ember.computed.equal('visibility', 'unlisted'),
    visuallyMuted: Ember.computed.or('muted', 'pleroma.threadMuted', 'account.pleroma.relationship.muting'),
    emojiState: (0, _emberConcerns.inject)('status/emoji-state'),
    itemable: (0, _emberConcerns.inject)(),
    visibilityState: (0, _emberConcerns.inject)('status/visibility'),
    conversation: Ember.computed('descendants.[]', 'ancestors.[]', function () {
      // Turn singly linked list into tree.
      let everything = [...this.ancestors.toArray(), this, ...this.descendants.toArray()];
      let obj = {};
      let rootId = null;
      everything.forEach(status => obj[status.id] = {
        status,
        children: [],
        containsCurrent: false
      });
      everything.forEach(status => {
        if (status.inReplyToId) {
          obj[status.inReplyToId].children.push(obj[status.id]);
        } else {
          if (rootId) {
            // Create pseudo tombstone objects and attach to them the replies to non-existing statuses.
            let tombstoneId = `${status.id}_tombstone_${(0, _v.default)()}`;
            obj[tombstoneId] = {
              status,
              tombstone: true,
              children: [obj[status.id]],
              containsCurrent: false
            };
            obj[rootId].children.push(obj[tombstoneId]);
          } else {
            rootId = status.id;
          }
        }
      }); // grab the status-in-question and recurse up its inReplyTo's, marking them as containing the current statusDetails

      if (this.inReplyToId) {
        let isReplyOf = this.inReplyToId;

        while (isReplyOf) {
          obj[isReplyOf].containsCurrent = true;
          isReplyOf = obj[isReplyOf].status.inReplyToId;
        }
      }

      let maxLevels = CONVERSATION_MAX_DEPTH;
      let shouldTrim = everything.length > maxLevels; // find new rootId

      if (shouldTrim) {
        let numAdditionalLevels = maxLevels - 1;
        let currentHasChild = everything.find(status => status.inReplyToId === this.id);

        if (currentHasChild) {
          numAdditionalLevels = numAdditionalLevels - 1;
        }

        let currentStatusId = this.id;

        for (let i = 0; i < numAdditionalLevels; i++) {
          let s = obj[currentStatusId].status;

          if (s.inReplyToId) {
            currentStatusId = s.inReplyToId;
          } else {
            break;
          }
        }

        rootId = currentStatusId;
      }

      return obj[rootId]; // [
      //   { parent: null, id: A, },
      //   { parent: A, id: B, },
      //   { parent: A, id: C, },
      //   { parent: B, id: D, },
      //   { parent: B, id: E, },
      //   { parent: D, id: F, }
      // ]
      //
      // ==>
      //
      // {
      //   id: A,
      //   children: [
      //     {
      //       id: B,
      //       children: [
      //         {
      //           id: D,
      //           children: [
      //             {
      //               id: F,
      //               children: []
      //             }
      //           ],
      //         },
      //         {
      //           id: E,
      //           children: [],
      //         },
      //       ],
      //     },
      //     {
      //       id: C,
      //       children: [],
      //     }
      //   ]
      // }
    }),
    favorite: (0, _emberApiActions.memberAction)({
      path: 'favourite',
      type: 'post',
      after: afterMemberActionSerialize
    }),
    unfavorite: (0, _emberApiActions.memberAction)({
      path: 'unfavourite',
      type: 'post',
      after: afterMemberActionSerialize
    }),
    repost: (0, _emberApiActions.memberAction)({
      path: 'reblog',
      type: 'post',
      after: afterMemberActionSerialize
    }),
    unrepost: (0, _emberApiActions.memberAction)({
      path: 'unreblog',
      type: 'post',
      after: afterMemberActionSerialize
    }),
    mute: (0, _emberApiActions.memberAction)({
      path: 'mute',
      type: 'post',
      after: afterMemberActionSerialize
    }),
    unmute: (0, _emberApiActions.memberAction)({
      path: 'unmute',
      type: 'post',
      after: afterMemberActionSerialize
    }),
    loadConversation: (0, _emberApiActions.memberAction)({
      path: 'context',
      type: 'get',

      after(response) {
        this.ancestors.clear();
        this.descendants.clear();
        this.ancestors.pushObjects(response.ancestors.map(record => afterMemberActionSerialize.call(this, record)));
        this.descendants.pushObjects(response.descendants.map(record => afterMemberActionSerialize.call(this, record)));
      }

    }),
    store: Ember.inject.service()
  });

  _exports.default = _default;
});