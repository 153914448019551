define("pleroma-pwa/serializers/status-extra", ["exports", "ember-data/serializers/json", "@ember-data/serializer/rest"], function (_exports, _json, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      emojiReactions: {
        embedded: 'always'
      }
    },

    keyForAttribute(attr
    /* , method */
    ) {
      return Ember.String.underscore(attr);
    },

    normalize(model, hash, prop) {
      /* eslint-disable camelcase */
      // emoji-reaction
      if (hash.id && hash.emoji_reactions) {
        hash.emoji_reactions = hash.emoji_reactions.map(reaction => {
          return { ...reaction,
            status_id: hash.id
          };
        });
      }
      /* eslint-enable camelcase */


      return this._super(model, hash, prop);
    }

  });

  _exports.default = _default;
});