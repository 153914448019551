define("pleroma-pwa/services/poll-actions", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PollActionsService = (_class = (_temp = class PollActionsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "authModal", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "toast", _descriptor7, this);

      _defineProperty(this, "reloadCache", {});
    }

    *vote(poll, option) {
      (false && !(poll) && Ember.assert('You must pass a poll to the poll service vote action', poll));
      (false && !(option !== undefined) && Ember.assert('You must pass an option to the poll service vote action', option !== undefined));

      if (this.session.isAuthenticated) {
        let url = this.pleromaApi.endpoints.vote(poll.id);
        let response = yield fetch(url, {
          method: 'POST',
          headers: this.session.headers,
          body: JSON.stringify({
            choices: [option]
          })
        });
        let json = yield response.json();
        let status = this.error.detectErrantSuccessCode(response.status, json);

        if (status >= 400) {
          if (status === 422) {
            this.toast.notify({
              message: json.error,
              type: 'error'
            });
          } else {
            this.toast.notify({
              message: this.intl.t('errorVotingTryAgain'),
              type: 'error'
            });
          }
        }

        this.toast.notify({
          message: this.intl.t('voteSaved'),
          type: 'success'
        });
        this.store.normalizeAndStore('poll', [json]);
      } else {
        this.session.setReasonForAuthentication('youMustSignInToVote');
        this.authModal.showSignInModal();
      }
    }

    reloadPollIn(pollId, milliseconds) {
      if (!this.reloadCache[pollId]) {
        this.reloadCache[pollId] = Ember.run.later(this, () => {
          let poll = this.store.peekRecord('poll', pollId);

          if (poll) {
            poll.reload();
          }
        }, milliseconds);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authModal", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "toast", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "vote", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "vote"), _class.prototype)), _class);
  _exports.default = PollActionsService;
});