define("pleroma-pwa/routes/notifications/index", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    feeds: Ember.inject.service(),

    model() {
      let feed = this.modelFor('notifications');
      this.feeds.subscribe(feed.id);
      return {
        feed
      };
    },

    afterModel(model) {
      model.feed.loadNew.perform();
    },

    titleToken() {
      return this.intl.t('allNotifications');
    }

  });

  _exports.default = _default;
});