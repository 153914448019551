define("pleroma-pwa/helpers/format-relative-short", ["exports", "pleroma-pwa/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let second = 1000;
  let minute = 60 * second;
  let hour = 60 * minute;
  let day = 24 * hour;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    init(...args) {
      this._super(...args);

      this.intl.on('localeChanged', this, this.recompute);
    },

    round(number, down = true) {
      if (down) {
        return Math.floor(number);
      } else {
        return Math.ceil(number);
      }
    },

    compute([date]) {
      Ember.run.cancel(this.timer);

      if (!date) {
        return;
      }

      let diff = Math.abs(Date.now() - date);
      let inThePast = Date.now() - date > 0;
      let todayish = new Date();

      if (diff < minute) {
        this.setTimer(second);
        return `${this.round(diff / second, inThePast)}s`;
      } else if (diff < hour) {
        this.setTimer(minute);
        return `${this.round(diff / minute, inThePast)}m`;
      } else if (diff < day) {
        this.setTimer(hour);
        return `${Math.round(diff / hour)}h`;
      } else if (!inThePast) {
        this.setTimer(day);
        return `${Math.round(diff / day)}d`;
      } else if (todayish.getFullYear() === date.getFullYear()) {
        return this.intl.formatDate(date, {
          day: 'numeric',
          month: 'short'
        });
      } else {
        return this.intl.formatDate(date, {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      }
    },

    setTimer(interval) {
      if (_environment.default.environment === 'test') {
        return;
      } // escape hatch for tests


      this.timer = Ember.run.later(this, this.recompute, interval);
    },

    willDestroy() {
      this._super();

      this.intl.off('localeChanged', this, this.recompute);
      Ember.run.cancel(this.timer);
    }

  });

  _exports.default = _default;
});