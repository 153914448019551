define("pleroma-pwa/utils/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.durationFromSeconds = durationFromSeconds;

  function durationFromSeconds(seconds) {
    let minute = 60;
    let hour = 60 * minute;
    let day = 24 * hour; // Were dealing with strings here, since these are used in a <select

    return {
      days: Math.floor(seconds / day),
      hours: Math.floor(seconds % day / hour),
      minutes: Math.floor(seconds % hour / minute)
    };
  }
});