define("pleroma-pwa/services/toast", ["exports", "ember-concurrency", "pleroma-pwa/config/environment", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _environment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ToastService = (_dec = Ember.computed.filterBy('activeToasts', 'type', 'error'), _dec2 = Ember.computed.filterBy('activeToasts', 'type', 'dismissibleMessage'), _dec3 = Ember.computed('activeToasts.[]'), (_class = (_temp = class ToastService extends Ember.Service {
    get successToasts() {
      // Ony show the last one of the `success` toasts
      return [this.activeToasts.filter(toast => !this.nonSuccessToasts.includes(toast.type)).lastObject];
    }

    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _defineProperty(this, "toastTimeout", _environment.default.APP.TOAST_TIMEOUT);

      _defineProperty(this, "activeToasts", Ember.A());

      _defineProperty(this, "nonSuccessToasts", ['error', 'dismissibleMessage']);

      _initializerDefineProperty(this, "errorToasts", _descriptor2, this);

      _initializerDefineProperty(this, "dismissibleMessages", _descriptor3, this);

      this.bus.on('muted', user => this.notify({
        user,
        type: 'userMuted'
      }));
      this.bus.on('unmuted', user => this.notify({
        user,
        type: 'userUnmuted'
      }));
      this.bus.on('blocked', user => this.notify({
        user,
        type: 'userBlocked'
      }));
      this.bus.on('unblocked', user => this.notify({
        user,
        type: 'userUnblocked'
      }));
      this.bus.on('followed', user => this.notify({
        user,
        type: 'userFollowed'
      }));
      this.bus.on('unfollowed', user => this.notify({
        user,
        type: 'userUnfollowed'
      }));
    }

    touch() {}

    notify(payload) {
      let toast = {
        id: Ember.guidFor(payload),
        type: payload.type || 'success',
        payload
      };
      this.activeToasts.pushObject(toast);

      if (!this.nonSuccessToasts.includes(toast.type) && !(_environment.default.environment === 'test')) {
        this.dismissLater.perform(toast.id);
      }

      return toast;
    }

    dismiss(id) {
      Ember.set(this, 'activeToasts', this.activeToasts.rejectBy('id', id));
    }

    *dismissLater(toastId) {
      yield (0, _emberConcurrency.timeout)(this.toastTimeout);
      this.dismiss(toastId);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorToasts", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dismissibleMessages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "successToasts", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "successToasts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notify", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "notify"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismiss", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismissLater", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "dismissLater"), _class.prototype)), _class));
  _exports.default = ToastService;
});