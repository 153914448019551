define("pleroma-pwa/services/off-canvas-nav", ["exports", "ember-keyboard-shortcuts", "pleroma-pwa/utils/element-is-visible"], function (_exports, _emberKeyboardShortcuts, _elementIsVisible) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OffCanvasNavService = (_dec = Ember.computed.not('isOpen'), (_class = (_temp = class OffCanvasNavService extends Ember.Service.extend(Ember.Evented) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isOpen", false);

      _initializerDefineProperty(this, "isClosed", _descriptor, this);

      _defineProperty(this, "previouslyActiveElement", null);

      _defineProperty(this, "keyboardShortcuts", null);

      _defineProperty(this, "keyboardShortcuts", {
        esc: this.close,
        home: this.focusFirstSection,
        end: this.focusLastSection
      });
    }

    open() {
      Ember.set(this, 'isOpen', true);
      this.trigger('opened');

      if (!this.previouslyActiveElement) {
        Ember.set(this, 'previouslyActiveElement', document.activeElement);
      }

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
      Ember.run.next(() => this.focusFirstSection());
    }

    close() {
      if (!this.isDestroyed) {
        Ember.set(this, 'isOpen', false);
        this.trigger('closed');

        if (this.previouslyActiveElement) {
          this.previouslyActiveElement.focus();
          Ember.set(this, 'previouslyActiveElement', null);
        }
      }

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    }

    getAllFocusableSections() {
      // Could have been written more declarative... this is simple and probably most performant
      // If need to refactor, look at ember-ref-modifier
      let focusables = Ember.A([...document.querySelectorAll('#menu button, #menu a')]); // NodeList to Ember Array

      return focusables.filter(focusable => (0, _elementIsVisible.default)(focusable));
    }

    focusFirstSection() {
      this.getAllFocusableSections().firstObject && this.getAllFocusableSections().firstObject.focus();
      return false;
    }

    focusLastSection() {
      this.getAllFocusableSections().lastObject && this.getAllFocusableSections().lastObject.focus();
      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isClosed", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = OffCanvasNavService;
});