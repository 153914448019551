define("pleroma-pwa/services/instances", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InstancesService = (_dec = Ember.computed('currentId'), (_class = (_temp = class InstancesService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "preload", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor5, this);

      _defineProperty(this, "currentId", null);
    }

    *refreshTask() {
      // Find or fetch data, depending on whether preload is enabled/loaded or not.
      let instance, nodeInfo, clientCredential; // TODO: Right now, there is an open PR on pleroma-api to preload data into the index.html.
      // https://git.pleroma.social/pleroma/pleroma/-/merge_requests/2381
      // Once that is ready, let jump on board and move as much stuff as possible into the index.html.

      if (this.features.isEnabled('usePreload') && this.preload.loaded) {
        // Use as much as possible from preloaded data.
        instance = this.store.peekRecord('instance', this.preload.instanceId);
        Ember.set(this, 'currentId', instance.id);
        nodeInfo = this.store.peekRecord('node-info', this.preload.nodeInfoId); // TODO: Get these to be in preloaded data.

        [clientCredential] = yield Ember.RSVP.all([this.store.queryRecord('client-credential', {
          current: true
        })]);
      } else {
        instance = yield this.store.queryRecord('instance', {
          current: true
        });
        Ember.set(this, 'currentId', instance.id); // Load separately if not using preloading.

        [nodeInfo, clientCredential] = yield Ember.RSVP.all([this.store.queryRecord('node-info', {
          current: true
        }), this.store.queryRecord('client-credential', {
          current: true
        })]);
      }

      Ember.set(instance, 'nodeInfo', nodeInfo);
      Ember.set(instance, 'credentials', clientCredential);
      (false && !(instance.id === this.current.id) && Ember.assert('current instance should be available', instance.id === this.current.id));
      let clientToken = yield this.store.queryRecord('client-token', {
        current: true,
        clientId: clientCredential.clientId,
        clientSecret: clientCredential.clientSecret,
        redirectUri: clientCredential.redirectUri
      });
      Ember.set(instance, 'token', clientToken);
    }

    get current() {
      return this.currentId && this.store.peekRecord('instance', this.currentId);
    }

    ensureCurrent() {
      if (this.refreshTask.last) {
        return this.refreshTask.last;
      } else {
        return this.refreshTask.perform();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "preload", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "refreshTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "current", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "current"), _class.prototype)), _class));
  _exports.default = InstancesService;
});