define("pleroma-pwa/overlay-positions/over-the-top-right-of-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(trigger, content) {
    let scroll = {
      left: window.pageXOffset,
      top: window.pageYOffset
    };
    let viewportBottom = scroll.top + window.innerHeight;
    let {
      top: triggerTop,
      left: triggerLeft,
      width: triggerWidth,
      height: triggerHeight
    } = trigger.getBoundingClientRect();
    let {
      width: contentWidth,
      height: contentHeight
    } = content.getBoundingClientRect();
    let willOverlapViewportBottom = scroll.top + triggerTop + contentHeight > viewportBottom;
    let style = {
      left: triggerLeft + triggerWidth - contentWidth,
      top: willOverlapViewportBottom ? triggerTop + scroll.top + triggerHeight - contentHeight : triggerTop + scroll.top
    };
    return {
      style
    };
  }
});