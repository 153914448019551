define("pleroma-pwa/feeds/notifications", ["exports", "pleroma-pwa/feeds/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationsFeed = (_class = (_temp = class NotificationsFeed extends _base.default {
    get name() {
      return this.intl.t('notifications');
    }

    get params() {
      let params = {
        includeTypes: ['follow', 'mention', 'reblog', 'favourite', 'poll', ...(this.features.isEnabled('directMessages') ? ['pleroma:chat_mention'] : [])]
      };
      return params;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor3, this);

      _initializerDefineProperty(this, "unreadCounts", _descriptor4, this);

      _defineProperty(this, "id", 'notifications');

      _defineProperty(this, "shouldShow", ['follow', 'mention', 'reblog', 'favourite', 'poll']);

      _defineProperty(this, "shouldSilenceDirectNotifications", true);

      _defineProperty(this, "modelName", 'notification');

      _defineProperty(this, "authenticated", true);

      _defineProperty(this, "url", this.pleromaApi.endpoints.notifications);

      this.on('feed:loaded-more', () => {
        Ember.run(() => {
          return this.silenceAllConfigured().then(() => {
            return this.collectUnreadCount();
          });
        });
      });
      this.on('feed:loaded-new', () => {
        Ember.run(() => {
          return this.silenceAllConfigured().then(() => {
            return this.collectUnreadCount();
          });
        });
      });
    }

    markAllAsRead() {
      let allItems = [...this.content.content, ...this.newItems.content];
      allItems.forEach(item => {
        Ember.set(item.pleroma, 'isSeen', true);
        item.pleroma.save({
          adapterOptions: {
            dontPersist: true
          }
        });
      });
      this.unreadCounts.setUnreadNotificationsCount(0);
    }

    getMaxId() {
      let allItems = [...this.content.content, ...this.newItems.content];
      let maxId = 0;
      allItems.forEach(item => {
        maxId = Math.max(maxId, item.id);
      });
      return maxId;
    }

    collectUnreadCount() {
      let allItems = [...this.content.content, ...this.newItems.content];
      let unreadNotificationsCount = allItems.reduce((memo, notification) => {
        var _notification$pleroma;

        if (notification === null || notification === void 0 ? void 0 : (_notification$pleroma = notification.pleroma) === null || _notification$pleroma === void 0 ? void 0 : _notification$pleroma.isSeen) {
          return memo;
        }

        return memo + 1;
      }, 0);
      this.unreadCounts.setUnreadNotificationsCount(unreadNotificationsCount);
      return this.unreadCounts.unreadNotificationsCount;
    }

    silenceAllConfigured() {
      return Ember.RSVP.all([this.silenceDirectNotifications()]);
    }

    silenceDirectNotifications() {
      if (!this.shouldSilenceDirectNotifications) {
        return Ember.RSVP.resolve();
      }

      let allItems = [...this.content.content, ...this.newItems.content];
      let allMatched = allItems.filter(notification => {
        var _notification$status;

        return notification.type === 'mention' && ((_notification$status = notification.status) === null || _notification$status === void 0 ? void 0 : _notification$status.visibility) === 'direct' && !notification.pleroma.isSeen;
      });

      if (Ember.isEmpty(allMatched)) {
        return Ember.RSVP.resolve();
      }

      return Ember.RSVP.all(allMatched.map(notification => {
        return notification.markAsRead();
      }));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "unreadCounts", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = NotificationsFeed;
});