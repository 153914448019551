define("pleroma-pwa/models/mention", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model"], function (_exports, _model, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_loadableModel.default, {
    username: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    acct: (0, _model.attr)('string')
  });

  _exports.default = _default;
});