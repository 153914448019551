define("pleroma-pwa/modifiers/lock-body-scroll", ["exports", "body-scroll-lock"], function (_exports, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(() => ({
    createModifier() {
      return {
        element: null,
        options: {}
      };
    },

    installModifier(state, element, args) {
      let {
        enabled
      } = args.named;
      state.element = element;

      if (enabled) {
        (0, _bodyScrollLock.disableBodyScroll)(state.element, state.options);
      }
    },

    updateModifier(state, args) {
      let {
        enabled
      } = args.named;

      if (enabled) {
        (0, _bodyScrollLock.disableBodyScroll)(state.element, state.options);
      } else {
        (0, _bodyScrollLock.enableBodyScroll)(state.element, state.options);
      }
    },

    destroyModifier(state) {
      (0, _bodyScrollLock.enableBodyScroll)(state.element, state.options);
    }

  }), class FocusAfterRender {});

  _exports.default = _default;
});