define("pleroma-pwa/helpers/full-url-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    pleromaApi: Ember.inject.service(),
    router: Ember.inject.service(),

    compute([routeName, model]) {
      return new URL(this.router.urlFor(routeName, model), this.pleromaApi.apiBaseUrl);
    }

  });

  _exports.default = _default;
});