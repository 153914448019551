define("pleroma-pwa/modifiers/focus-after-render", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(() => ({
    createModifier() {
      return {
        schedule: null
      };
    },

    installModifier(state, element) {
      state.schedule = Ember.run.scheduleOnce('afterRender', element, this.doFocus);
    },

    doFocus() {
      this.focus();
    },

    updateModifier()
    /* state, args */
    {},

    destroyModifier(state) {
      Ember.run.cancel(state.schedule);
    }

  }), class FocusAfterRender {});

  _exports.default = _default;
});