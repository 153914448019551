define("pleroma-pwa/templates/settings/mutes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0d4Hf4AK",
    "block": "{\"symbols\":[\"mutes\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"main__tab\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"h3\",true],[12,\"class\",\"settings__small-heading\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,69,1,[27,[26,0,\"CallHead\"],[]],[\"mutes\"],null]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,103,1,[27,[26,0,\"CallHead\"],[]],[\"muteSettingsPrompt\"],null]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"model\",\"user\",\"mutes\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"mute-settings\",[],[[\"@mutes\",\"@refresh\",\"@mute\",\"@unmute\"],[[27,[24,1],[]],[27,[24,0],[\"model\",\"refresh\"]],[31,282,3,[27,[26,2,\"CallHead\"],[]],[[31,287,7,[27,[26,1,\"CallHead\"],[]],[\"userActions\"],null],\"muteUser\"],null],[31,339,3,[27,[26,2,\"CallHead\"],[]],[[31,344,7,[27,[26,1,\"CallHead\"],[]],[\"userActions\"],null],\"unmuteUser\"],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"img\",true],[12,\"src\",\"/assets/images/spinner.gif\",null],[13,\"alt\",[31,448,1,[27,[26,0,\"CallHead\"],[]],[\"loading\"],null],null],[12,\"width\",\"148\",null],[12,\"height\",\"148\",null],[12,\"role\",\"alert\",null],[12,\"aria-busy\",\"true\",null],[12,\"class\",\"settings__loading\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"service\",\"get\",\"async-await\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/settings/mutes.hbs"
    }
  });

  _exports.default = _default;
});