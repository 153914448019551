define("pleroma-pwa/components/ember-collection", ["exports", "ember-collection/components/ember-collection"], function (_exports, _emberCollection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberCollection.default;
    }
  });
});