define("pleroma-pwa/helpers/concern", ["exports", "ember-concerns/helpers/concern"], function (_exports, _concern) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _concern.default;
    }
  });
  Object.defineProperty(_exports, "concern", {
    enumerable: true,
    get: function () {
      return _concern.concern;
    }
  });
});