define("pleroma-pwa/routes/settings/blocks", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    intl: Ember.inject.service(),

    afterModel({
      user
    }) {
      return user.load('blocks');
    },

    titleToken() {
      return this.intl.t('blocks');
    }

  });

  _exports.default = _default;
});