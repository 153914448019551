define("pleroma-pwa/templates/search/results/statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5EjPVKTQ",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"searchResult\",\"statuses\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"feed-item\",[],[[\"@feedItem\"],[[27,[24,1],[]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"  \"],[7,\"search-no-results\",[],[[\"@type\"],[\"status\"]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/search/results/statuses.hbs"
    }
  });

  _exports.default = _default;
});