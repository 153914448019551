define("pleroma-pwa/serializers/search-result", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest", "uuid/v4"], function (_exports, _application, _rest, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      accounts: {
        deserialize: 'records'
      },
      statuses: {
        deserialize: 'records'
      },
      hashtags: {
        deserialize: 'records'
      }
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      // Make up a UUID on the fly.
      id = (0, _v.default)();
      let wrappedPayload = {
        'search_result': { ...payload,
          id
        }
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});