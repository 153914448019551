define("pleroma-pwa/services/error", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function hasUppercase(string) {
    for (let i = 0; i < string.length; i++) {
      let ch = string.charCodeAt(i);

      if (ch >= 65 && ch <= 90) {
        return true;
      }
    }

    return false;
  }

  let ErrorService = (_class = (_temp = class ErrorService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "genericFallbackErrorKey", 'somethingWentWrongTryAgain');

      _defineProperty(this, "genericSources", ['generic', 'error']);
    }

    /**
     * Returns an array of error objects with format { source, detail },
     * ready for use in displaying in forms.
     *
     * @public
     */
    formErrors(_input = {}, fallbackErrorKey = this.genericFallbackErrorKey) {
      let errors = [];
      let input = this.formatPayloadErrors(_input, fallbackErrorKey);

      if (input.errors) {
        errors = input.errors.map(error => {
          let source = error.source && !this.genericSources.includes(error.source) ? error.source : 'generic';
          let detail = source === 'generic' || error.detail && hasUppercase(error.detail[0]) ? error.detail : `${Ember.String.capitalize(source)} ${error.detail}`;
          return { ...error,
            source,
            detail
          };
        });
      } else if (input.message) {
        errors.push({
          source: 'generic',
          detail: input.message
        });
      } else if (Ember.typeOf(input) === 'string') {
        errors.push({
          source: 'generic',
          detail: input
        });
      } else {
        errors.push({
          source: 'generic',
          detail: this.intl.t(fallbackErrorKey)
        });
      }

      return errors;
    }
    /**
     * Takes payloads in many forms and attempts to return payload in the form of
     * { errors: [{ source, detail }] }
     *
     * @public
     */


    formatPayloadErrors(payload = {}, fallbackErrorKey = this.genericFallbackErrorKey) {
      var _payloadCopy;

      let payloadCopy;

      if (Ember.typeOf(payload) === 'string' && payload[0] === '{') {
        payloadCopy = JSON.parse(payload);
      }

      if (Ember.typeOf((_payloadCopy = payloadCopy) === null || _payloadCopy === void 0 ? void 0 : _payloadCopy.error) === 'string') {
        payloadCopy.errors = this.constructErrors(payloadCopy.error);
      }

      if (payload instanceof _error.default || payload.isAdapterError) {
        var _payload$errors$;

        let errors;

        if ((payload === null || payload === void 0 ? void 0 : (_payload$errors$ = payload.errors[0]) === null || _payload$errors$ === void 0 ? void 0 : _payload$errors$.detail[0]) === '{') {
          errors = this.constructErrors(payload.errors[0].detail, fallbackErrorKey);
        } else {
          errors = this.constructErrors(payload === null || payload === void 0 ? void 0 : payload.errors, fallbackErrorKey);
        }

        payloadCopy = {
          errors
        };
      } else if (payload instanceof Error) {
        let errors = this.constructErrors(payload === null || payload === void 0 ? void 0 : payload.message, fallbackErrorKey);
        payloadCopy = {
          errors
        };
      } else {
        payloadCopy = payload;
      }

      if (payloadCopy.error) {
        payloadCopy.errors = this.constructErrors(payloadCopy.error, fallbackErrorKey);
        delete payloadCopy.error;
      } // TODO: Lets get the API to return correct errors and attr names


      if (payloadCopy.errors && payloadCopy.errors.ap_id) {
        payloadCopy.errors.username = payloadCopy.errors.ap_id;
        delete payloadCopy.errors.ap_id;
      }

      if (Ember.typeOf(payloadCopy.errors) === 'object') {
        payloadCopy.errors = Object.keys(payloadCopy.errors).map(key => {
          return {
            source: key,
            detail: Array(payloadCopy.errors[key]).flat().join(',')
          };
        });
      }

      if (Ember.typeOf(payloadCopy.errors) === 'array') {
        let {
          errors
        } = payloadCopy;
        let anyHasMessage = payloadCopy.errors.any(e => Ember.isPresent(e.message));
        let anyIsString = payloadCopy.errors.any(e => Ember.typeOf(e) === 'string');

        if (anyHasMessage) {
          errors = payloadCopy.errors.map(error => {
            let source = 'generic';
            let detail = error.message;
            return { ...error,
              source,
              detail
            };
          });
        } else if (anyIsString) {
          errors = payloadCopy.errors.map(error => {
            let source = 'generic';
            let detail = error;
            return {
              source,
              detail
            };
          });
        }

        payloadCopy.errors = errors;
      }

      return payloadCopy;
    }
    /**
     * Detect if a response payload has been given an improper success status
     * code. If detected as improper, it is given a generic 400 Bad Request
     * status code.
     *
     * @public
     * @return number
     */


    detectErrantSuccessCode(code, payload) {
      if (payload && (payload.error || payload.errors) && code < 400) {
        // Pretend it's a generic bad request error.
        code = 400;
      }

      return parseInt(code);
    }

    constructErrors(input, fallbackErrorKey = this.genericFallbackErrorKey) {
      let output;

      if (Ember.typeOf(input) === 'string') {
        if (input[0] === '{') {
          try {
            output = JSON.parse(input);
          } catch (err) {
            output = [{
              source: 'generic',
              detail: this.intl.t(fallbackErrorKey)
            }];
          }
        } else {
          output = [{
            source: 'generic',
            detail: input
          }];
        }
      } else {
        output = input;
      } // Special case for that extra special captcha error, comes through like so: "{"captcha":["Invalid CAPTCHA"]}"


      if (output.captcha) {
        output = [{
          source: 'captcha',
          detail: output.captcha[0]
        }];
      }

      if (output.errors) {
        return output.errors;
      }

      return output;
    }

    pipe(value) {
      /* eslint-disable no-console */
      console.debug('error.pipe', 'value', value);
      /* eslint-enable no-console */

      if (value) {
        return Ember.RSVP.resolve(value);
      }

      return Ember.RSVP.reject(value);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pipe", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "pipe"), _class.prototype)), _class);
  _exports.default = ErrorService;
});