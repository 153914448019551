define("pleroma-pwa/helpers/max-in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function maxInArray([arrayOfNumbers]) {
    return Math.max(...arrayOfNumbers);
  });

  _exports.default = _default;
});