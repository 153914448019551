define("pleroma-pwa/routes/account", ["exports", "ember-lifeline"], function (_exports, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Route.extend((_obj = {
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    async model({
      id
    }) {
      let user = await this.store.loadRecord('user', id);
      let {
        id: userId
      } = user;
      await this.store.loadRecord('relationship', userId);
      return {
        refresh: () => this.refresh(),
        user
      };
    },

    activate() {
      // Adding logic to refresh this route because of the precarious async data
      // relationship between user and relationship. In short, there could be
      // times when they don't contain the right data. This ensures when
      // authentication happens and this set of routes is active, this data is
      // refreshed and made current.
      this.session.on('authenticationSucceeded', this.doRefresh);
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.session.off('authenticationSucceeded', this.doRefresh);
      });
    },

    deactivate() {
      (0, _emberLifeline.runDisposables)(this);
    },

    doRefresh() {
      this.refresh();
    },

    titleToken(model) {
      return model.user.username;
    }

  }, (_applyDecoratedDescriptor(_obj, "doRefresh", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "doRefresh"), _obj)), _obj));

  _exports.default = _default;
});