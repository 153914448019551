define("pleroma-pwa/components/auth-modal/forgot-password-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modal-form__body">
    <div class="sign-in-form__form" data-test-selector="forgot-password-success">
      <h1 class="form__subhead modal-form__heading">{{t "forgotPasswordSuccessHeader"}}</h1>
      <p>{{t "forgotPasswordSuccess"}}</p>
  
      <button
        type="button"
        {{on "click" (get (service "authModal") "close")}}
        data-test-selector="close-modal-link"
        class="button button--primary sign-in-form__submit button--full-width"
      >
        {{t "returnToTheHomePage"}}
      </button>
    </div>
  </div>
  
  */
  {
    id: "wmloKyuS",
    block: "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"modal-form__body\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"sign-in-form__form\",null],[12,\"data-test-selector\",\"forgot-password-success\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"h1\",true],[12,\"class\",\"form__subhead modal-form__heading\",null],[10],[1,0,0,0,[31,163,1,[27,[26,0,\"CallHead\"],[]],[\"forgotPasswordSuccessHeader\"],null]],[11],[1,1,0,0,\"\\n    \"],[9,\"p\",true],[10],[1,0,0,0,[31,211,1,[27,[26,0,\"CallHead\"],[]],[\"forgotPasswordSuccess\"],null]],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"button\",false],[23,\"data-test-selector\",\"close-modal-link\",null],[23,\"class\",\"button button--primary sign-in-form__submit button--full-width\",null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[\"click\",[31,296,3,[27,[26,2,\"CallHead\"],[]],[[31,301,7,[27,[26,1,\"CallHead\"],[]],[\"authModal\"],null],\"close\"],null]],null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[31,468,1,[27,[26,0,\"CallHead\"],[]],[\"returnToTheHomePage\"],null]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"service\",\"get\",\"on\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/auth-modal/forgot-password-success.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});