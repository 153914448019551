define("pleroma-pwa/components/feature-flags-menu", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{did-insert this.onInsert}}
    {{will-destroy this.onDestroy}}
  >
    {{#if this.showing}}
      <div class="feature-flags-menu">
        <ul>
          {{#each this.features.flags as |flag|}}
            <li>
              <button type="button" {{on "click" (fn this.toggleFlag flag)}}>
                {{#if (get this.features flag)}}
                  {{svg-jar "check"}}
                {{else}}
                  {{svg-jar "circle"}}
                {{/if}}
                {{camel-to-sentence-case flag}}
              </button>
            </li>
          {{/each}}
        </ul>
      </div>
    {{/if}}
  </div>
  
  */
  {
    id: "2eF+BwQ+",
    block: "{\"symbols\":[\"flag\"],\"statements\":[[9,\"div\",false],[3,0,0,[27,[26,8,\"ModifierHead\"],[]],[[27,[24,0],[\"onInsert\"]]],null],[3,0,0,[27,[26,9,\"ModifierHead\"],[]],[[27,[24,0],[\"onDestroy\"]]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[24,0],[\"showing\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"feature-flags-menu\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"ul\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[\"features\",\"flags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"          \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n            \"],[9,\"button\",false],[23,\"type\",\"button\",null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[\"click\",[31,254,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"toggleFlag\"]],[27,[24,1],[]]],null]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,303,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"features\"]],[27,[24,1],[]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"                \"],[1,0,0,0,[31,347,7,[27,[26,0,\"CallHead\"],[]],[\"check\"],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"                \"],[1,0,0,0,[31,406,7,[27,[26,0,\"CallHead\"],[]],[\"circle\"],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"              \"],[1,0,0,0,[31,463,22,[27,[26,5,\"CallHead\"],[]],[[27,[24,1],[]]],null]],[1,1,0,0,\"\\n            \"],[11],[1,1,0,0,\"\\n          \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"fn\",\"on\",\"get\",\"if\",\"camel-to-sentence-case\",\"-track-array\",\"each\",\"did-insert\",\"will-destroy\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/feature-flags-menu.hbs"
    }
  });

  let FeatureFlagsMenuComponent = (_class = (_temp = class FeatureFlagsMenuComponent extends Ember.Component {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _defineProperty(this, "showing", false);

      _defineProperty(this, "keyboardShortcuts", {
        'command+e': () => this.toggleShow(),
        'command+y': () => this.toggleShow()
      });
    }

    toggleShow() {
      Ember.set(this, 'showing', !this.showing);
    }

    toggleFlag(flag) {
      if (Ember.get(this.features, flag)) {
        this.features.disable(flag);
      } else {
        this.features.enable(flag);
      }
    }

    onInsert() {
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    }

    onDestroy() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFlag", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFlag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDestroy", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onDestroy"), _class.prototype)), _class);
  _exports.default = FeatureFlagsMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FeatureFlagsMenuComponent);
});