define("pleroma-pwa/services/auth-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthModalService = (_class = (_temp = class AuthModalService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "routerScroll", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "window", _descriptor5, this);

      _defineProperty(this, "authModal", null);

      _defineProperty(this, "shouldBlockClosingAuthModal", false);
    }

    showSignInModal() {
      this.show('sign-in');
    }

    showRegistrationModal() {
      this.show('register');
    }

    showForgotPasswordModal() {
      this.show('forgot-password');
    }

    showForgotPasswordSuccess() {
      this.show('forgot-password-success');
    }

    showOnboardingBioForm() {
      this.show('onboarding-bio-form');
    }

    showOnboardingAvatarForm() {
      this.show('onboarding-avatar-form');
    }

    show(tabName) {
      if (this.shouldBlockClosingAuthModal) {
        // If navigating between auth forms
        if (!this.window.confirm(this.intl.t('ifYouLeaveProgressWillBeLost'))) {
          return;
        } else {
          this.allowExit();
        }
      }

      this.setTriggerElementSelector();
      this.router.transitionTo({
        queryParams: {
          auth: tabName
        }
      });
      Ember.set(this.routerScroll, 'preserveScrollPosition', true);
    }

    setTriggerElementSelector() {
      Ember.run(() => {
        let {
          activeElement
        } = document;

        if (activeElement && !this.triggerElementSelector) {
          let now = Date.now();
          activeElement.setAttribute('data-triggered-sign-in', now);
          Ember.set(this, 'triggerElementSelector', `[data-triggered-sign-in="${now}"]`);
        }
      });
    }

    returnFocusToTrigger() {
      Ember.run(() => {
        if (this.triggerElementSelector) {
          let triggerElement = document.querySelector(this.triggerElementSelector);

          if (triggerElement && triggerElement.tabIndex > -1) {
            triggerElement.focus();
          }
        }

        this.clearTriggerSelector();
      });
    }

    clearTriggerSelector() {
      Ember.set(this, 'triggerElementSelector', null);
    }

    onAuthenticated() {
      // Typically we would want to close on authentication.
      // But if we are in the registration flow, we want the modal to stay open and to show the onboarding forms.
      if (this.router.currentRoute) {
        // This is false in integration tests that load the sessions user
        let {
          queryParams: {
            auth
          }
        } = this.router.currentRoute.find(route => route.name === 'application');

        if (auth === 'onboarding-avatar-form' || auth === 'onboarding-bio-form') {
          return;
        }

        this.close();
      }
    }

    blockExit() {
      Ember.set(this, 'shouldBlockClosingAuthModal', true);
    }

    allowExit() {
      Ember.set(this, 'shouldBlockClosingAuthModal', false);
    }

    close(dd, event) {
      if (this.shouldBlockClosingAuthModal) {
        if (this.window.confirm(this.intl.t('ifYouLeaveProgressWillBeLost'))) {
          this.allowExit();
          this.actuallyClose();
        }
      } else {
        this.actuallyClose();
      } // Always return false, the closing is done as part of the transition


      event && event.stopPropagation();
      event && event.preventDefault();
      return false;
    }

    actuallyClose() {
      if (this.router.currentRoute) {
        this.router.transitionTo({
          queryParams: {
            auth: null
          }
        });
      }

      this.returnFocusToTrigger();
      this.session.clearSessionRedirectRoute();
      this.session.clearReasonForAuthentication();
      Ember.sendEvent(this, 'auth-modal-hidden');
      Ember.set(this.routerScroll, 'preserveScrollPosition', false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routerScroll", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "window", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showSignInModal", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "showSignInModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showRegistrationModal", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "showRegistrationModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showForgotPasswordModal", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "showForgotPasswordModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showForgotPasswordSuccess", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "showForgotPasswordSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showOnboardingBioForm", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "showOnboardingBioForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showOnboardingAvatarForm", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "showOnboardingAvatarForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAuthenticated", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onAuthenticated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blockExit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "blockExit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allowExit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "allowExit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  _exports.default = AuthModalService;
});