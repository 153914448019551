define("pleroma-pwa/services/session", ["exports", "ember-simple-auth/services/session", "pleroma-pwa/config/environment"], function (_exports, _session, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PleromaSessionService = (_dec = Ember.computed('isAuthenticated', 'instances.current.token'), (_class = (_temp = class PleromaSessionService extends _session.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "authModal", _descriptor, this);

      _initializerDefineProperty(this, "instances", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "sentry", _descriptor4, this);

      _defineProperty(this, "currentUser", null);

      _defineProperty(this, "reasonForAuthenticationKey", null);
    }

    async authenticate(...args) {
      try {
        await super.authenticate(...args);
        await this.loadCurrentUser();
        await this.authModal.onAuthenticated();
        await this.clearReasonForAuthentication();
      } catch (error) {
        this.sentry.throwException('Session was invalidated failing to load the current user');
        this.invalidate();
        return Ember.RSVP.reject(error.responseJSON);
      }
    }

    async loadCurrentUser() {
      let currentUser = await this.store.queryRecord('user', {
        me: true
      });
      Ember.set(this, 'currentUser', currentUser);
      this.trigger('currentUserLoaded');
      return currentUser;
    }

    get headers() {
      var _this$instances$curre;

      if (this.isAuthenticated) {
        return {
          'authorization': `${this.data.authenticated.token_type} ${this.data.authenticated.access_token}`,
          'content-type': 'application/json'
        };
      } else if ((_this$instances$curre = this.instances.current) === null || _this$instances$curre === void 0 ? void 0 : _this$instances$curre.token) {
        // Some calls require the clients token, (like registration, and viewing users followers while unauthenticated)
        return {
          'authorization': `${this.instances.current.token.tokenType} ${this.instances.current.token.accessToken}`,
          'content-type': 'application/json'
        };
      } else {
        return {};
      }
    }

    async invalidate(...args) {
      await super.invalidate(...args);
      this.reset();
    }

    reset() {
      Ember.set(this, 'currentUser', null);
      Ember.set(this, 'reasonForAuthenticationKey', null);
    }

    authenticateBasic(username, password) {
      (false && !(username || password) && Ember.assert('You must pass both an username and password to the session services authenticateBasic method', username || password));
      return this.authenticate('authenticator:password-grant', username, password, _environment.default.APP.OAUTH_SCOPES);
    }

    authenticateImplicitGrant(options) {
      (false && !(options.token_type || options.access_token) && Ember.assert('You must pass both a tokenType and accessToken to the session services authenticateWithAccessToken method', options.token_type || options.access_token));
      return this.authenticate('authenticator:implicit-grant', options);
    }

    clearReasonForAuthentication() {
      Ember.set(this, 'reasonForAuthenticationKey', null);
    }

    setReasonForAuthentication(reasonKey) {
      Ember.set(this, 'reasonForAuthenticationKey', reasonKey);
    }

    clearSessionRedirectRoute() {
      Ember.set(this, 'attemptedTransition', null);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authModal", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instances", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sentry", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = PleromaSessionService;
});