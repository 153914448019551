define("pleroma-pwa/routes/message/details", ["exports", "ember-lifeline", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _emberLifeline, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MessageDetailsRoute = (_dec = Ember.inject.service(), (_class = (_temp = class MessageDetailsRoute extends Ember.Route.extend(_authenticatedRoute.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "feeds", _descriptor, this);
    }

    async model(params, transition) {
      let conversation = await this.store.loadRecord('conversation', params.id);
      let previousModel = this.modelFor(this.routeName);
      let factory = Ember.getOwner(this).factoryFor('feed:directMessage');
      let tmpFeed = factory.create({
        conversationId: conversation.id
      });
      let {
        id: feedId
      } = tmpFeed;

      if (transition.from && previousModel && previousModel.feed.id !== feedId) {
        // Unsubscribe from previousFeed when navigating between direct messages.
        this.feeds.unsubscribe(previousModel.feed.id);
      }

      let feed = this.feeds.registerFeed(tmpFeed);

      try {
        await this.feeds.subscribe(feedId);
        (0, _emberLifeline.registerDisposable)(this, () => {
          this.feeds.unsubscribe(feedId);
        });
      } catch (_) {
        feed = {};
      }

      return {
        feed,
        conversation,
        initialLinks: params.links
      };
    }

    deactivate() {
      (0, _emberLifeline.runDisposables)(this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feeds", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MessageDetailsRoute;
});