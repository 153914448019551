define("pleroma-pwa/models/relationship", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://docs.joinmastodon.org/entities/relationship/
  var _default = _model.default.extend({
    blockedBy: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    blocking: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    domainBlocking: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    endorsed: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    followedBy: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    following: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    muting: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    mutingNotifications: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    requested: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    showingReblogs: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    subscribing: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    pleroma: (0, _model.belongsTo)('user-extra', {
      async: false,
      inverse: 'relationship'
    })
  });

  _exports.default = _default;
});