define("pleroma-pwa/serializers/client-token", ["exports", "pleroma-pwa/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'client-token': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    extractId(modelClass, resourceHash) {
      return Ember.guidFor(resourceHash);
    }

  });

  _exports.default = _default;
});