define("pleroma-pwa/helpers/debounce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function debounceHelper([callback]) {
    (false && !(callback) && Ember.assert('Expected a callback to be passed to the debounce helper', callback));
    return function (...args) {
      Ember.run.debounce(null, callback, ...args, 300);
    };
  });

  _exports.default = _default;
});