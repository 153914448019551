define("pleroma-pwa/components/load-record", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-app-scheduler"], function (_exports, _component, _emberConcurrencyDecorators, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    this.query.isRunning
    this.query.last.value
  }}
  
  */
  {
    id: "q+4OIGNB",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[27,[24,0],[\"query\",\"isRunning\"]],[27,[24,0],[\"query\",\"last\",\"value\"]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "pleroma-pwa/components/load-record.hbs"
    }
  });

  let LoadRecord = (_class = (_temp = class LoadRecord extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      this.query.perform();
    }

    get cache() {
      if (this.args.cache) {
        return !!this.args.cache;
      }

      return false;
    }

    *query() {
      yield (0, _emberAppScheduler.whenRouteIdle)();

      if (!this.args.modelId) {
        return;
      }

      try {
        let value;

        if (this.cache) {
          value = this.store.peekRecord(this.args.modelName, this.args.modelId);
        }

        if (!value) {
          value = yield this.store.loadRecord(this.args.modelName, this.args.modelId);
        }

        return value;
      } catch (e) {
        Ember.tryInvoke(this.args, 'onNotFound');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "query", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "query"), _class.prototype)), _class);
  _exports.default = LoadRecord;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoadRecord);
});