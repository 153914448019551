define("pleroma-pwa/components/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <picture class="avatar" data-test-selector="avatar" ...attributes>
    {{#if @tombstone}}
      <div
        class="avatar__image tombstone"
        width="48px"
        height="48px"
        aria-hidden="true"
        data-test-selector="tombstone-avatar"
      />
    {{else if @muted}}
      {{svg-jar
        "muted-user-avatar"
        class="avatar__image"
        width="48px"
        height="48px"
        aria-hidden="true"
        data-test-selector="muted-avatar"
      }}
    {{else}}
      <img
        class="avatar__image"
        src={{@user.avatar}}
        alt={{t "avatarFor" name=(t "atHandle" handle=@user.username)}}
        width={{or @size 48}}
        height={{or @size 48}}
        data-test-selector="user-avatar"
        loading="lazy"
        {{with-load-error-states}}
      >
    {{/if}}
  </picture>
  
  */
  {
    id: "fyfMczXt",
    block: "{\"symbols\":[\"@user\",\"@size\",\"@muted\",\"&attrs\",\"@tombstone\"],\"statements\":[[9,\"picture\",false],[23,\"class\",\"avatar\",null],[23,\"data-test-selector\",\"avatar\",null],[15,4],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[24,5],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"avatar__image tombstone\",null],[12,\"width\",\"48px\",null],[12,\"height\",\"48px\",null],[12,\"aria-hidden\",\"true\",null],[12,\"data-test-selector\",\"tombstone-avatar\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[24,3],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,277,7,[27,[26,3,\"CallHead\"],[]],[\"muted-user-avatar\"],[[\"class\",\"width\",\"height\",\"aria-hidden\",\"data-test-selector\"],[\"avatar__image\",\"48px\",\"48px\",\"true\",\"muted-avatar\"]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"img\",false],[23,\"class\",\"avatar__image\",null],[14,\"src\",[27,[24,1],[\"avatar\"]],null],[14,\"alt\",[31,537,1,[27,[26,0,\"CallHead\"],[]],[\"avatarFor\"],[[\"name\"],[[31,557,1,[27,[26,0,\"CallHead\"],[]],[\"atHandle\"],[[\"handle\"],[[27,[24,1],[\"username\"]]]]]]]],null],[14,\"width\",[31,609,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,2],[]],48],null],null],[14,\"height\",[31,638,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,2],[]],48],null],null],[23,\"data-test-selector\",\"user-avatar\",null],[23,\"loading\",\"lazy\",null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],null,null],[10],[11],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"with-load-error-states\",\"svg-jar\",\"if\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/avatar.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});