define("pleroma-pwa/utils/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filesFromClipboard = filesFromClipboard;

  function filesFromClipboard(event) {
    let {
      clipboardData = {}
    } = event.originalEvent || event;
    let {
      items = []
    } = clipboardData;
    let files = [];

    for (let clipboardItem of items) {
      if (clipboardItem.kind === 'file') {
        let file = clipboardItem.getAsFile();

        if (file) {
          files.push(file);
        }
      }
    }

    return files;
  }
});