define("pleroma-pwa/utils/unique-array-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uniqueArrayBy;

  // Return items from list that do not exist in source by property
  function uniqueArrayBy(source, list, property) {
    let uniqueItems = []; // Enforce unique, For loop with cache set is the fastest way to do BEFORE modification.

    let knownItems = new Set(source.getEach(property));

    outer: for (let index = 0; index < list.length; index++) {
      let value = list[index].id;

      if (knownItems.has(value)) {
        continue outer;
      }

      knownItems.add(value);
      uniqueItems.push(list[index]);
    }

    return uniqueItems;
  }
});