define("pleroma-pwa/serializers/notification", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      account: {
        embedded: 'always'
      },
      chatMessage: {
        embedded: 'always'
      },
      pleroma: {
        embedded: 'always'
      },
      status: {
        embedded: 'always'
      }
    },

    // This overrides `normalize` from `EmbeddedRecordsMixin`.
    normalize(typeClass, hash, prop) {
      // notification-extra
      if (hash.id && hash.pleroma) {
        hash.pleroma = { ...hash.pleroma,
          id: hash.id
        };
      }

      return this._super(typeClass, hash, prop);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        notifications: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        notification: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});