define("pleroma-pwa/routes/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model({
      id
    }) {
      return id;
    }

    redirect(id) {
      this.replaceWith('account', id);
    }

  }

  _exports.default = _default;
});