define("pleroma-pwa/helpers/zero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function zeroHelper() {
    return 0;
  });

  _exports.default = _default;
});