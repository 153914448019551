define("pleroma-pwa/serializers/user-extra", ["exports", "ember-data/serializers/json", "@ember-data/serializer/rest", "lodash.isequal"], function (_exports, _json, _rest, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      notificationSettings: {
        embedded: 'always'
      }
    },

    keyForAttribute(attr
    /* , method */
    ) {
      return Ember.String.underscore(attr);
    },

    normalize(model, hash, prop) {
      /* eslint-disable camelcase */
      // notification-setting
      if (hash.id && hash.notification_settings) {
        hash.notification_settings = { ...hash.notification_settings,
          id: hash.id
        };
      }
      /* eslint-enable camelcase */


      let relationship = {
        type: 'relationship',
        id: hash.id
      };

      if (!hash.relationship || (0, _lodash.default)(hash.relationship, {})) {
        hash.relationship = relationship;
      } else {
        var _hash$relationship;

        if ((_hash$relationship = hash.relationship) === null || _hash$relationship === void 0 ? void 0 : _hash$relationship.id) {
          delete hash.relationship.id;
        }

        hash.relationship = { ...relationship,
          attributes: hash.relationship
        };
      }

      return this._super(model, hash, prop);
    }

  });

  _exports.default = _default;
});