define("pleroma-pwa/concerns/pleroma-api/endpoints", ["exports", "ember-concerns", "pleroma-pwa/config/environment"], function (_exports, _emberConcerns, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      mastodonApiNamespace,
      pleromaApiNamespace
    }
  } = _environment.default;

  class PleromaApiEndpointsConcern extends _emberConcerns.default {
    get accountBlocks() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/blocks`;
    }

    accountFavorites(userId) {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/accounts/${userId}/favourites`;
    }

    accountFollowers(userId) {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/${userId}/followers`;
    }

    accountFollowing(userId) {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/${userId}/following`;
    }

    accountMedia(userId) {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/${userId}/statuses?only_media=1`;
    }

    get accountMutes() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/mutes`;
    }

    get accountSearch() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/search`;
    }

    accountStatuses(userId) {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/${userId}/statuses`;
    }

    assertChat(userId) {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/chats/by-account-id/${userId}`;
    }

    get blocksImport() {
      return `${this.model.apiBaseUrl}/api/pleroma/blocks_import`;
    }

    get captcha() {
      return `${this.model.apiBaseUrl}/api/pleroma/captcha`;
    }

    get changePassword() {
      return `${this.model.apiBaseUrl}/api/pleroma/change_password`;
    }

    get chats() {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/chats`;
    }

    chatMarkAsRead(chatId) {
      return `${this.chats}/${chatId}/read`;
    }

    chatMessages(chatId) {
      return `${this.chats}/${chatId}/messages`;
    }

    chatMessage(chatId, messageId) {
      return `${this.chatMessages(chatId)}/${messageId}`;
    }

    chatMessageMarkAsRead(chatId, messageId) {
      return `${this.chatMessage(chatId, messageId)}/read`;
    }

    get clientCredentials() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/apps`;
    }

    conversation(conversationId) {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/conversations/${conversationId}`;
    }

    get conversations() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/conversations`;
    }

    get currentUser() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/verify_credentials`;
    }

    get currentUsersFavorites() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/favourites`;
    }

    get customEmojis() {
      return `${this.model.apiBaseUrl}/api/pleroma/emoji`;
    }

    get deleteAccount() {
      return `${this.model.apiBaseUrl}/api/pleroma/delete_account`;
    }

    directMessageFeed(conversationId) {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/conversations/${conversationId}/statuses`;
    }

    get followImport() {
      return `${this.model.apiBaseUrl}/api/pleroma/follow_import`;
    }

    hashtagFeed(hashtag) {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/timelines/tag/${hashtag}`;
    }

    get instance() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/instance`;
    }

    get media() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/media`;
    }

    get nodeInfo() {
      return `${this.model.apiBaseUrl}/nodeinfo/2.0.json`;
    }

    get notifications() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/notifications`;
    }

    get notificationSettings() {
      return `${this.model.apiBaseUrl}/api/pleroma/notification_settings`;
    }

    get notificationsMarkAsRead() {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/notifications/read`;
    }

    oauthToken(tokenId) {
      return `${this.model.apiBaseUrl}/api/oauth_tokens/${tokenId}`;
    }

    get oauthTokens() {
      return `${this.model.apiBaseUrl}/api/oauth_tokens`;
    }

    get pushSubscription() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/push/subscription`;
    }

    get registration() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts`;
    }

    get relationships() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/relationships`;
    }

    get requestForgotPasswordEmail() {
      return `${this.model.apiBaseUrl}/auth/password`;
    }

    get revokeToken() {
      return `${this.model.apiBaseUrl}/oauth/revoke`;
    }

    get search() {
      return `${this.model.apiBaseUrl}/api/v2/search`;
    }

    get setAvatar() {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/accounts/update_avatar`;
    }

    get setBackground() {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/accounts/update_background`;
    }

    statusEmojiReact(statusId, emoji) {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/statuses/${statusId}/reactions/${emoji}`;
    }

    statusEmojiReactions(statusId) {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/statuses/${statusId}/reactions`;
    }

    get timelineAll() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/timelines/public`;
    }

    get timelineHome() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/timelines/home`;
    }

    get timelineDirect() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/timelines/direct`;
    }

    get token() {
      return `${this.model.apiBaseUrl}/oauth/token`;
    }

    get updateUser() {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/accounts/update_credentials`;
    }

    get updateUserAvatar() {
      return `${this.model.apiBaseUrl}/${pleromaApiNamespace}/accounts/update_avatar`;
    }

    vote(pollId) {
      return `${this.model.apiBaseUrl}/${mastodonApiNamespace}/polls/${pollId}/votes`;
    }

  }

  _exports.default = PleromaApiEndpointsConcern;
});