define("pleroma-pwa/models/attachment-extra", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    mimeType: (0, _model.attr)('string'),
    playable: Ember.computed('mime-type', function () {
      let isImage = this.mimeType.includes('image');
      let isVideo = this.mimeType.includes('video');
      return isImage || isVideo;
    })
  });

  _exports.default = _default;
});