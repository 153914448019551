define("pleroma-pwa/templates/notifications/reposts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jqcGftIM",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[7,\"feed/loader\",[],[[\"@feed\",\"@beginningOfFeedCallout\"],[[27,[24,0],[\"model\",\"feed\"]],[31,70,1,[27,[26,1,\"CallHead\"],[]],[\"beginningOfNotificationHistory\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"feed\",\"content\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[\"shouldShow\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[7,\"feed-item\",[],[[\"@feedItem\"],[[27,[24,1],[]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,5,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/notifications/reposts.hbs"
    }
  });

  _exports.default = _default;
});