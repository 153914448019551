define("pleroma-pwa/adapters/relationship", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    coalesceFindRequests: true,
    pleromaApi: Ember.inject.service(),
    session: Ember.inject.service(),

    handleResponse(code, headers, payload, requestData) {
      if (!this.session.isAuthenticated) {
        code = 200;
        payload = [];
      }

      return this._super(code, headers, payload, requestData);
    },

    findRecord(store, type, id) {
      return this.queryForIds([id]);
    },

    findMany(store, type, ids) {
      return this.queryForIds(ids);
    },

    query(store, type, query) {
      let ids = Object.values(query).flat().filter(Boolean);
      return this.queryForIds(ids);
    },

    /**
     * @private
     */
    queryForIds(ids) {
      if (!this.session.isAuthenticated) {
        return Ember.RSVP.resolve(this.defaultResponse(ids));
      }

      return this.ajax(this.pleromaApi.endpoints.relationships, 'GET', {
        data: {
          id: ids
        }
      }).catch(() => this.defaultResponse(ids));
    },

    /**
     * @private
     */
    defaultResponse(ids = []) {
      return ids.map(id => ({
        id
      }));
    }

  });

  _exports.default = _default;
});