define("pleroma-pwa/serializers/chat-message", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      attachment: {
        embedded: 'always'
      },
      emojis: {
        deserialize: 'records'
      }
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'chat-message': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'chat-message': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalize(typeClass, hash, prop) {
      if (!hash.account && hash.account_id) {
        hash.account = {
          type: 'user',
          id: hash.account_id
        };
        delete hash.account_id;
      }

      if (!hash.chat && hash.chat_id) {
        hash.chat = {
          type: 'chat',
          id: hash.chat_id
        };
        delete hash.chat_id;
      }

      return this._super(typeClass, hash, prop);
    }

  });

  _exports.default = _default;
});