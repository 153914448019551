define("pleroma-pwa/serializers/media", ["exports", "pleroma-pwa/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import uuid from 'uuid/v4';
  var _default = _application.default.extend({
    modelNameFromPayloadKey() {
      return 'media';
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        media: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});