define("pleroma-pwa/templates/message/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V6XERn9Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"direct-message\",[],[[\"@chat\",\"@participants\",\"@initialLinks\"],[[27,[24,0],[\"model\",\"chat\"]],[31,61,5,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"chat\",\"account\"]]],null],[27,[24,0],[\"model\",\"initialLinks\"]]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/message/chat.hbs"
    }
  });

  _exports.default = _default;
});