define("pleroma-pwa/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let layoutWidthMain = 600;
  let layoutWidthSidebar = 335;
  let gutter = 25;
  var _default = {
    small: '(max-width: 599px)',
    medium: `(min-width: 600px) and (max-width: ${layoutWidthMain + layoutWidthSidebar + gutter * 3 - 1}px)`,
    large: `(min-width: ${layoutWidthMain + layoutWidthSidebar + gutter * 3}px)`,
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)'
  };
  _exports.default = _default;
});