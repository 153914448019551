define("pleroma-pwa/templates/status/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dWxyyo8K",
    "block": "{\"symbols\":[],\"statements\":[[9,\"main\",true],[12,\"class\",\"main\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[31,29,12,[27,[26,1,\"CallHead\"],[]],[\"statusDetails\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"conversation\",null],[10],[1,1,0,0,\"\\n      \"],[7,\"thread\",[],[[\"@conversation\",\"@centerStatusId\"],[[27,[24,0],[\"model\",\"status\",\"conversation\"]],[27,[24,0],[\"model\",\"status\",\"id\"]]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,249,1,[27,[26,0,\"CallHead\"],[]],[\"featureNotEnabled\"],[[\"feature\"],[\"Status Details\"]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"feature-flag\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/status/details.hbs"
    }
  });

  _exports.default = _default;
});