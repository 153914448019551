define("pleroma-pwa/services/chat-actions", ["exports", "ember-concurrency", "ember-concurrency-decorators", "snakecase-keys"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators, _snakecaseKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChatActionsService = (_class = (_temp = class ChatActionsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "toast", _descriptor6, this);

      _initializerDefineProperty(this, "unreadCounts", _descriptor7, this);
    }

    // Input is typically a user ID but could be a group ID later on
    *assertChat(accountId) {
      if (!this.session.isAuthenticated) {
        this.session.setReasonForAuthentication('pleaseSignIn');
        this.authModal.showSignInModal();
        return;
      }

      try {
        if (!accountId) {
          throw new Error(this.intl.t('pleaseProvideValidUser'));
        }

        let chat; // try to see if user exists first.

        try {
          yield this.store.loadRecord('user', accountId);
          yield this.store.loadRecord('relationship', accountId);
        } catch (err) {
          /* eslint-disable no-console */
          console.debug('err', err);
          /* eslint-enable no-console */

          throw new Error(this.intl.t('pleaseProvideValidUser'));
        }

        let url = this.pleromaApi.endpoints.assertChat(accountId);
        let {
          headers
        } = this.session;
        let response = yield fetch(url, {
          method: 'POST',
          headers
        });
        let json = yield response.json();
        let status = this.error.detectErrantSuccessCode(response.status, json);

        if (status >= 400) {
          throw this.error.formatPayloadErrors(json);
        }

        [chat] = this.store.normalizeAndStore('chat', [json]);
        return yield chat;
      } catch (err) {
        let formatted = this.error.formatPayloadErrors(err);

        for (let {
          detail: message
        } of formatted.errors) {
          this.toast.notify({
            message,
            type: 'error'
          });
        }
      }
    }

    *sendMessage(chatId, data) {
      if (!this.session.isAuthenticated) {
        this.session.setReasonForAuthentication('pleaseSignIn');
        this.authModal.showSignInModal();
        return;
      }

      try {
        let url = this.pleromaApi.endpoints.chatMessages(chatId);
        let {
          headers
        } = this.session;
        let {
          content = '',
          mediaIds = []
        } = data;

        if (!content) {
          content = '';
        } // TODO: Remove restriction for one media attachment when chat messages support multiple.


        let [mediaId = null] = mediaIds;
        let sendData = {
          content,
          mediaId
        };

        if (!mediaId) {
          delete sendData.mediaId;
        }

        let response = yield fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify((0, _snakecaseKeys.default)(sendData))
        });
        let json = yield response.json();
        let status = this.error.detectErrantSuccessCode(response.status, json);

        if (status >= 400) {
          throw this.error.formatPayloadErrors(json);
        }

        let [message] = this.store.normalizeAndStore('chat-message', [json]);
        return yield message;
      } catch (err) {
        let formatted = this.error.formatPayloadErrors(err);

        for (let {
          detail: message
        } of formatted.errors) {
          this.toast.notify({
            message,
            type: 'error'
          });
        }
      }
    }

    *deleteMessage(chatId, messageId) {
      if (!this.session.isAuthenticated) {
        this.session.setReasonForAuthentication('pleaseSignIn');
        this.authModal.showSignInModal();
        return;
      }

      try {
        let url = this.pleromaApi.endpoints.chatMessage(chatId, messageId);
        let {
          headers
        } = this.session;
        let response = yield fetch(url, {
          method: 'DELETE',
          headers
        });
        let json = yield response.json();
        let status = this.error.detectErrantSuccessCode(response.status, json);

        if (status >= 400) {
          throw this.error.formatPayloadErrors(json);
        }

        let [message] = this.store.normalizeAndStore('chat-message', [json]);
        this.store.unloadRecord(message);
        return yield message;
      } catch (err) {
        let formatted = this.error.formatPayloadErrors(err);

        for (let {
          detail: message
        } of formatted.errors) {
          this.toast.notify({
            message,
            type: 'error'
          });
        }
      }
    } // @task*
    // markAsRead(messageId) {
    //   if (!this.session.isAuthenticated) {
    //     this.session.setReasonForAuthentication('pleaseSignIn');
    //     this.authModal.showSignInModal();
    //     return;
    //   }
    //
    //   yield timeout(6000);
    //
    //   try {
    //     // be optimistic
    //     this.unreadCounts.decrementUnreadChatCount();
    //
    //     let messageOrig = yield this.store.peekRecord('chat-message', messageId);
    //
    //     if (!messageOrig) {
    //       throw new Error(this.intl.t('messageNotFound'));
    //     }
    //
    //     // console.debug('messageOrig', messageOrig);
    //     // console.debug('unread?', messageOrig.unread);
    //
    //     // let messageData = yield messageOrig.read();
    //     // console.debug('messageData', messageData);
    //
    //     // don't mark as read if it's already read :shrug:
    //     // don't mark as read if it's my msg :shrug:
    //
    //     // console.debug('messageOrig.account.id', messageOrig.account.id);
    //     // console.debug('this.session.currentUser.id', this.session.currentUser.id);
    //
    //     if (!messageOrig.unread || messageOrig.account.id === this.session.currentUser.id) {
    //       return messageOrig;
    //     }
    //
    //     let url = this.pleromaApi.endpoints.chatMessageMarkAsRead(messageOrig.chat.id, messageId);
    //     // console.debug('url', url);
    //
    //     // // return yield url;
    //     // // throw new Error(url);
    //
    //     let { headers } = this.session;
    //
    //     let response = yield fetch(url, {
    //       method: 'POST',
    //       headers,
    //     });
    //
    //     let json = yield response.json();
    //
    //     let status = this.error.detectErrantSuccessCode(response.status, json);
    //
    //     if (status >= 400) {
    //       throw this.error.formatPayloadErrors(json);
    //     }
    //
    //     // console.debug('json', json);
    //
    //     let [message] = this.store.normalizeAndStore('chat-message', [json]);
    //
    //     // console.debug('message', message);
    //     // console.debug('unread? should be false', message.unread);
    //
    //     // this.store.unloadRecord(message);
    //
    //     return yield message;
    //   } catch (err) {
    //     let formatted = this.error.formatPayloadErrors(err);
    //
    //     for (let { detail: message } of formatted.errors) {
    //       this.toast.notify({ message, type: 'error' });
    //     }
    //   }
    // }


    *getChatMessage(chatId, messageId) {
      let url = this.pleromaApi.endpoints.chatMessage(chatId, messageId);
      let {
        headers
      } = this.session;
      let response = yield fetch(url, {
        method: 'GET',
        headers
      });
      let json = yield response.json();
      let status = this.error.detectErrantSuccessCode(response.status, json);

      if (status >= 400) {
        throw this.error.formatPayloadErrors(json);
      }

      let [message] = this.store.normalizeAndStore('chat-message', [json]);
      return message;
    }

    *markChatAsRead(chatId, lastReadId, options = {}) {
      let delayed = !!options.delayed;

      if (!this.session.isAuthenticated) {
        this.session.setReasonForAuthentication('pleaseSignIn');
        this.authModal.showSignInModal();
        return;
      }

      try {
        let chatOrig = yield this.store.peekRecord('chat', chatId);

        if (!chatOrig) {
          throw new Error(this.intl.t('directMessageNotFound'));
        }

        if (chatOrig.unread === 0) {
          return;
        }

        let message = yield this.store.peekRecord('chat-message', lastReadId);

        if (!message.unread) {
          return;
        } // pause here so task can be cancelled if it restarts.
        // this happens when multiple messages are viewed at once. the latest one wins.


        if (delayed) {
          yield (0, _emberConcurrency.timeout)(1500);
        } // be optimistic in decrementing unread chat count


        for (let i = 0; i < chatOrig.unread; i++) {
          this.unreadCounts.decrementUnreadChatCount();
        }

        let url = this.pleromaApi.endpoints.chatMarkAsRead(chatId);
        let sendData = {
          lastReadId
        };
        let {
          headers
        } = this.session;
        let response = yield fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify((0, _snakecaseKeys.default)(sendData))
        });
        let json = yield response.json();
        let status = this.error.detectErrantSuccessCode(response.status, json);

        if (status >= 400) {
          throw this.error.formatPayloadErrors(json);
        }

        let [chat] = this.store.normalizeAndStore('chat', [json]);
        return yield chat;
      } catch (err) {
        let formatted = this.error.formatPayloadErrors(err);

        for (let {
          detail: message
        } of formatted.errors) {
          this.toast.notify({
            message,
            type: 'error'
          });
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "toast", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "unreadCounts", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "assertChat", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "assertChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendMessage", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteMessage", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getChatMessage", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getChatMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markChatAsRead", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "markChatAsRead"), _class.prototype)), _class);
  _exports.default = ChatActionsService;
});