define("pleroma-pwa/serializers/static-emoji", ["exports", "pleroma-pwa/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // {
    //   "womans_clothes": "\ud83d\udc5a",
    //   "cookie": "\ud83c\udf6a",
    //   "woman_with_headscarf": "\ud83e\uddd5"
    // }
    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let normalized = Object.entries(payload).map(([shortcode, value]) => {
        return {
          shortcode,
          value,
          visibleInPicker: true
        };
      });
      let wrappedPayload = {
        'static_emoji': normalized
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    extractId(_, {
      shortcode
    }) {
      return shortcode;
    }

  });

  _exports.default = _default;
});