define("pleroma-pwa/services/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WindowService extends Ember.Service {
    confirm(message) {
      return window.confirm(message);
    }

  }

  _exports.default = WindowService;
});