define("pleroma-pwa/concerns/attachment/uploader", ["exports", "ember-concerns", "ember-concurrency-decorators", "pleroma-pwa/utils/humanize-file-size"], function (_exports, _emberConcerns, _emberConcurrencyDecorators, _humanizeFileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AttachmentUploaderConcern = (_class = (_temp = class AttachmentUploaderConcern extends _emberConcerns.default {
    // Services
    // Variables
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "instances", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "attempts", _descriptor4, this);

      _defineProperty(this, "maxAttempts", 3);

      _initializerDefineProperty(this, "shouldRetry", _descriptor5, this);

      _initializerDefineProperty(this, "uploadError", _descriptor6, this);

      this.reset();
    }

    reset() {
      this.attempts = 0;
      this.uploadError = null;
    }

    get id() {
      return Ember.guidFor(this.model);
    }

    get uploaded() {
      return Ember.isPresent(this.model.id);
    }

    get canRetry() {
      return this.shouldRetry && this.attempts < this.maxAttempts;
    }

    *upload() {
      if (this.uploaded || !this.canRetry) {
        return this.model;
      }

      let {
        file
      } = this.model;

      try {
        if (!(file instanceof File || file instanceof Blob) || !file.size || !file.type) {
          this.shouldRetry = false;
          throw new Error(this.intl.t('pleaseUploadValidFile'));
        }

        if (file.size > this.instances.current.uploadLimit) {
          this.shouldRetry = false;
          throw new Error(this.intl.t('fileTooLarge', {
            max: (0, _humanizeFileSize.default)(this.instances.current.uploadLimit)
          }));
        }

        Ember.debug(`attempts: ${this.attempts}`);
        this.uploadError = null; // TODO: remove this conditional. this simulates an error.

        if (this.features.isEnabled('simulateFirstUploadFail') && this.attempts === 0) {
          throw new Error(this.intl.t('uploadFailed'));
        }

        yield this.model.save();
        this.reset();
        return this.model;
      } catch (err) {
        this.uploadError = err;
        Ember.debug('trying again');
        this.attempts = this.attempts + 1;
        return yield this.model;
      }
    }
    /**
     * Receive a number that indicates which retry is occurring.
     * Return number of milliseconds to wait before that retry occurs.
     * Some randomization is added.
     *
     * @private
     */


    exponentialWaitTime(whichRetry = 1) {
      return Math.pow(2, parseInt(whichRetry)) * 1000 + Math.floor(Math.random() * 1000);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instances", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "attempts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "shouldRetry", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uploadError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "upload", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype)), _class);
  _exports.default = AttachmentUploaderConcern;
});