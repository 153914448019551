define("pleroma-pwa/serializers/instance", ["exports", "pleroma-pwa/serializers/application", "camelcase-keys", "uuid/v4"], function (_exports, _application, _camelcaseKeys, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForAttribute(attr) {
      return attr; // dont call super, we dont want to decamelize these
    },

    normalize(model, hash) {
      return this._super(model, (0, _camelcaseKeys.default)(hash, {
        deep: true
      }));
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        instance: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        instance: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    extractId() {
      let value = this._super(...arguments);

      if (!value) {
        return (0, _v.default)();
      }

      return value;
    }

  });

  _exports.default = _default;
});