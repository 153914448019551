define("pleroma-pwa/components/install-app-callout", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.deferredPrompt}}
    <button
      type="button"
      {{on "click" (perform this.installPwa)}}
      disabled={{this.installPwa.isRunning}}
    >
      <h2>
        {{#if this.installPwa.isRunning}}
          {{t "installing"}}
        {{else}}
          {{t "installPwaPrompt"}}
        {{/if}}
      </h2>
    </button>
  {{/if}}
  
  {{#if this.installPwa.lastSuccessful.value}}
    <h2>{{this.installPwa.lastSuccessful.value}}</h2>
  {{/if}}
  
  {{#if this.viewingInstalledVersion}}
    <h2>{{t "viewingInstalledPwa"}}</h2>
  {{/if}}
  
  {{yield}}
  
  */
  {
    id: "sK2NHl0P",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"deferredPrompt\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"button\",false],[14,\"disabled\",[27,[24,0],[\"installPwa\",\"isRunning\"]],null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[\"click\",[31,74,7,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"installPwa\"]]],null]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"h2\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"installPwa\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,207,1,[27,[26,0,\"CallHead\"],[]],[\"installing\"],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,249,1,[27,[26,0,\"CallHead\"],[]],[\"installPwaPrompt\"],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"installPwa\",\"lastSuccessful\",\"value\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"h2\",true],[10],[1,0,0,0,[27,[24,0],[\"installPwa\",\"lastSuccessful\",\"value\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"viewingInstalledVersion\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"h2\",true],[10],[1,0,0,0,[31,468,1,[27,[26,0,\"CallHead\"],[]],[\"viewingInstalledPwa\"],null]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\",\"on\",\"if\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/install-app-callout.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    intl: Ember.inject.service(),
    deferredPrompt: null,
    viewingInstalledVersion: false,

    init(...args) {
      this._super(...args);

      this.onBeforeInstallPrompt = this.onBeforeInstallPrompt.bind(this);
    },

    didInsertElement(...args) {
      this._super(...args);

      window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);

      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
        Ember.set(this, 'viewingInstalledVersion', true);
      }
    },

    willDestroyElement(...args) {
      this._super(...args);

      window.removeEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
    },

    onBeforeInstallPrompt(event) {
      Ember.set(this, 'deferredPrompt', event);
    },

    installPwa: (0, _emberConcurrency.task)(function* () {
      if (!this.deferredPrompt) {
        return;
      }

      this.deferredPrompt.prompt();
      let choiceResult = yield this.deferredPrompt.userChoice;
      Ember.set(this, 'deferredPrompt', null);
      return choiceResult.outcome === 'accepted' ? this.intl.t('pwaInstallSuccess') : this.intl.t('pwaInstallFail');
    }).drop()
  }));

  _exports.default = _default;
});