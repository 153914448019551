define("pleroma-pwa/models/notification", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "ember-concerns"], function (_exports, _model, _loadableModel, _emberConcerns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_loadableModel.default, {
    type: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    status: (0, _model.belongsTo)('status', {
      async: false,
      inverse: null
    }),
    account: (0, _model.belongsTo)('user', {
      async: false,
      inverse: null
    }),
    chatMessage: (0, _model.belongsTo)('chat-message', {
      async: false,
      inverse: null
    }),
    pleroma: (0, _model.belongsTo)('notification-extra', {
      async: false,
      inverse: null
    }),
    isRepost: Ember.computed.equal('type', 'reblog'),
    isFavorite: Ember.computed.equal('type', 'favourite'),
    isFollow: Ember.computed.equal('type', 'follow'),
    isMention: Ember.computed.equal('type', 'mention'),
    isChatMention: Ember.computed.equal('type', 'pleroma:chat_mention'),
    itemable: (0, _emberConcerns.inject)(),
    shouldShow: Ember.computed('type', function () {
      var _this$status;

      let feed = this.feeds.getFeed('notifications');
      return feed.shouldShow.includes(this.type) && !(this.type === 'mention' && ((_this$status = this.status) === null || _this$status === void 0 ? void 0 : _this$status.visibility) === 'direct');
    }),

    markAsRead(response) {
      let store = Ember.getOwner(this).lookup('service:store');
      let feedsService = Ember.getOwner(this).lookup('service:feeds');
      let unreadCounts = Ember.getOwner(this).lookup('service:unread-counts');
      let normalized = store.serializerFor('notification').normalizeSingleResponse(store, 'notification', response, `${(response === null || response === void 0 ? void 0 : response.id) || '(unknown)'}`, 'findRecord');
      feedsService.refreshFeedMeta('notifications');
      unreadCounts.decrementNotificationsCount();
      return store.push(normalized);
    },

    deleteNotification() {
      let feedsService = Ember.getOwner(this).lookup('service:feeds');
      let unreadCounts = Ember.getOwner(this).lookup('service:unread-counts');
      feedsService.removeItemFromAllFeeds(this);
      feedsService.refreshFeedMeta('notifications');
      unreadCounts.decrementNotificationsCount();
    },

    feeds: Ember.inject.service()
  });

  _exports.default = _default;
});