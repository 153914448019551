define("pleroma-pwa/adapters/conversation", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    urlForFindRecord(id) {
      return this.pleromaApi.endpoints.conversation(id);
    }

  });

  _exports.default = _default;
});