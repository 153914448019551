define("pleroma-pwa/models/user-extra", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://docs-develop.pleroma.social/backend/API/differences_in_mastoapi_responses/#accounts
  var _default = _model.default.extend({
    backgroundImage: (0, _model.attr)('string'),
    hideFavorites: (0, _model.attr)('boolean'),
    hideFollows: (0, _model.attr)('boolean'),
    hideFollowsCount: (0, _model.attr)('boolean'),
    hideFollowers: (0, _model.attr)('boolean'),
    hideFollowersCount: (0, _model.attr)('boolean'),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    notificationSettings: (0, _model.belongsTo)('notification-setting', {
      async: false,
      inverse: null,

      defaultValue() {
        return {};
      }

    }),

    /* eslint-disable ember-data-sync-relationships/no-async-relationships */
    relationship: (0, _model.belongsTo)('relationship', {
      async: true,
      inverse: 'pleroma',

      defaultValue() {
        return {};
      }

    })
    /* eslint-enable ember-data-sync-relationships/no-async-relationships */

  });

  _exports.default = _default;
});