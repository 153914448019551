define("pleroma-pwa/components/load-html", ["exports", "fetch", "ember-concurrency-decorators", "ember-app-scheduler"], function (_exports, _fetch, _emberConcurrencyDecorators, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.fetchHtmlFile.isRunning}}
    {{t "loadingInstancePanel"}}
  {{else}}
    {{html-safe (linkify this.sanitizedHtml.string)}}
  {{/if}}
  
  */
  {
    id: "epYWMu82",
    block: "{\"symbols\":[],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"fetchHtmlFile\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,41,1,[27,[26,2,\"CallHead\"],[]],[\"loadingInstancePanel\"],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,81,9,[27,[26,1,\"CallHead\"],[]],[[31,92,7,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"sanitizedHtml\",\"string\"]]],null]],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"linkify\",\"html-safe\",\"t\",\"if\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/load-html.hbs"
    }
  });

  let LoadHtml = (_dec = Ember.computed('attrs.path'), _dec2 = Ember.computed('fetchHtmlFile.lastSuccessful.value'), (_class = (_temp = class LoadHtml extends Ember.Component {
    get url() {
      return `${this.pleromaApi.apiBaseUrl}/${this.path}`;
    }

    get sanitizedHtml() {
      let html = this.fetchHtmlFile.lastSuccessful.value;
      html = this.stripScriptTags(html);
      html = this.stripStyleTags(html);
      html = this.stripInlineStyles(html);
      html = this.stripClasses(html);
      html = this.stripImages(html);
      html = Ember.String.htmlSafe(html);
      return html;
    }

    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "pleromaApi", _descriptor, this);

      _initializerDefineProperty(this, "preload", _descriptor2, this);

      _defineProperty(this, 'data-test-selector', 'load-html');

      this.fetchHtmlFile.perform();
    }

    *fetchHtmlFile() {
      try {
        yield (0, _emberAppScheduler.whenRouteIdle)(); // try preload first

        let html = this.preload.data[`/${this.path}`];

        if (html) {
          return html;
        } // fall back to loading url


        let results = yield (0, _fetch.default)(this.url);

        if (results.ok) {
          let html = yield results.text();
          return html;
        } else {
          return '';
        }
      } catch (e) {
        return '';
      }
    }

    stripScriptTags(htmlString) {
      return htmlString.replace(/<script[\s\S]+?<\/script>/g, '');
    }

    stripStyleTags(htmlString) {
      return htmlString.replace(/<style[\s\S]+?<\/style>/g, '');
    }

    stripInlineStyles(htmlString) {
      return htmlString.replace(/style=['"].+?['"]/g, '');
    }

    stripClasses(htmlString) {
      return htmlString.replace(/class=['"].+?['"]/g, '');
    }

    stripImages(htmlString) {
      return htmlString.replace(/<img[\s\S]+?>/g, '');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "preload", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "url", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "url"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sanitizedHtml", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sanitizedHtml"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchHtmlFile", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchHtmlFile"), _class.prototype)), _class));
  _exports.default = LoadHtml;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoadHtml);
});