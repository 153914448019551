define("pleroma-pwa/feeds/notifications-mentions", ["exports", "pleroma-pwa/feeds/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NotificationsMentionsFeed extends _notifications.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "id", 'notifications-mentions');

      _defineProperty(this, "shouldShow", ['mention']);
    }

    get name() {
      return this.intl.t('mentions');
    }

    get params() {
      let params = {
        includeTypes: ['mention']
      };
      return params;
    }

    collectUnreadCount() {}

  }

  _exports.default = NotificationsMentionsFeed;
});