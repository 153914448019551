define("pleroma-pwa/routes/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel() {
      if (this.session.isAuthenticated) {
        this.session.invalidate();
      }
    },

    redirect() {
      this.transitionTo('/');
    }

  });

  _exports.default = _default;
});