define("pleroma-pwa/models/conversation", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "ember-api-actions"], function (_exports, _model, _loadableModel, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const afterMemberActionSerialize = function (response) {
    let serializer = this.store.serializerFor('conversation');
    let normalized = serializer.normalizeResponse(this.store, this.store.modelFor('conversation'), response, response.id, 'findRecord');
    return this.store.push(normalized);
  };

  var _default = _model.default.extend(_loadableModel.default, {
    unread: (0, _model.attr)('boolean'),
    accounts: (0, _model.hasMany)('user', {
      async: false,
      inverse: null
    }),
    lastStatus: (0, _model.belongsTo)('status', {
      async: false,
      inverse: null
    }),
    markAsRead: (0, _emberApiActions.memberAction)({
      path: 'read',
      type: 'post',
      after: afterMemberActionSerialize
    })
  });

  _exports.default = _default;
});