define("pleroma-pwa/routes/not-found", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      return Ember.RSVP.reject(new _error.NotFoundError());
    }

  });

  _exports.default = _default;
});