define("pleroma-pwa/services/preload", ["exports", "fetch", "lodash.isequal", "js-base64"], function (_exports, _fetch, _lodash, _jsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const parser = new DOMParser();
  let PreloadService = (_dec = Ember.inject.service('-document'), (_class = (_temp = class PreloadService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "document", _descriptor, this);

      _initializerDefineProperty(this, "feeds", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "data", _descriptor6, this);

      _initializerDefineProperty(this, "nodeInfoId", _descriptor7, this);

      _initializerDefineProperty(this, "instanceId", _descriptor8, this);
    }

    /**
     * Indicates if preloaded data has been loaded or not.
     *
     * @public
     */
    get loaded() {
      return !(0, _lodash.default)(this.data, {});
    }
    /**
     * @public
     */


    async load() {
      var _doc$getElementById;

      if (!this.features.isEnabled('usePreload')) {
        return;
      }

      let doc;

      if (this.document.getElementById('initial-results')) {
        doc = this.document;
      } else {
        let resp = await (0, _fetch.default)(`${this.pleromaApi.apiBaseUrl}/main/all`);
        let html = await resp.text();
        doc = parser.parseFromString(html, 'text/html');
      }

      let escapedJson = (_doc$getElementById = doc.getElementById('initial-results')) === null || _doc$getElementById === void 0 ? void 0 : _doc$getElementById.innerHTML;

      if (Ember.isEmpty(escapedJson)) {
        return;
      }

      try {
        let preloadedData = JSON.parse(escapedJson);
        let parsedPreloadedData = Object.entries(preloadedData).map(([key, value]) => {
          return [key, JSON.parse(_jsBase.Base64.decode(value))];
        });
        preloadedData = Object.fromEntries(parsedPreloadedData);
        this.data = preloadedData;
        this.instance(this.data['/api/v1/instance']);
        this.nodeInfo(this.data['/nodeinfo/2.0.json']); // this.publicFeed(Base64.decode(this.data['/api/v1/timelines/public']));
      } catch (err) {
        Ember.debug(err);
      }
    }
    /**
     * @private
     */


    instance(data = {}) {
      if (Ember.typeOf(data) !== 'object' || (0, _lodash.default)(data, {})) {
        return;
      }

      let [instance] = this.store.normalizeAndStore('instance', [data]);
      this.instanceId = instance.id;
    }
    /**
     * @private
     */


    nodeInfo(data = {}) {
      if (Ember.typeOf(data) !== 'object' || (0, _lodash.default)(data, {})) {
        return;
      }

      let [nodeInfo] = this.store.normalizeAndStore('node-info', [data]);
      this.nodeInfoId = nodeInfo.id;
    }
    /**
     * @private
     */


    publicFeed(data = []) {
      if (Ember.typeOf(data) !== 'array' || Ember.isEmpty(data)) {
        return;
      }

      let feed = this.feeds.getFeed('public');
      let records = this.store.normalizeAndStore(feed.modelName, data);

      if (records.length) {
        feed.addItems(records);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "document", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "feeds", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "data", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "nodeInfoId", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "instanceId", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = PreloadService;
});