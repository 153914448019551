define("pleroma-pwa/helpers/date-format", ["exports", "date-fns/format"], function (_exports, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;

  function dateFormat([date, format]) {
    return (0, _format.default)(date, format);
  }

  var _default = Ember.Helper.helper(dateFormat);

  _exports.default = _default;
});