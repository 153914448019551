define("pleroma-pwa/templates/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xe6Rcsrb",
    "block": "{\"symbols\":[],\"statements\":[[9,\"main\",false],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[[31,20,3,[27,[26,1,\"CallHead\"],[]],[[31,25,7,[27,[26,0,\"CallHead\"],[]],[\"auth-modal\"],null],\"showSignInModal\"],null]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"service\",\"get\",\"did-insert\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/sign-in.hbs"
    }
  });

  _exports.default = _default;
});