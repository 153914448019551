define("pleroma-pwa/templates/settings/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "33tgSerd",
    "block": "{\"symbols\":[\"currentUser\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"main__tab\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"model\",\"user\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"settings/profile/avatar\",[],[[\"@crop\",\"@username\",\"@avatar\",\"@save\"],[true,[27,[24,1],[\"username\"]],[27,[24,1],[\"avatar\"]],[31,223,3,[27,[26,1,\"CallHead\"],[]],[[31,228,7,[27,[26,0,\"CallHead\"],[]],[\"userActions\"],null],\"setAvatar\"],null]]],null],[1,1,0,0,\"\\n\\n    \"],[7,\"settings/profile/details\",[],[[\"@displayName\",\"@note\",\"@save\"],[[27,[24,1],[\"displayName\"]],[27,[24,1],[\"source\",\"note\"]],[31,457,3,[27,[26,1,\"CallHead\"],[]],[[31,462,7,[27,[26,0,\"CallHead\"],[]],[\"userActions\"],null],\"updateUser\"],null]]],null],[1,1,0,0,\"\\n\\n    \"],[7,\"settings/profile/options\",[],[[\"@hideFavorites\",\"@locked\",\"@hideFollows\",\"@hideFollowsCount\",\"@hideFollowers\",\"@hideFollowersCount\",\"@defaultScope\",\"@save\"],[[27,[24,1],[\"pleroma\",\"hideFavorites\"]],[27,[24,1],[\"locked\"]],[27,[24,1],[\"pleroma\",\"hideFollows\"]],[27,[24,1],[\"pleroma\",\"hideFollowsCount\"]],[27,[24,1],[\"pleroma\",\"hideFollowers\"]],[27,[24,1],[\"pleroma\",\"hideFollowersCount\"]],[27,[24,1],[\"source\",\"privacy\"]],[31,1001,3,[27,[26,1,\"CallHead\"],[]],[[31,1006,7,[27,[26,0,\"CallHead\"],[]],[\"userActions\"],null],\"updateUser\"],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"service\",\"get\",\"let\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/settings/profile.hbs"
    }
  });

  _exports.default = _default;
});