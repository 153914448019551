define("pleroma-pwa/routes/account/media", ["exports", "ember-lifeline"], function (_exports, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountMediaRoute = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), (_class = (_temp = class AccountMediaRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "feeds", _descriptor2, this);
    }

    async model() {
      let accountModel = this.modelFor('account');
      let {
        user: {
          id: userId
        }
      } = accountModel;
      let factory = Ember.getOwner(this).factoryFor('feed:user-media');
      let tmpFeed = factory.create({
        userId
      });
      let {
        id: feedId
      } = tmpFeed;
      let feed = this.feeds.registerFeed(tmpFeed);

      try {
        await this.feeds.subscribe(feedId);
        (0, _emberLifeline.registerDisposable)(this, () => {
          this.feeds.unsubscribe(feedId);
        });
      } catch (_) {
        feed = {};
      }

      return {
        feed,
        ...accountModel
      };
    }

    deactivate() {
      (0, _emberLifeline.runDisposables)(this);
    }

    titleToken() {
      return this.intl.t('media');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "feeds", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountMediaRoute;
});