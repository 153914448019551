define("pleroma-pwa/routes/search/results/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    redirect({
      searchResult
    }) {
      if (searchResult.statuses.length) {
        this.replaceWith('search.results.statuses');
      } else if (searchResult.accounts.length) {
        this.replaceWith('search.results.people');
      } else if (searchResult.hashtags.length) {
        this.replaceWith('search.results.hashtags');
      } else {
        this.replaceWith('search.results.statuses');
      }
    }

  }

  _exports.default = _default;
});