define("pleroma-pwa/helpers/prevent-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function preventDefault([eventHandler]) {
    return function (event) {
      event.preventDefault(); // TODO: Remove this stop-propagation, and use the helper instead

      event.stopPropagation();

      if (eventHandler) {
        return eventHandler(event);
      }

      return event;
    };
  });

  _exports.default = _default;
});