define("pleroma-pwa/helpers/linkify", ["exports", "pleroma-pwa/utils/mention-matcher"], function (_exports, _mentionMatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const parser = new DOMParser();

  var _default = Ember.Helper.extend({
    pleromaApi: Ember.inject.service(),

    compute([html, mentions = []]) {
      let {
        apiBaseUrl
      } = this.pleromaApi;
      let {
        location: {
          origin
        }
      } = window;
      let doc = parser.parseFromString(html, 'text/html');
      let links = doc.getElementsByTagName('a');

      if (links.length === 0) {
        return html;
      }

      for (let link of links) {
        if (link.href.startsWith(`${apiBaseUrl}/users/`)) {
          // handle mentions
          link.classList.add('linkified');
          link.href = link.href.replace(`${apiBaseUrl}/users/`, '/account/');
        } else if (link.href === `${origin}/main/all`) {
          // handle old link to all feed
          link.classList.add('linkified');
          link.href = '/feeds/all';
        } else {
          // handle remote mentions
          let mention = mentions.find(mention => (0, _mentionMatcher.matchesMention)(mention, link.href));

          if (mention) {
            link.classList.add('linkified');
            link.href = `/account/${mention.id}`;
          }
        }
      }

      let body = doc.getElementsByTagName('body').item(0);
      return body.innerHTML;
    }

  });

  _exports.default = _default;
});