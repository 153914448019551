define("pleroma-pwa/serializers/conversation", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      accounts: {
        deserialize: 'records'
      },
      lastStatus: {
        deserialize: 'records'
      }
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        conversations: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        conversation: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});