define("pleroma-pwa/services/intl", ["exports", "ember-intl/services/intl"], function (_exports, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PleromaIntlService = (_dec = Ember.computed('primaryLocale', 'rightToLeftLocales'), (_class = (_temp = class PleromaIntlService extends _intl.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "rightToLeftLocales", Ember.A());
    }

    get isRTL() {
      return this.rightToLeftLocales.includes(this.primaryLocale);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "isRTL", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isRTL"), _class.prototype)), _class));
  _exports.default = PleromaIntlService;
});