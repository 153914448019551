define("pleroma-pwa/routes/notifications", ["exports", "pleroma-pwa/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MessagesRoute = (_class = (_temp = class MessagesRoute extends Ember.Route.extend(_authenticatedRoute.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "feeds", _descriptor2, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor3, this);

      _defineProperty(this, "reasonForAuthenticationKey", 'youMustSignInToSeeYourNotifications');
    }

    async activate() {
      this.markAllNotificationsAsRead();
    }

    async model() {
      let feed = this.feeds.getFeed('notifications');

      if (feed.subscribedTo) {
        feed.loadNew.perform();
      } else {
        await this.feeds.subscribe(feed.id);
      }

      return feed;
    }

    async markAllNotificationsAsRead() {
      let maxId = this.feeds.notifications.getMaxId();

      try {
        let url = this.pleromaApi.endpoints.notificationsMarkAsRead;
        let {
          headers
        } = this.session;
        let response = await fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            'max_id': maxId
          })
        });
        let json = await response.json();
        let status = this.error.detectErrantSuccessCode(response.status, json);

        if (status === 200) {
          this.feeds.notifications.markAllAsRead();
        }
      } catch (e) {
        Ember.debug(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "feeds", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MessagesRoute;
});