define("pleroma-pwa/adapters/static-emoji", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'static',

    pathForType() {
      return 'emoji.json';
    }

  });

  _exports.default = _default;
});