define("pleroma-pwa/utils/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tryLinkedTask = tryLinkedTask;

  function tryLinkedTask(task, ...args) {
    if (task === null || task === void 0 ? void 0 : task.linked) {
      return task.linked().perform(...args);
    } else if (Ember.typeOf(task) === 'function') {
      return task(...args);
    }
  }
});