define("pleroma-pwa/models/notification-setting", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://docs-develop.pleroma.social/backend/API/differences_in_mastoapi_responses/#accounts
  var _default = _model.default.extend({
    blockFromStrangers: (0, _model.attr)('boolean'),
    hideNotificationContents: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});