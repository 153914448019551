define("pleroma-pwa/adapters/client-token", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    urlForQueryRecord() {
      return this.pleromaApi.endpoints.token;
    },

    ajaxOptions(url, type, options) {
      let hash = this._super(url, type, options);

      delete hash.headers['content-type'];
      let formData = new FormData();
      formData.append('client_id', hash.data.clientId);
      formData.append('client_secret', hash.data.clientSecret);
      formData.append('redirect_uri', hash.data.redirectUri);
      formData.append('grant_type', 'client_credentials');
      hash.body = formData;
      hash.type = 'POST';
      hash.method = 'POST';
      hash.url = url;
      return hash;
    }

  });

  _exports.default = _default;
});