define("pleroma-pwa/routes/error", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      Ember.setProperties(controller, {
        is404: model instanceof _error.NotFoundError,
        isAuthenticationError: [_error.ForbiddenError, _error.UnauthorizedError].any(errorType => model instanceof errorType)
      });
    },

    actions: {
      reload() {
        window.location.reload(true);
      }

    },

    resetConroller(controller, isExiting) {
      if (isExiting) {
        Ember.setProperties(controller, {
          is404: false,
          isAuthenticationError: false
        });
      }
    }

  });

  _exports.default = _default;
});