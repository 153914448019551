define("pleroma-pwa/serializers/chat", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    pleromaApi: Ember.inject.service(),
    attrs: {
      account: {
        embedded: 'always'
      },
      lastMessage: {
        embedded: 'always'
      }
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'chat': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'chat': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    // This overrides `normalize` from `EmbeddedRecordsMixin`.
    normalize(typeClass, hash, prop) {
      hash.links = {
        messages: this.pleromaApi.endpoints.chatMessages(hash.id)
      };
      return this._super(typeClass, hash, prop);
    }

  });

  _exports.default = _default;
});