define("pleroma-pwa/adapters/status", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    ajaxOptions(url, type, options) {
      if (['POST'].includes(type) && options.data) {
        // TODO: When adding in file upload, be sure to add the Idempotency-id to the request headers. This will ensure we can re-start / recover.
        // De-nest the posted obj. Thanks ember-data.
        options.data = options.data.status;
      }

      return this._super(url, type, options);
    }

  });

  _exports.default = _default;
});