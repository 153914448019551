define("pleroma-pwa/services/feature-decisions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeatureDecisionsService = (_dec = Ember.computed('features.emojiReactions', 'instances.current.nodeInfo.metadata.features.[]'), (_class = (_temp = class FeatureDecisionsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "instances", _descriptor2, this);
    }

    get emojiReactionsSupported() {
      let backendFeaturesSupported = Ember.get(this.instances, 'current.nodeInfo.metadata.features');
      return this.features.isEnabled('emojiReactions') && backendFeaturesSupported.includes('pleroma_emoji_reactions');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instances", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "emojiReactionsSupported", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "emojiReactionsSupported"), _class.prototype)), _class));
  _exports.default = FeatureDecisionsService;
});