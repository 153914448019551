define("pleroma-pwa/templates/message/compose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yZNRGrIT",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"model\",\"participants\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"direct-message\",[],[[\"@participants\",\"@initialLinks\"],[[27,[24,0],[\"model\",\"participants\"]],[27,[24,0],[\"model\",\"initialLinks\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/message/compose.hbs"
    }
  });

  _exports.default = _default;
});