define("pleroma-pwa/templates/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rtvzUSr6",
    "block": "{\"symbols\":[],\"statements\":[[9,\"main\",true],[12,\"class\",\"main main--message\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,46,12,[27,[26,3,\"CallHead\"],[]],[\"directMessages\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"body-class\",[],[[\"@class\"],[\"alternative-scroll\"]],null],[1,1,0,0,\"\\n\\n    \"],[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,157,1,[27,[26,0,\"CallHead\"],[]],[\"featureNotEnabled\"],[[\"feature\"],[\"Direct Messages\"]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\",\"feature-flag\",\"if\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/message.hbs"
    }
  });

  _exports.default = _default;
});