define("pleroma-pwa/validators/status-content", ["exports", "ember-validators"], function (_exports, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateStatusContent;

  function validateStatusContent(options = {}) {
    options.presence = true;
    return (key, value, _oldValue, changes) => {
      var _result$context;

      if (!Ember.isEmpty(changes.mediaIds)) {
        return true;
      }

      let result = (0, _emberValidators.validate)('presence', value, options, null, key);
      return (result === null || result === void 0 ? void 0 : (_result$context = result.context) === null || _result$context === void 0 ? void 0 : _result$context.message) || result;
    };
  }
});