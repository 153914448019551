define("pleroma-pwa/routes/status/details", ["exports", "ember-app-scheduler"], function (_exports, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    features: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    async model(params) {
      let status; // Experiment to see if avoiding cache fixes rendering errors.

      if (this.features.isEnabled('statusDetailsFindRecord')) {
        status = await this.store.findRecord('status', params.id);
      } else {
        status = await this.store.loadRecord('status', params.id);
      }

      await status.loadConversation();
      return {
        status
      };
    },

    afterModel() {
      this._super(...arguments);

      (0, _emberAppScheduler.whenRouteIdle)().then(() => {
        this.scrollSchedule = Ember.run.later(this, 'scrollToActiveItem', 200);
      });
    },

    deactivate(...args) {
      Ember.run.cancel(this.scrollSchedule);
      return this._super(...args);
    },

    // Only run when first entering route, never again during internal route transitions.
    scrollToActiveItem() {
      let activeItem = document.querySelector('[data-is-active-item]');

      if (!activeItem) {
        return;
      }

      activeItem.scrollIntoView({
        block: 'center'
      });
    },

    titleToken() {
      return this.intl.t('conversation');
    }

  });

  _exports.default = _default;
});