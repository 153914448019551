define("pleroma-pwa/templates/settings/security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gflPIl7m",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"main__tab\",null],[10],[1,1,0,0,\"\\n  \"],[7,\"security/change-password\",[],[[\"@save\"],[[31,64,3,[27,[26,1,\"CallHead\"],[]],[[31,69,7,[27,[26,0,\"CallHead\"],[]],[\"userActions\"],null],\"changePassword\"],null]]],null],[1,1,0,0,\"\\n  \"],[7,\"security/delete-account\",[],[[\"@remove\"],[[31,157,3,[27,[26,1,\"CallHead\"],[]],[[31,162,7,[27,[26,0,\"CallHead\"],[]],[\"userActions\"],null],\"deleteAccount\"],null]]],null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"service\",\"get\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/settings/security.hbs"
    }
  });

  _exports.default = _default;
});