define("pleroma-pwa/adapters/attachment", ["exports", "pleroma-pwa/adapters/application", "fetch"], function (_exports, _application, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    pathForType() {
      return this._super('media');
    },

    createRecord(store, type, snapshot) {
      let {
        file
      } = snapshot.attributes();
      let formData = new FormData();
      formData.append('file', file);
      let url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      let headers = { ...this.headers
      };
      delete headers['content-type'];
      let options = {
        method: 'POST',
        headers,
        body: formData
      };
      return (0, _fetch.default)(url, options).then(resp => resp.json());
    }

  });

  _exports.default = _default;
});