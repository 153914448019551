define("pleroma-pwa/components/sidebar/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (service "session") as |session|}}
    <LinkTo class="sidebar__box sidebar__box--padded sidebar__box--link" @route="account" @model={{session.currentUser.id}} data-test-selector="username-link" title={{t "viewProfile"}}>
      <section data-test-selector="sidebar-profile">
        <div class="profile-card profile-card--sidebar" data-test-selector="profile-card">
          <div class="profile-card__text profile-card__account-link">
            <span class="profile-card__name">{{session.currentUser.names.displayNameWithEmojis}}</span>
            <span class="profile-card__handle">{{t "atHandle" handle=(if (feature-flag "showFullScreenName") session.currentUser.names.fullScreenName session.currentUser.username)}}</span>
          </div>
          <div class="profile-card__avatar">
            <Avatar
              @user={{session.currentUser}}
            />
          </div>
        </div>
      </section>
    </LinkTo>
  {{/let}}
  
  */
  {
    id: "vW8CHmop",
    block: "{\"symbols\":[\"session\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,8,7,[27,[26,3,\"CallHead\"],[]],[\"session\"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"link-to\",[[23,\"class\",\"sidebar__box sidebar__box--padded sidebar__box--link\",null],[23,\"data-test-selector\",\"username-link\",null],[14,\"title\",[31,207,1,[27,[26,0,\"CallHead\"],[]],[\"viewProfile\"],null],null]],[[\"@route\",\"@model\"],[\"account\",[27,[24,1],[\"currentUser\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"section\",true],[12,\"data-test-selector\",\"sidebar-profile\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"div\",true],[12,\"class\",\"profile-card profile-card--sidebar\",null],[12,\"data-test-selector\",\"profile-card\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"profile-card__text profile-card__account-link\",null],[10],[1,1,0,0,\"\\n          \"],[9,\"span\",true],[12,\"class\",\"profile-card__name\",null],[10],[1,0,0,0,[27,[24,1],[\"currentUser\",\"names\",\"displayNameWithEmojis\"]]],[11],[1,1,0,0,\"\\n          \"],[9,\"span\",true],[12,\"class\",\"profile-card__handle\",null],[10],[1,0,0,0,[31,583,1,[27,[26,0,\"CallHead\"],[]],[\"atHandle\"],[[\"handle\"],[[31,604,2,[27,[26,2,\"CallHead\"],[]],[[31,608,12,[27,[26,1,\"CallHead\"],[]],[\"showFullScreenName\"],null],[27,[24,1],[\"currentUser\",\"names\",\"fullScreenName\"]],[27,[24,1],[\"currentUser\",\"username\"]]],null]]]]],[11],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"profile-card__avatar\",null],[10],[1,1,0,0,\"\\n          \"],[7,\"avatar\",[],[[\"@user\"],[[27,[24,1],[\"currentUser\"]]]],null],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"feature-flag\",\"if\",\"service\",\"let\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/sidebar/profile.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});