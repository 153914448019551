define("pleroma-pwa/components/body-class", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="visuallyhidden"
    {{did-insert this.handleDidInsert}}
    {{will-destroy this.handleWillDestroy}}
  ></div>
  
  */
  {
    id: "AHBMn+g2",
    block: "{\"symbols\":[],\"statements\":[[9,\"div\",false],[23,\"class\",\"visuallyhidden\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[\"handleDidInsert\"]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"handleWillDestroy\"]]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/body-class.hbs"
    }
  });

  let BodyClassComponent = (_class = class BodyClassComponent extends _component.default {
    handleDidInsert() {
      document.body.classList.add(this.args.class);
    }

    handleWillDestroy() {
      document.body.classList.remove(this.args.class);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleDidInsert", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleWillDestroy", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleWillDestroy"), _class.prototype)), _class);
  _exports.default = BodyClassComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BodyClassComponent);
});