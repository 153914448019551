define("pleroma-pwa/modifiers/with-load-error-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(() => ({
    createModifier() {
      return {
        element: null,
        onError: null,
        onLoad: null
      };
    },

    installModifier(state, element) {
      state.element = element;

      if (!state.element.complete) {
        state.onError = () => {
          state.element.classList.remove('loading');
          state.element.removeEventListener('error', state.onError);
          state.element.classList.add('error');
          state.element.alt = '';
        };

        state.onLoad = () => {
          state.element.classList.remove('loading');
          state.element.removeEventListener('load', status.onLoad);
          state.element.classList.add('loaded');
        };

        state.element.addEventListener('error', state.onError);
        state.element.addEventListener('load', state.onLoad);
        state.element.classList.add('loading');
      }
    },

    updateModifier()
    /* state, args */
    {},

    destroyModifier(state) {
      state.element.removeEventListener('error', state.onError);
      state.element.removeEventListener('load', status.onLoad);
    }

  }), class WithLoadErrorStates {});

  _exports.default = _default;
});