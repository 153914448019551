define("pleroma-pwa/authenticators/implicit-grant", ["exports", "ember-simple-auth/authenticators/oauth2-implicit-grant"], function (_exports, _oauth2ImplicitGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2ImplicitGrant.default.extend();

  _exports.default = _default;
});