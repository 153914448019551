define("pleroma-pwa/helpers/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute([serviceName]) {
      return Ember.getOwner(this).lookup(`service:${serviceName}`);
    }

  });

  _exports.default = _default;
});