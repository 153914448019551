define("pleroma-pwa/components/load-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LoadRecord
    @modelName="user"
    @modelId={{@id}}
    as |userIsLoading user|
  >
    <LoadRecord
      @modelName="relationship"
      @modelId={{@id}}
      as |relationshipIsLoading relationship|
    >
      {{yield
        (or userIsLoading relationshipIsLoading)
        user
        relationship
      }}
    </LoadRecord>
  </LoadRecord>
  
  */
  {
    id: "tQE45rWF",
    block: "{\"symbols\":[\"userIsLoading\",\"user\",\"relationshipIsLoading\",\"relationship\",\"@id\",\"&default\"],\"statements\":[[7,\"load-record\",[],[[\"@modelName\",\"@modelId\"],[\"user\",[27,[24,5],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,\"load-record\",[],[[\"@modelName\",\"@modelId\"],[\"relationship\",[27,[24,5],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[16,6,[[31,211,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[]],[27,[24,3],[]]],null],[27,[24,2],[]],[27,[24,4],[]]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[3,4]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1,2]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/load-user.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});