define("pleroma-pwa/components/monitor-render", ["exports", "ember-user-performance-monitoring/components/monitor-render"], function (_exports, _monitorRender) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _monitorRender.default;
    }
  });
});