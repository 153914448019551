define("pleroma-pwa/concerns/itemable", ["exports", "ember-concerns"], function (_exports, _emberConcerns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Being an item means it's an object that ends up linking to a status at some
   * point.
   *
   * The object could be a status itself.
   *
   * It could also be a notification about a status, like a repost/boost or
   * favourite status notification.
   *
   * It could also be a poll.
   *
   * @public
   */
  let ItemableConcern = (_class = (_temp = class ItemableConcern extends _emberConcerns.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    /**
     * The logic for this was spread in multiple places in a template, so it was
     * brought here to centralize it.
     *
     * @public
     */
    get isItem() {
      let item = this.model;
      return !!(item.content || item.poll || item.createdAt) && !item.isFollow;
    }

    get isCurrentItem() {
      var _currentRoute$params;

      let {
        currentRoute,
        currentRouteName
      } = this.router;
      return currentRouteName === 'status.details' && ((_currentRoute$params = currentRoute.params) === null || _currentRoute$params === void 0 ? void 0 : _currentRoute$params.id) === this.model.id;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ItemableConcern;
});