define("pleroma-pwa/initializers/ignore-warnings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IGNORED_WARNINGS = [// Ignore the sync-relationship known-to-be-empty.
  // This is thrown when a payload includes a ember-data-"link" but the relationship is marked as sync.
  // https://github.com/embermap/ember-data-storefront/issues/24
  'ds.store.push-link-for-sync-relationship'];
  var _default = {
    name: 'ignore-warnings',

    initialize() {
      Ember.Debug.registerWarnHandler(function (message, options, next) {
        if (!ignoreWarning(options)) {
          next(...arguments);
        }
      });
    }

  };
  _exports.default = _default;

  function ignoreWarning(options) {
    return options && options.id && IGNORED_WARNINGS.includes(options.id);
  }
});