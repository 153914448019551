define("pleroma-pwa/templates/hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RPXEX2NZ",
    "block": "{\"symbols\":[\"status\",\"index\",\"&attrs\"],\"statements\":[[9,\"main\",false],[23,\"class\",\"main main__hashtag\",null],[23,\"role\",\"main\",null],[23,\"aria-labelledby\",\"feed__header\",null],[14,\"aria-busy\",[31,124,9,[27,[26,2,\"CallHead\"],[]],[[31,135,7,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"feed\",\"isLoading\"]]],null]],null],null],[15,3],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"content-header content-header--simple\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"h1\",true],[12,\"class\",\"content-heading\",null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[31,285,1,[27,[26,3,\"CallHead\"],[]],[\"tagWithHash\"],[[\"name\"],[[27,[24,0],[\"model\",\"hashtag\"]]]]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\\n  \"],[7,\"feed/loader\",[],[[\"@feed\",\"@beginningOfFeedCallout\"],[[27,[24,0],[\"model\",\"feed\"]],[31,423,1,[27,[26,3,\"CallHead\"],[]],[\"beginningOfHashtagFeed\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[\"model\",\"feed\",\"content\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[7,\"feed-item/index\",[],[[\"@feedItem\",\"@posinset\",\"@setsize\"],[[27,[24,1],[]],[31,728,3,[27,[26,0,\"CallHead\"],[]],[[27,[24,2],[]],1],null],[27,[24,0],[\"model\",\"feed\",\"content\",\"length\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1,2]}]]],[1,1,0,0,\"  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,7,\"CallHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"add\",\"to-bool\",\"to-string\",\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pleroma-pwa/templates/hashtag.hbs"
    }
  });

  _exports.default = _default;
});