define("pleroma-pwa/adapters/notification", ["exports", "pleroma-pwa/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    buildURL(modelName, record, snapshot, urlType) {
      if (urlType === 'markAsRead') {
        return this.pleromaApi.endpoints.notificationsMarkAsRead;
      }

      return this._super(modelName, record, snapshot, urlType);
    }

  });

  _exports.default = _default;
});