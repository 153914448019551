define("pleroma-pwa/router", ["exports", "pleroma-pwa/config/environment", "ember-router-scroll", "ember-app-scheduler"], function (_exports, _environment, _emberRouterScroll, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _emberRouterScroll.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);

      (0, _emberAppScheduler.setupRouter)(this);
    }

    willDestroy(...args) {
      (0, _emberAppScheduler.reset)();
      super.destroy(...args);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('about');
    this.route('sign-in');
    this.route('sign-out');
    this.route('sign-up');
    this.route('feed', {
      path: '/feeds/:id'
    });
    this.route('notifications', function () {
      this.route('new-follows');
      this.route('mentions');
      this.route('reposts');
      this.route('favorites');
    });
    this.route('account', {
      path: 'account/:id'
    }, function () {
      this.route('followers');
      this.route('following');
      this.route('statuses');
      this.route('media');
      this.route('favorites');
    });
    this.route('user', {
      path: 'users/:id'
    });
    this.route('status', {
      path: '/notice'
    }, function () {
      this.route('details', {
        path: '/:id'
      });
    });
    this.route('settings', function () {
      this.route('profile');
      this.route('security');
      this.route('notifications');
      this.route('blocks');
      this.route('mutes');
      this.route('advanced');
    });
    this.route('search', function () {
      this.route('results', {
        path: ':query'
      }, function () {
        this.route('statuses');
        this.route('people');
        this.route('hashtags');
      });
    });
    this.route('hashtag', {
      path: '/tag/:hashtag'
    });
    this.route('messages');
    this.route('message', function () {
      this.route('chat', {
        path: '/chat/:id'
      });
      this.route('compose');
      this.route('details', {
        path: '/:id'
      });
    });
    this.route('not-found', {
      path: '*path'
    });
  });
});