define("pleroma-pwa/serializers/node-info", ["exports", "pleroma-pwa/serializers/application", "camelcase-keys", "uuid/v4"], function (_exports, _application, _camelcaseKeys, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForAttribute(attr) {
      return attr; // dont call super, we dont want to decamelize these
    },

    // This endpoint currently already returns camelCase
    // keys. It's one of the few that does. Putting this here
    // in case that ever changes.
    normalize(model, hash) {
      return this._super(model, (0, _camelcaseKeys.default)(hash, {
        deep: true
      }));
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'node-info': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      let wrappedPayload = {
        'node-info': payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    extractId() {
      return this._super(...arguments) || (0, _v.default)();
    }

  });

  _exports.default = _default;
});