define("pleroma-pwa/helpers/position-overlay-top-right-of-trigger", ["exports", "pleroma-pwa/overlay-positions/over-the-top-right-of-trigger"], function (_exports, _overTheTopRightOfTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function positionOverlayTopRightOfTrigger([triggerSelector]) {
    if (triggerSelector) {
      return (trigger, content) => (0, _overTheTopRightOfTrigger.default)(trigger.querySelector(triggerSelector), content);
    }

    return _overTheTopRightOfTrigger.default;
  });

  _exports.default = _default;
});