define("pleroma-pwa/components/on-escape-key-pressed", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    handler: null,

    init(...args) {
      this.keyboardShortcuts = {
        esc: () => Ember.tryInvoke(this, 'handler', [])
      };

      this._super(...args);
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});