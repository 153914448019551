define("pleroma-pwa/helpers/truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // ----
  // Supports both {{truncate <string> <chars>}} and {{map (truncate <chars>) (map-by <name> <array>)}}
  // ----
  function truncateString(string, chars) {
    if (string.length > chars) {
      return `${string.substring(0, chars)}...`;
    }

    return string;
  }

  var _default = Ember.Helper.helper(function truncate([string, chars]) {
    // If only one argument passed, curry from the outside in
    if (!chars && string) {
      return function (innerString) {
        return truncateString(innerString, string);
      };
    }

    return truncateString(string, chars);
  });

  _exports.default = _default;
});