define("pleroma-pwa/adapters/chat-message", ["exports", "pleroma-pwa/adapters/application", "pleroma-pwa/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: _environment.default.APP.pleromaApiNamespace
  });

  _exports.default = _default;
});