define("pleroma-pwa/modifiers/mirror-document-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(() => ({
    createModifier() {
      return {
        element: null,
        previousScroll: 0,

        onScroll() {
          if (this.enabled) {
            let windowDiff = window.scrollY - this.previousScroll;
            let elementDiff = this.element.scrollTop + windowDiff;
            this.element.scrollTo({
              top: elementDiff,
              left: 0 // behavior: 'smooth',

            });
            this.previousScroll = window.scrollY;
          }
        }

      };
    },

    installModifier(state, element, args) {
      let {
        enabled
      } = args.named;
      state.element = element;
      state.onScroll = state.onScroll.bind(state);
      state.enabled = enabled;
      state.previousScroll = window.scrollY;
      document.addEventListener('scroll', state.onScroll, {
        passive: true
      });
    },

    updateModifier(state, args) {
      let {
        enabled
      } = args.named;
      state.enabled = enabled;
    },

    destroyModifier(state) {
      document.body.removeEventListener('scroll', state.onScroll, {
        passive: true
      });
    }

  }), class FocusAfterRender {});

  _exports.default = _default;
});