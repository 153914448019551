define("pleroma-pwa/components/on-route-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    handler: null,

    didInsertElement() {
      this._super(...arguments);

      this.router.on('routeWillChange', this, 'invokeHandler');
    },

    willDestroyElement() {
      this.router.off('routeWillChange', this, 'invokeHandler');

      this._super(...arguments);
    },

    invokeHandler() {
      Ember.tryInvoke(this, 'handler');
    }

  });

  _exports.default = _default;
});