define("pleroma-pwa/adapters/client-credential", ["exports", "pleroma-pwa/adapters/application", "pleroma-pwa/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    urlForQueryRecord() {
      return this.pleromaApi.endpoints.clientCredentials;
    },

    ajaxOptions(url, type, options) {
      let hash = this._super(url, type, options);

      hash.method = 'POST';
      hash.type = 'POST';
      hash.body = JSON.stringify({
        /* eslint-disable camelcase */
        client_name: `${_environment.default.APP.name}_${new Date().toISOString()}`,
        redirect_uris: `${window.location.origin}/oauth-callback`,

        /* eslint-enable camelcase */
        scopes: _environment.default.APP.OAUTH_SCOPES.join(' ')
      });
      hash.headers = {
        'Content-Type': 'application/json'
      };
      delete hash.data;
      hash.url = url;
      return hash;
    }

  });

  _exports.default = _default;
});