define("pleroma-pwa/utils/element-is-visible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = elementIsVisible;

  function elementIsVisible(element) {
    let computedStyle = document.defaultView.getComputedStyle(element, null);
    return computedStyle.getPropertyValue('display') !== 'none' && computedStyle.getPropertyValue('visibility') !== 'hidden';
  }
});