define("pleroma-pwa/models/chat", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "ember-concerns"], function (_exports, _model, _loadableModel, _emberConcerns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_loadableModel.default, {
    unread: (0, _model.attr)('number', {
      defaultValue() {
        return 0;
      }

    }),
    updatedAt: (0, _model.attr)('date'),
    lastMessage: (0, _model.belongsTo)('chat-message', {
      async: false,
      inverse: null
    }),
    account: (0, _model.belongsTo)('user', {
      async: false,
      inverse: null
    }),

    /* eslint-disable ember-data-sync-relationships/no-async-relationships */
    messages: (0, _model.hasMany)('chat-message', {
      async: true,
      inverse: 'chat'
    }),

    /* eslint-enable ember-data-sync-relationships/no-async-relationships */
    reloadable: (0, _emberConcerns.inject)('chat/reloadable')
  });

  _exports.default = _default;
});