define("pleroma-pwa/models/poll", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model"], function (_exports, _model, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // https://docs.joinmastodon.org/entities/poll/
  let PollModel = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('number', {
    defaultValue() {
      return 0;
    }

  }), _dec6 = (0, _model.attr)('number', {
    defaultValue() {
      return 0;
    }

  }), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('array'), _dec9 = (0, _model.hasMany)('poll-option', {
    async: false,
    inverse: null
  }), _dec10 = (0, _model.attr)('array'), _dec11 = Ember.computed('options.@each.votesCount'), (_class = (_temp = class PollModel extends _model.default.extend(_loadableModel.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "expiresAt", _descriptor, this);

      _initializerDefineProperty(this, "expiresIn", _descriptor2, this);

      _initializerDefineProperty(this, "expired", _descriptor3, this);

      _initializerDefineProperty(this, "multiple", _descriptor4, this);

      _initializerDefineProperty(this, "votesCount", _descriptor5, this);

      _initializerDefineProperty(this, "votersCount", _descriptor6, this);

      _initializerDefineProperty(this, "voted", _descriptor7, this);

      _initializerDefineProperty(this, "ownVotes", _descriptor8, this);

      _initializerDefineProperty(this, "options", _descriptor9, this);

      _initializerDefineProperty(this, "emojis", _descriptor10, this);
    }

    get winningVoteCount() {
      return Math.max(...this.options.mapBy('votesCount'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "expiresAt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expiresIn", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "expired", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "multiple", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "votesCount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "votersCount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "voted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ownVotes", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "emojis", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "winningVoteCount", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "winningVoteCount"), _class.prototype)), _class));
  _exports.default = PollModel;
});