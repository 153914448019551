define("pleroma-pwa/authenticators/password-grant", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    instances: Ember.inject.service(),
    pleromaApi: Ember.inject.service(),
    session: Ember.inject.service(),
    refreshAccessTokens: false,
    sendClientIdAsQueryParam: true,
    serverTokenEndpoint: Ember.computed(function () {
      return this.pleromaApi.endpoints.token;
    }),
    serverTokenRevocationEndpoint: Ember.computed(function () {
      return this.pleromaApi.endpoints.revokeToken;
    }),

    async makeRequest(url, data, headers) {
      let originalMakeRequest = this._super;
      await this.instances.ensureCurrent();
      /* eslint-disable camelcase */

      data.client_id = this.instances.current.credentials.clientId;
      data.client_secret = this.instances.current.credentials.clientSecret;
      /* eslint-enable camelcase */

      return originalMakeRequest.call(this, url, data, headers).then(response => {
        // TODO: Properly allow for refreshing access token.
        if (response.expires_in) {
          delete response.expires_in;
        }

        return response;
      });
    }

  });

  _exports.default = _default;
});