define("pleroma-pwa/serializers/emoji-reaction", ["exports", "ember-data/serializers/json", "@ember-data/serializer/rest", "js-base64"], function (_exports, _json, _rest, _jsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      accounts: {
        embedded: 'always'
      }
    },

    keyForAttribute(attr
    /* , method */
    ) {
      return Ember.String.underscore(attr);
    },

    /* eslint-disable camelcase */
    extractId(_, {
      status_id,
      name
    }) {
      return _jsBase.Base64.encode(`:${status_id}:${name}`);
    }
    /* eslint-enable camelcase */


  });

  _exports.default = _default;
});