define("pleroma-pwa/services/offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OfflineService = (_class = (_temp = class OfflineService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "isOffline", _descriptor3, this);

      _defineProperty(this, "turnOffline", () => this.isOffline = true);

      _defineProperty(this, "turnOnline", () => this.isOffline = false);

      this.isOffline = !window.navigator.onLine;
      this.setUpOfflineListeners();
    }

    willDestroy() {
      super.willDestroy(...arguments);
      this.tearDownOfflineListeners();
    }

    setUpOfflineListeners() {
      window.addEventListener('offline', this.turnOffline);
      window.addEventListener('online', this.turnOnline);
    }

    tearDownOfflineListeners() {
      window.removeEventListener('offline', this.turnOffline);
      window.removeEventListener('online', this.turnOnline);
    }

    errorIfOffline() {
      if (this.isOffline) {
        this.toast.notify({
          message: this.intl.t('offlineNotice'),
          type: 'error'
        });
        return Ember.RSVP.reject(new Error(this.intl.t('offlineNotice')));
      }

      return Ember.RSVP.resolve();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isOffline", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "errorIfOffline", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "errorIfOffline"), _class.prototype)), _class);
  _exports.default = OfflineService;
});