define("pleroma-pwa/utils/mention-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.matchesMention = void 0;

  const matchesMention = (mention, url) => {
    if (url === mention.url) return true;
    let [username, instance] = mention.acct.split('@');
    let matchString = new RegExp(`://${instance}/.*${username}$`, 'g');
    return !!url.match(matchString);
  };

  _exports.matchesMention = matchesMention;
});