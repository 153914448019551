define("pleroma-pwa/components/emoji-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @emoji.value}}
    <button
      type="button"
      class="emoji-picker__emoji-list__button"
      title={{t "emojiReact" emoji=@emoji.shortcode}}
      data-shortcode={{@emoji.shortcode}}
      {{on "click" (optional @choose)}}
    >
      <span class="emoji-button">
        {{@emoji.value}}
      </span>
    </button>
  {{/if}}
  
  */
  {
    id: "I/e8kDhF",
    block: "{\"symbols\":[\"@emoji\",\"@choose\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,1],[\"value\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"button\",false],[23,\"class\",\"emoji-picker__emoji-list__button\",null],[14,\"title\",[31,106,1,[27,[26,0,\"CallHead\"],[]],[\"emojiReact\"],[[\"emoji\"],[[27,[24,1],[\"shortcode\"]]]]],null],[14,\"data-shortcode\",[27,[24,1],[\"shortcode\"]],null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[\"click\",[31,204,8,[27,[26,1,\"CallHead\"],[]],[[27,[24,2],[]]],null]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[12,\"class\",\"emoji-button\",null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[24,1],[\"value\"]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"optional\",\"on\",\"if\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/emoji-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});