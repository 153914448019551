define("pleroma-pwa/modifiers/capture-focus-on", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(() => ({
    createModifier() {
      return {
        element: null,
        keyboardShortcuts: {},

        focusElement(event) {
          event.stopPropagation();
          event.preventDefault();
          this.element.focus();
        }

      };
    },

    installModifier(state, element, {
      named
    }) {
      state.element = element;

      if (named.key) {
        this.setupShortcuts(state, named.key);
      }
    },

    updateModifier(state, {
      named
    }) {
      if (state.key !== named.key) {
        this.setupShortcuts(state, named.key);
      }
    },

    setupShortcuts(state, key) {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(state);
      state.key = key;
      state.keyboardShortcuts[key] = {
        action: state.focusElement,
        global: false // whether to trigger inside input

      };
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(state);
    },

    destroyModifier(state) {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(state);
    }

  }), class CaptureFocusOn {});

  _exports.default = _default;
});