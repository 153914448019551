define("pleroma-pwa/serializers/attachment", ["exports", "pleroma-pwa/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      pleroma: {
        embedded: 'always'
      }
    },

    modelNameFromPayloadKey() {
      return 'attachment';
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (!id && payload.id) {
        // eslint-disable-next-line prefer-destructuring
        id = payload.id;
      }

      let wrappedPayload = {
        attachment: payload
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    // This overrides `normalize` from `EmbeddedRecordsMixin`.
    normalize(typeClass, hash, prop) {
      // attachment-extra
      if (hash.id && hash.pleroma) {
        hash.pleroma = { ...hash.pleroma,
          id: hash.id
        };
      }

      return this._super(typeClass, hash, prop);
    }

  });

  _exports.default = _default;
});