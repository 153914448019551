define("pleroma-pwa/helpers/stop-propagation", ["exports", "pleroma-pwa/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function stopPropagation([handler]) {
    return function (event) {
      (false && !(event && typeof event.stopPropagation === 'function') && Ember.assert(`Expected '${event}' to be an Event and have a 'stopPropagation' method.`, event && typeof event.stopPropagation === 'function'));
      event.stopPropagation();

      if (_environment.default.environment === 'test') {
        event.propagationStopped = true;
      }

      if (handler) {
        return handler(event);
      }

      return event;
    };
  });

  _exports.default = _default;
});