define("pleroma-pwa/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['auth', {
        gallerySubject: 'gallery-subject'
      }, {
        galleryAttachment: 'gallery-attachment'
      }, {
        gallerySubjectType: 'gallery-subject-type'
      }, {
        inReplyToStatusId: 'in-reply-to'
      }, {
        compose: 'compose'
      }, {
        directMode: 'directMode'
      }, {
        initialMentions: {
          as: 'initial-mentions',
          type: 'array'
        }
      }, {
        initialLinks: {
          as: 'initial-links',
          type: 'array'
        }
      }, {
        initialParticipants: {
          as: 'initial-recipients',
          type: 'array'
        }
      }]);

      _defineProperty(this, "auth", null);

      _defineProperty(this, "gallerySubject", null);

      _defineProperty(this, "galleryAttachment", null);

      _defineProperty(this, "gallerySubjectType", null);

      _defineProperty(this, "inReplyToStatusId", null);

      _defineProperty(this, "initialMentions", null);

      _defineProperty(this, "initialParticipants", null);

      _defineProperty(this, "initialLinks", null);

      _defineProperty(this, "compose", null);

      _defineProperty(this, "directMode", null);
    }

  }

  _exports.default = ApplicationController;
});