define("pleroma-pwa/services/user-performance-monitoring", ["exports", "ember-user-performance-monitoring/services/user-performance-monitoring"], function (_exports, _userPerformanceMonitoring) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _userPerformanceMonitoring.default;
    }
  });
});