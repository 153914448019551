define("pleroma-pwa/services/store", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PleromaStore extends _store.default {
    /**
     * Normalizes data, pushes it into Ember Data store, and
     * returns an array of hydrated Ember Data model objects
     * from the store.
     *
     * @return [Model]
     * @public
     */
    normalizeAndStore(modelName = 'user', rawObjects = []) {
      let wrappedObjects = Ember.A(rawObjects);

      if (wrappedObjects.length) {
        // looks like the first object is an array itself. warn.
        (false && !(!wrappedObjects.firstObject.length) && Ember.assert('Must pass an array of objects', !wrappedObjects.firstObject.length));
      }

      let serializer = this.serializerFor(modelName);
      let model = this.modelFor(modelName);
      let objects = rawObjects.map(rawObject => {
        let object = rawObject;

        if (!object.id) {
          object.id = serializer.extractId(model, object);
        }

        return object;
      });
      let ids = objects.map(u => u.id);
      let normalized = serializer.normalizeArrayResponse(this, model, objects);
      this.push(normalized);
      return ids.map(id => this.peekRecord(modelName, id));
    }

  }

  _exports.default = PleromaStore;
});