define("pleroma-pwa/mixins/authenticated-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "pleroma-pwa/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    authModal: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    ..._environment.default.APP.emberSimpleAuthConfig,

    triggerAuthentication() {
      this.session.attemptedTransition.abort();
      let reasonKey = this.reasonForAuthenticationKey || 'youMustSignInToSeeThatPage'; // is very first visited page, nowhere to render the signin modal, take um to the sign-in route.

      if (this.session.attemptedTransition.sequence === 0) {
        this.session.setReasonForAuthentication(reasonKey);
        this.replaceWith('sign-in');
      } else {
        this.session.setReasonForAuthentication(reasonKey);
        this.authModal.showSignInModal();
      }
    }

  });

  _exports.default = _default;
});