define("pleroma-pwa/helpers/toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    toast: Ember.inject.service(),

    compute(messageKey) {
      let {
        intl,
        toast
      } = this;
      (false && !(intl) && Ember.assert('Unable to lookup intl service', intl));
      (false && !(toast) && Ember.assert('Unable to lookup toast service', toast));
      return function () {
        toast.notify({
          message: intl.t(messageKey)
        });
        return Ember.RSVP.resolve(...arguments);
      };
    }

  });

  _exports.default = _default;
});