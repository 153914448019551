define("pleroma-pwa/serializers/emoji", ["exports", "pleroma-pwa/serializers/application", "js-base64"], function (_exports, _application, _jsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pleromaApi: Ember.inject.service(),

    // {
    //   "girlpower": {
    //     "tags": [
    //       "Finmoji"
    //     ],
    //     "image_url": "/finmoji/128px/girlpower-128.png"
    //   },
    //   "education": {
    //     "tags": [
    //       "Finmoji"
    //     ],
    //     "image_url": "/finmoji/128px/education-128.png"
    //   },
    //   "finnishlove": {
    //     "tags": [
    //       "Finmoji"
    //     ],
    //     "image_url": "/finmoji/128px/finnishlove-128.png"
    //   }
    // }
    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let normalized = Object.entries(payload).map(([shortcode, data]) => {
        let url;

        if (data.image_url) {
          url = data.image_url[0] === '/' ? `${this.pleromaApi.apiBaseUrl}${data.image_url}` : data.image_url;
        }

        return {
          shortcode,
          'static_url': url,
          url,
          tags: data.tags
        };
      });
      let wrappedPayload = {
        emoji: normalized
      };
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let normalized = this._super(...arguments); // makes the assumption that every emoji found directly from an API (with
      // `find*` or `load*` methods on the store) should be visible in picker.


      if (requestType === 'query') {
        normalized.data = normalized.data.map(emoji => {
          emoji.attributes.visibleInPicker = true;
          return emoji;
        });
      }

      return normalized;
    },

    extractId(_, {
      shortcode,
      url
    }) {
      return _jsBase.Base64.encode(`:${shortcode}:${url}`);
    }

  });

  _exports.default = _default;
});