define("pleroma-pwa/components/sidebar/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="sidebar__box sidebar__box--padded large-hide sidebar__box--signout" data-test-selector="sidebar-sign-out">
    <ul class="menu-list menu-list--sidebar">
      <li class="menu-list__item">
        <LinkTo class="menu-list__item-link" @route="sign-out" data-test-selector="sidebar-profile-sign-out-button">
          <p class="menu-list__item-title">{{t "signOut"}}</p>
          <div class="menu-list__item-icon" role="presentation">{{svg-jar "sign-out"}}</div>
        </LinkTo>
      </li>
    </ul>
  </section>
  
  */
  {
    id: "M87PPOor",
    block: "{\"symbols\":[],\"statements\":[[9,\"section\",true],[12,\"class\",\"sidebar__box sidebar__box--padded large-hide sidebar__box--signout\",null],[12,\"data-test-selector\",\"sidebar-sign-out\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"ul\",true],[12,\"class\",\"menu-list menu-list--sidebar\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[12,\"class\",\"menu-list__item\",null],[10],[1,1,0,0,\"\\n      \"],[7,\"link-to\",[[23,\"class\",\"menu-list__item-link\",null],[23,\"data-test-selector\",\"sidebar-profile-sign-out-button\",null]],[[\"@route\"],[\"sign-out\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n        \"],[9,\"p\",true],[12,\"class\",\"menu-list__item-title\",null],[10],[1,0,0,0,[31,358,1,[27,[26,0,\"CallHead\"],[]],[\"signOut\"],null]],[11],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"menu-list__item-icon\",null],[12,\"role\",\"presentation\",null],[10],[1,0,0,0,[31,440,7,[27,[26,1,\"CallHead\"],[]],[\"sign-out\"],null]],[11],[1,1,0,0,\"\\n      \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\"]}",
    meta: {
      moduleName: "pleroma-pwa/components/sidebar/sign-out.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});