define("pleroma-pwa/services/push-notifications", ["exports", "ember-concurrency-decorators", "fetch", "urlb64touint8array"], function (_exports, _emberConcurrencyDecorators, _fetch, _urlb64touint8array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PushNotificationsService = (_class = (_temp = class PushNotificationsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "instances", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "pleromaApi", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "toast", _descriptor6, this);

      _defineProperty(this, "defaultNotificationSettings", {
        follow: true,
        favourite: true,
        reblog: true,
        mention: true,
        poll: true
      });
    }

    get browserSupported() {
      return 'serviceWorker' in navigator && ('PushManager' in window || 'pushManager' in ServiceWorkerRegistration.prototype);
    }

    get browserPermissionDenied() {
      return window.Notification.permission === 'denied';
    }

    get browserPermissionDefault() {
      return window.Notification.permission === 'default';
    }

    get browserPermissionGranted() {
      return window.Notification.permission === 'granted';
    }

    get vapidPublicKey() {
      return this.instances.current.credentials.vapidKey;
    }

    get isFetchingInitial() {
      return !this.vapidPublicKey || !this.existingSubscriptionTask.last;
    }

    get isSupported() {
      return this.vapidPublicKey && this.browserSupported;
    }

    get isUpdatingSubscription() {
      return this.subscribe.isRunning || this.unsubscribe.isRunning || this.updatePushSubscription.isRunning;
    }

    *subscribe() {
      if (!this.features.isEnabled('pushNotifications')) {
        return;
      }

      if (!this.browserSupported) {
        return;
      }

      if (!this.session.isAuthenticated) {
        return;
      } // Wait for service worker registration


      yield navigator.serviceWorker.ready; // Attempt to request permission

      yield window.Notification.requestPermission();

      if (this.browserPermissionGranted) {
        if (!this.existingSubscriptionTask.last) {
          yield this.existingSubscriptionTask.linked().perform();
        }

        if (this.existingSubscriptionTask.last.value) {
          return; // Bail out if a subscription exists
        }

        yield this.updatePushSubscription.linked().perform(this.defaultNotificationSettings, true);
        let notification = new Notification(this.intl.t('approved'), {
          body: this.intl.t('pushNotificationsApproved'),
          icon: this.instances.current.thumbnail
        });
        setTimeout(() => notification.close(), 1000 * 8);
      }
    }

    *updatePushSubscription(notificationSettings, createSubscription) {
      if (!this.browserSupported) {
        return;
      }

      if (!this.session.isAuthenticated) {
        return;
      }

      if (!this.existingSubscriptionTask.last) {
        yield this.existingSubscriptionTask.linked().perform();
      }

      if (this.browserPermissionGranted && (createSubscription || this.existingSubscriptionTask.last.value)) {
        let registration = yield navigator.serviceWorker.ready;
        let subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: (0, _urlb64touint8array.default)(this.vapidPublicKey)
        };
        let subscription = yield registration.pushManager.subscribe(subscribeOptions);
        yield this.sendSubscriptionToApi(subscription, notificationSettings);
        yield this.existingSubscriptionTask.linked().perform();

        if (!createSubscription) {
          this.toast.notify({
            message: this.intl.t('notificationSettingsHaveBeenSaved')
          });
        }
      }
    }

    *unsubscribe() {
      if (!this.existingSubscriptionTask.last) {
        yield this.existingSubscriptionTask.perform();
      }

      let subscription = this.existingSubscriptionTask.last.value;

      if (subscription) {
        yield subscription.serviceWorkerSubscription.unsubscribe();
        yield (0, _fetch.default)(this.pleromaApi.endpoints.pushSubscription, {
          method: 'DELETE',
          headers: this.session.headers
        });
        yield this.existingSubscriptionTask.linked().perform();
      }
    }

    *existingSubscriptionTask() {
      try {
        let {
          headers
        } = this.session;
        let response = yield (0, _fetch.default)(this.pleromaApi.endpoints.pushSubscription, {
          headers
        });
        let foundSubscription = response.status === 200;
        let apiSubscription = yield response.json();
        let registration = yield navigator.serviceWorker.ready;
        let serviceWorkerSubscription = yield registration.pushManager.getSubscription(); // only return the browser subscription if the API and the browser agree that there is a subscription

        return foundSubscription && serviceWorkerSubscription && {
          apiSubscription,
          serviceWorkerSubscription
        };
      } catch (e) {
        console.debug(e);
        return false;
      }
    }

    toggle() {
      var _this$existingSubscri;

      if ((_this$existingSubscri = this.existingSubscriptionTask.last) === null || _this$existingSubscri === void 0 ? void 0 : _this$existingSubscri.value) {
        return this.unsubscribe.perform();
      } else {
        return this.subscribe.perform();
      }
    }

    async sendSubscriptionToApi(subscription, notificationSettings) {
      let response = await (0, _fetch.default)(this.pleromaApi.endpoints.pushSubscription, {
        method: 'POST',
        headers: this.session.headers,
        body: JSON.stringify({
          subscription,
          data: {
            alerts: { ...this.defaultNotificationSettings,
              ...notificationSettings
            }
          }
        })
      });
      await response.json();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instances", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pleromaApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "toast", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "subscribe", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePushSubscription", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "updatePushSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsubscribe", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "unsubscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "existingSubscriptionTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "existingSubscriptionTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class);
  _exports.default = PushNotificationsService;
});