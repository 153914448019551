define("pleroma-pwa/utils/scroll-to-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollToBottom;

  function scrollToBottom(element) {
    if (!(element === null || element === void 0 ? void 0 : element.nodeName)) {
      return;
    }

    let newScrollTop = element.scrollHeight - element.clientHeight;
    let behavior = 'auto';

    if (newScrollTop === element.scrollTop) {
      return;
    }

    if (Math.abs(newScrollTop - element.scrollTop) < 300) {
      behavior = 'smooth';
    }

    element.scrollTo({
      top: newScrollTop,
      behavior
    });
  }
});