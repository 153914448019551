define("pleroma-pwa/feeds/base", ["exports", "pleroma-pwa/utils/poller", "ember-concurrency-decorators", "pleroma-pwa/config/environment", "pleroma-pwa/utils/unique-array-by", "fetch"], function (_exports, _poller, _emberConcurrencyDecorators, _environment, _uniqueArrayBy, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function pushObjectsUnique(items) {
    this.pushObjects((0, _uniqueArrayBy.default)(this.content, items, 'id'));
  }

  function unshiftObjectsUnique(items) {
    this.unshiftObjects((0, _uniqueArrayBy.default)(this.content, items, 'id'));
  }

  let BaseFeed = (_dec = Ember.computed.or('loadNew.isRunning', 'loadMore.isRunning'), (_class = (_temp = class BaseFeed extends Ember.Object.extend(Ember.Evented) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "feeds", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _defineProperty(this, "pageSize", _environment.default.APP.FEED_PAGE_SIZE);

      _defineProperty(this, "content", Ember.ArrayProxy.create({
        content: Ember.A(),
        pushObjectsUnique,
        unshiftObjectsUnique
      }));

      _defineProperty(this, "newItems", Ember.ArrayProxy.create({
        content: Ember.A(),
        pushObjectsUnique,
        unshiftObjectsUnique
      }));

      _defineProperty(this, "hidesMutedStatuses", false);

      _defineProperty(this, "subscribedTo", false);

      _defineProperty(this, "canLoadMore", true);

      _defineProperty(this, "enqueNewItems", true);

      _defineProperty(this, "longPoller", new _poller.default(() => {
        this.loadNew.perform();
      }));

      _initializerDefineProperty(this, "isLoading", _descriptor5, this);
    }

    addItems(items) {
      this.content.unshiftObjectsUnique(items);
    }

    removeItem(item) {
      this.content.removeObject(item);
    }

    syncNewItems() {
      this.addItems(this.newItems.content);
      this.newItems.clear();
    }

    *collectFeedMeta() {}

    // TODO: use concurrency groups to express inter-task concurrency
    *subscribe() {
      // Kick um outa here!
      if (this.authenticated === true && !this.session.isAuthenticated) {
        return this;
      } // Only block the first time


      if (!this.content.length && this.canLoadMore) {
        yield this.loadMoreAndSubscribe();
      } else {
        if (!this.canLoadMore) {
          // Give the feed a chance to redeem itself if it failed originally.
          Ember.set(this, 'canLoadMore', true);
        }

        this.loadMoreAndSubscribe();
      }

      return yield this;
    }

    loadMoreAndSubscribe() {
      // Ensure we don't fetch older posts if the feed is already loaded.
      if (this.content.length > 0) {
        this.startPollerAndSubscribe();
      } else {
        return this.loadMore.perform().then(() => {
          // Only register long poller if no error.
          this.startPollerAndSubscribe();
        }).catch(() => {
          // Mark feed as not able to load more if there was a load error.
          Ember.set(this, 'canLoadMore', false);
        });
      }
    }

    startPollerAndSubscribe() {
      this.longPoller.start(); // Only mark as subscribedTo if all setup is done.

      Ember.set(this, 'subscribedTo', true);
    }

    *unsubscribe() {
      this.longPoller.stop();
      Ember.set(this, 'subscribedTo', false);
      return yield this;
    }

    async reset() {
      this.content.clear();
      this.newItems.clear();
      Ember.set(this, 'subscribedTo', false);
      Ember.set(this, 'canLoadMore', true);
      Ember.set(this, 'enqueNewItems', true);
    }

    getMostRecentItem() {
      return this.newItems.firstObject || this.content.firstObject;
    }

    addNewItems(items) {
      if (this.enqueNewItems) {
        this.newItems.unshiftObjectsUnique(items);
      } else {
        this.content.unshiftObjectsUnique(items);
      }
    }

    get url() {
      return assert('You must define a url method when creating a feed', false);
    }

    *loadNew() {
      if (this.authenticated === true && !this.session.isAuthenticated) {
        return [];
      }

      if (this.loadMore.isRunning) {
        // TODO, use task groups to ensure inter-task concurrency
        return;
      }

      let {
        headers
      } = this.session;
      let url = this.loadNewUrl;
      let response = yield (0, _fetch.default)(url, {
        headers
      });
      let results = yield response.json();
      let status = this.error.detectErrantSuccessCode(response.status, results);

      if (status >= 400) {
        throw this.error.formatPayloadErrors(results);
      }

      let records = this.store.normalizeAndStore(this.modelName, results);

      if (records.length) {
        this.addNewItems(records);
      }

      this.trigger('feed:loaded-new', records);
      return this;
    } // Returns URL object


    get loadNewUrl() {
      let mostRecentItem = this.getMostRecentItem();
      let url = new URL(this.url);
      let query = {
        limit: this.pageSize,
        // eslint-disable-next-line camelcase
        ...(mostRecentItem && {
          min_id: mostRecentItem.id
        }),
        ...this.params
      };
      Object.keys(query).forEach(key => {
        // Pleroma expects arrays in urls to be formatted PHP style, ?prop[]=one&prop[]=two
        if (Ember.typeOf(query[key]) === 'array') {
          let arrayKey = `${Ember.String.decamelize(key)}[]`;
          query[key].forEach(param => url.searchParams.append(arrayKey, param));
        } else {
          url.searchParams.append(Ember.String.decamelize(key), query[key]);
        }
      });
      return url;
    }

    getOldestItem() {
      return this.content.lastObject;
    }

    addMoreItems(items) {
      this.content.pushObjectsUnique(items);
    }

    *loadMore() {
      if (this.authenticated === true && !this.session.isAuthenticated) {
        return;
      }

      if (!this.canLoadMore || this.loadNew.isRunning) {
        // TODO: use task groups to ensure inter-task concurrency
        return;
      }

      let {
        headers
      } = this.session;
      let url = this.loadMoreUrl;
      let response = yield (0, _fetch.default)(url, {
        headers
      });
      let results = yield response.json();
      let status = this.error.detectErrantSuccessCode(response.status, results);

      if (status >= 400) {
        throw this.error.formatPayloadErrors(results);
      }

      let records = this.store.normalizeAndStore(this.modelName, results);

      if (records.length) {
        this.addMoreItems(records);
      }

      Ember.set(this, 'canLoadMore', records.length !== 0);
      this.trigger('feed:loaded-more', records);
    } // Returns URL object


    get loadMoreUrl() {
      let url = new URL(this.url);
      let oldestItem = this.getOldestItem();
      let query = {
        limit: this.pageSize,
        // eslint-disable-next-line camelcase
        ...(oldestItem && {
          max_id: oldestItem.id
        }),
        ...this.params
      };
      Object.keys(query).forEach(key => {
        // Pleroma expects arrays in urls to be formatted PHP style, ?prop[]=one&prop[]=two
        if (Ember.typeOf(query[key]) === 'array') {
          let arrayKey = `${Ember.String.decamelize(key)}[]`;
          query[key].forEach(param => url.searchParams.append(arrayKey, param));
        } else {
          url.searchParams.append(Ember.String.decamelize(key), query[key]);
        }
      });
      return url;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feeds", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "collectFeedMeta", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "collectFeedMeta"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "subscribe", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsubscribe", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "unsubscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadNew", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class));
  _exports.default = BaseFeed;
});