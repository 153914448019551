define("pleroma-pwa/helpers/round-to-nearest-1k", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function roundToNearest1k(number) {
    if (number < 1000) {
      return number;
    } else {
      return `${Math.floor(number / 100) / 10}k`;
    }
  });

  _exports.default = _default;
});