define("pleroma-pwa/adapters/application", ["exports", "@ember-data/adapter/rest", "pleroma-pwa/config/environment"], function (_exports, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    error: Ember.inject.service(),
    instances: Ember.inject.service(),
    intl: Ember.inject.service(),
    pleromaApi: Ember.inject.service(),
    sentry: Ember.inject.service(),
    session: Ember.inject.service(),
    host: Ember.computed.readOnly('pleromaApi.apiBaseUrl'),
    namespace: _environment.default.APP.mastodonApiNamespace,
    headers: Ember.computed.readOnly('session.headers'),

    handleResponse(code, headers, payload, requestData) {
      code = this.error.detectErrantSuccessCode(code, payload);

      if (code >= 400) {
        payload = this.error.formatPayloadErrors(payload);
      }

      return this._super(code, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});