define("pleroma-pwa/models/node-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    version: attr('string'),
    metadata: attr('object'),
    openRegistrations: attr('object'),
    protocols: attr('array'),
    services: attr('object'),
    software: attr('object'),
    useage: attr('object')
  }); // {
  // 	"metadata": {
  // 		"accountActivationRequired": false,
  // 		"features": ["pleroma_api", "mastodon_api", "mastodon_api_streaming", "polls", "pleroma_explicit_addressing", "shareable_emoji_packs", "chat", "relay"],
  // 		"federation": {
  // 			"exclusions": false,
  // 			"mrf_policies": ["NoOpPolicy"],
  // 			"quarantined_instances": []
  // 		},
  // 		"invitesEnabled": false,
  // 		"mailerEnabled": true,
  // 		"nodeDescription": "A Pleroma instance, an alternative fediverse server",
  // 		"nodeName": "Pleroma",
  // 		"pollLimits": {
  // 			"max_expiration": 31536000,
  // 			"max_option_chars": 200,
  // 			"max_options": 20,
  // 			"min_expiration": 0
  // 		},
  // 		"postFormats": ["text/plain", "text/html", "text/markdown", "text/bbcode"],
  // 		"private": false,
  // 		"restrictedNicknames": [".well-known", "~", "about", "activities", "api", "auth", "check_password", "dev", "friend-requests", "inbox", "internal", "main", "media", "nodeinfo", "notice", "oauth", "objects", "ostatus_subscribe", "pleroma", "proxy", "push", "registration", "relay", "settings", "status", "tag", "user-search", "user_exists", "users", "web"],
  // 		"skipThreadContainment": true,
  // 		"staffAccounts": [],
  // 		"suggestions": {
  // 			"enabled": false,
  // 			"limit": 40,
  // 			"thirdPartyEngine": "http://vinayaka.distsn.org/cgi-bin/vinayaka-user-match-suggestions-api.cgi?{{host}}+{{user}}",
  // 			"timeout": 300000,
  // 			"web": "https://vinayaka.distsn.org"
  // 		},
  // 		"uploadLimits": {
  // 			"avatar": 2000000,
  // 			"background": 4000000,
  // 			"banner": 4000000,
  // 			"general": 16000000
  // 		}
  // 	},
  // 	"openRegistrations": true,
  // 	"protocols": ["activitypub"],
  // 	"services": {
  // 		"inbound": [],
  // 		"outbound": []
  // 	},
  // 	"software": {
  // 		"name": "pleroma",
  // 		"version": "1.1.50"
  // 	},
  // 	"usage": {
  // 		"localPosts": 135,
  // 		"users": {
  // 			"total": 13
  // 		}
  // 	},
  // 	"version": "2.0"
  // }


  _exports.default = _default;
});